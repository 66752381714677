import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'ID',
        minWidth: 100,
    },
    {
        id: 'userName',
        numeric: true,
        disablePadding: false,
        label: 'Uživatelské jméno',
        minWidth: 170,
    },
    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        label: 'Email',
        minWidth: 170,
    },
    {
        id: 'sureName',
        numeric: false,
        disablePadding: false,
        label: 'Jméno',
        minWidth: 170,
    },
    {
        id: 'lastName',
        numeric: true,
        disablePadding: false,
        label: 'Příjmení',
        minWidth: 170,
    },
    {
        id: 'role',
        numeric: true,
        disablePadding: false,
        label: 'Role',
        minWidth: 170,
    },
]

const TableUsersHead = () => {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.numeric ? 'left' : 'left'}>
                        {headCell.label}
                    </TableCell>
                ))}
                <TableCell style={{minWidth: 100}} align="center">
                    Akce
                </TableCell>
            </TableRow>
        </TableHead>
    )
}
export default TableUsersHead
