import React from 'react'
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core'
import toFormatDate from '../../utils/toFormatDate'
import {isNotEmpty, isNotNil} from 'ramda-extension'
import PdfSVG from '../../assets/pdfSVG.svg'
import DownloadIcon from '../../assets/icons8-download-96.png'
import Icon8 from '../IconFromPNG/Icon8'
import GenerateOrderPDF from '../Buttons/GenerateOrderPDF'
import ConfirmDeleteWithIcon from '../ConfirmDialog/ConfirmDeleteWithIcon'
import {useDispatch} from 'react-redux'
import {deleteContract} from '../../actions/orders'
import TypographyTitle from './TypographyTitle'
import {useStyles} from '../../css/useStyles'

const Contracts = ({contracts, orderId}) => {
    const {tableHeadGray} = useStyles()

    const dispatch = useDispatch()
    const openPDF = (url) => {
        window.open(url, '_blank')
    }
    const deleteInvoice = (contractId) => {
        dispatch(deleteContract(orderId, contractId))
    }

    return (
        <div>
            <React.Fragment>
                <Grid container justify={'space-between'} alignItems={'center'}>
                    <Grid item md={5}>
                        <TypographyTitle title={'Smlouvy'} />
                    </Grid>
                    <Grid item>
                        <GenerateOrderPDF id={orderId} />
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead className={tableHeadGray}>
                            <TableRow>
                                <TableCell align="left"> </TableCell>
                                <TableCell align="left">Soubor</TableCell>
                                <TableCell align="left">Vytvořeno</TableCell>
                                <TableCell align="left"> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isNotNil(contracts) &&
                                isNotEmpty(contracts) &&
                                contracts.map(({fileName, id, dateCreated, url}) => (
                                    <TableRow key={fileName}>
                                        <TableCell component="th" scope="row">
                                            <Icon8 src={PdfSVG} />
                                        </TableCell>
                                        <TableCell align="left">{fileName}</TableCell>
                                        <TableCell align="left">{toFormatDate(dateCreated)}</TableCell>
                                        <TableCell width={150} align="right">
                                            <IconButton size={'medium'} onClick={() => openPDF(url)}>
                                                <Icon8 src={DownloadIcon} />
                                            </IconButton>
                                            <ConfirmDeleteWithIcon onApprove={() => deleteInvoice(id)} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        </div>
    )
}
export default Contracts
