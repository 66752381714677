import Axios from 'axios'
import {createAction} from 'redux-actions'
import userConstants from '../../constants/userConstants'
import {closeUserModal} from '../modal'
import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import fetchConstants from '../../constants/fetchConstants'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import {reset} from 'redux-form'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import {toastSuccess} from '../../components/Toast/ToastSuccess'
import {loadAllUsrs} from './loadAllUsers'

const fetchSuccess = createAction(userConstants.CREATE_USER_SUCCESS)

export const createUser = (formData) => (dispatch, getState) => {
    const {
        users: {size, number},
    } = getState()
    const {baseUrl, version, userCreate} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.USER_CREATE))
    Axios.post(`${baseUrl}${version}${userCreate}`, formData, config)
        .then(({data}) => {
            dispatch(loadAllUsrs(size, number))
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.USER_CREATE}))
            dispatch(closeUserModal())
            dispatch(reset('createUser'))
            toastSuccess('Uživatel byl vytvořen')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.USER_CREATE))
}
