import {makeStyles} from '@material-ui/core/styles'
import colours from './colors'
export const useColors = makeStyles((theme) => ({
    svgGreen: {
        fill: 'green',
    },
    svgRed: {
        fill: 'red',
    },
    arrowBlue: {
        fill: colours.ARROW_BLUE,
    },
    orderTextColor: {
        color: colours.ORDER_TEXT,
    },
    successColor: {
        color: colours.SUCCESS,
    },
    errorColor: {
        color: colours.ERROR,
    },
    tableHeadGray: {
        color: colours.TABLE_HEAD_GRAY,
    },
}))
