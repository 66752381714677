import {makeStyles} from '@material-ui/core'

export const navUseStyles = makeStyles((theme) => ({
    navItem: {
        padding: '10px 5px 10px 30px',
        position: 'relative',
    },
    navItemRaw: {
        padding: '0px 20px 15px 30px',
    },
    collapseNavItem: {
        padding: '0px 0px 0px 30px',
    },
    navItemText: {
        paddingLeft: '10px',
    },
    navText: {
        //5b5b5b 2b61cd
        color: '#5b5b5b',
        fontSize: '0.915rem',
        fontWeight: 500,
    },
    navTextActive: {
        //5b5b5b 2b61cd rgba(36, 34, 123, 1)
        color: theme.palette.primary.main,
        fontSize: '0.915rem',
        fontWeight: 500,
    },
    expandIcon: {
        fill: theme.palette.primary.main,
        cursor: 'pointer',
    },
    navItemSelected: {
        width: '8px',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translate(0%, -50%)',
        height: '40px',
        background: 'inherit',
        backgroundColor: theme.palette.primary.main,
        border: '0 none',
        borderRadius: '0 4px 4px 0',
        boxShadow: 'none',
    },
    icon: {
        fill: '#5b5b5b',
        width: '28px',
    },
    iconActive: {
        fill: theme.palette.primary.main,
        width: '28px',
    },
}))
