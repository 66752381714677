import React, {useState} from 'react'
import {Checkbox, FormControlLabel, Select, TextField, Typography} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import {isEmpty, prop, propOr} from 'ramda'
import {change} from 'redux-form'
import {useDispatch} from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import {isNotEmpty, isNotNil} from 'ramda-extension'
import {useStyles} from '../../css/useStyles'
import {useColors} from '../../css/useColors'

export const renderTextField = ({disableErrorCode, label, input, meta: {touched, invalid, error}, ...custom}) => (
    <TextField
        label={label}
        variant="outlined"
        size="small"
        fullWidth
        error={touched && invalid}
        helperText={!disableErrorCode && touched && error}
        {...input}
        {...custom}
    />
)

export const renderCheckbox = ({input, label, ...other}) => (
    <FormControlLabel
        control={
            <Checkbox {...input} {...other} checked={!!input.value} onChange={input.onChange} defaultValue={false} />
        }
        label={label}
    />
)

export const renderSelectField = ({label, input, meta: {touched, invalid, error}, children, ...custom}) => (
    <TextField
        label={label}
        select
        variant="outlined"
        size="small"
        fullWidth
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}>
        {children}
    </TextField>
)

export const renderSelect = ({label, input, meta: {touched, invalid, error}, children, ...custom}) => (
    <Select
        autoWidth={true}
        label={label}
        select
        variant="outlined"
        size="small"
        fullWidth={true}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}>
        {children}
    </Select>
)

export const RenderBankAutoComplete = ({
    options,
    label,
    input,
    meta: {touched, invalid, error, form},
    children,
    ...custom
}) => {
    const dispatch = useDispatch()
    return (
        <Autocomplete
            onChange={(option, newValue) => dispatch(change(form, input.name, prop('Kód banky', newValue)))}
            placeholder={label}
            error={touched && invalid}
            helperText={touched && error}
            options={options}
            getOptionSelected={(option) => propOr('', 'Kód banky', option)}
            getOptionLabel={(option) => propOr('', 'Kód banky', option)}
            renderOption={(option) => (
                <span>{`${propOr('', 'Kód banky', option)}  ${propOr('', 'Banka', option)}`}</span>
            )}
            renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
        />
    )
}

export const validate = (values) => {
    const errors = {}
    const requiredFields = ['userName', 'firstName', 'email', 'lastName', 'password', 'role']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Povinné'
        }
    })
    if (values.password && values.password.length < 8) {
        errors.password = 'Krátké heslo'
    }
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Špatná formát emailové adresy'
    }
    return errors
}

//const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0])

export const isValidUrl = (string) => {
    try {
        new URL(string)
    } catch (_) {
        return false
    }
    return true
}

const Preview = ({url, disabled}) => {
    const {relative, avatarSize7, grayScale} = useStyles()
    return (
        <div className={relative}>
            <Avatar src={url} className={`${avatarSize7} ${disabled ? grayScale : ''}`} />
        </div>
    )
}

export const TextFileInput = ({
    editImage,
    disabled,
    title,
    input: {value: omitValue, onChange, onBlur, ...inputProps},
    meta,
    withNewImage,
    ...props
}) => {
    const {pointer, fileInputSelectText, fileInput} = useStyles()
    const {errorColor} = useColors()
    const [base64Url, setBase64Url] = useState('')
    const adaptFileEvent = (delegate) => (e) => {
        withNewImage(true)
        handleFileChange(e)
        delegate(e.target.files[0])
    }
    const deleteImage = () => {
        setBase64Url('')
        withNewImage(false)
    }
    const handleFileChange = (e) => {
        e.preventDefault()
        onChange(e.target.files[0])
        getBase64(e.target.files[0], (base64) => {
            setBase64Url(base64)
        })
    }
    const getBase64 = (file, cb) => {
        if (Math.round(file.size / 1024) > 2048) {
            // 2MB
            alert('Obrázek je příliš velký')
            return
        }

        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(reader.result)
        }
        reader.onerror = function (error) {
            console.log('Error: ', error)
        }
    }
    return (
        <React.Fragment>
            {editImage && isValidUrl(editImage) && isEmpty(base64Url) && (
                <Preview url={editImage} deleteImage={deleteImage} disabled={disabled} />
            )}
            {isNotEmpty(base64Url) && (
                <Preview
                    url={base64Url}
                    deleteImage={deleteImage}
                    base64
                    setBase64Url={setBase64Url}
                    disabled={disabled}
                />
            )}

            <label htmlFor={'fileInputCustom'} className={pointer}>
                {' '}
                <Typography className={fileInputSelectText}>Vyberte obrázek</Typography>
            </label>
            <input
                id={'fileInputCustom'}
                onChange={adaptFileEvent(onChange)}
                {...props.input}
                {...props}
                className={fileInput}
                type={'file'}
            />
            {meta.error && isNotNil(meta.error) && meta.touched && <p className={errorColor}>{meta.error}</p>}
        </React.Fragment>
    )
}
