import React, {useState} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import FlightTableToolbar from './FlightTableToolbar'
import {connect} from 'react-redux'
import {IconButton} from '@material-ui/core'
import {indexOf} from 'ramda'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import FlightTableHead from './FlightTableHead'
import {addingFlightompanySelector} from '../../selectors/flightCompanies'
import {deleteFlightsCompanies} from '../../actions/flightCompanies'
import PageWrapper from '../PageWrapper'
import AddFlightCompanyButton from './AddFlightCompanyButton'
import {PAGES} from '../../utils/routes'
import FlightCompaniesModal from './Dialog'
import {initialize} from 'redux-form'
import formNames from '../../constants/form/formNames'
import {openReduxModal} from '../../actions/modal'
import modalNames from '../../constants/modal/modalNames'
import ConfirmDeleteWithIcon from '../ConfirmDialog/ConfirmDeleteWithIcon'
import {usePadding} from '../../css/usePadding'

const FlightCompaniesTable = ({flightCompanies = [], dispatch, addingNewCompany}) => {
    const {plr8px} = usePadding()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const fillUpdateCompany = (flight) => {
        dispatch(initialize(formNames.FLIGHT_COMPANIES_FORM, flight))
        dispatch(openReduxModal(modalNames.FLIGHT_COMPANIES_MODAL))
    }

    return (
        <PageWrapper headingFor={PAGES.FLIGHT_COMPANIES} rightBar={<AddFlightCompanyButton />}>
            <FlightCompaniesModal />
            <div>
                <Paper>
                    <FlightTableToolbar setSearch={setSearch} search={search} showAddButton={addingNewCompany} />
                    <TableContainer>
                        <Table size={'medium'}>
                            <FlightTableHead />
                            {
                                <TableBody>
                                    {flightCompanies
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .filter((row) => indexOf(search, row.name) !== -1)
                                        .map((flightCompany) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={flightCompany.id} className={plr8px}>
                                                    <TableCell className={plr8px} component="th" scope="row">
                                                        {flightCompany.id}
                                                    </TableCell>
                                                    <TableCell className={plr8px} align="left">
                                                        {flightCompany.code}
                                                    </TableCell>
                                                    <TableCell className={plr8px} align="left">
                                                        {flightCompany.name}
                                                    </TableCell>
                                                    <TableCell className={plr8px} align="left">
                                                        {flightCompany.url}
                                                    </TableCell>
                                                    <TableCell className={`${plr8px}`} align="center">
                                                        <IconButton size={'medium'}>
                                                            <EditIcon
                                                                color={'primary'}
                                                                onClick={() => fillUpdateCompany(flightCompany)}
                                                            />
                                                        </IconButton>

                                                        <ConfirmDeleteWithIcon
                                                            onApprove={() =>
                                                                dispatch(deleteFlightsCompanies(flightCompany.id))
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={flightCompanies.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </PageWrapper>
    )
}
FlightCompaniesTable.propTypes = {
    loading: PropTypes.bool,
    rows: PropTypes.array,
    deletingID: PropTypes.number,
    loadingDelete: PropTypes.bool,
}
export default connect((state) => ({
    addingNewCompany: addingFlightompanySelector(state),
}))(FlightCompaniesTable)
