import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {fetchAllPaymentsWithOrder, fetchAllPaymentsWithoutOrder, fetchForBankAccount} from '../../actions/fioPayments'
import FioPaymentsTable from '../../components/FioPayments/FioPaymentsTable'
import {Route, Switch} from 'react-router'
import FioCreatePayment from '../../components/FioPayments/FioCreatePayment'
import FioPaymentLogs from '../../components/FioPayments/FioPaymentLogs'

const FioPayments = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAllPaymentsWithoutOrder())
        dispatch(fetchAllPaymentsWithOrder())
        dispatch(fetchForBankAccount())
    }, [dispatch])

    return (
        <Switch>
            <Route exact path={'/fio_payments'} component={FioPaymentsTable} />
            <Route exact path={'/fio_payments/create_payment'} component={FioCreatePayment} />
            <Route exact path={'/fio_payments/payments_log'} component={FioPaymentLogs} />
        </Switch>
    )
}
export default FioPayments
