import {isNil, propOr} from 'ramda'

export const cFieldNames = {
    title: 'title',
    filter: 'filter',
    active: 'active',
    image: 'image',
}

export const initialValues = {
    [cFieldNames.title]: '',
    [cFieldNames.filter]: '{ }',
    [cFieldNames.active]: false,
    [cFieldNames.image]: {},
}

export const validate = (values) => {
    const errors = {}
    if (!values.title) {
        errors.title = 'Povinné pole'
    }
    if (!values.image && isNil(propOr(null, 'imageUrl', values))) {
        errors.image = 'Povinné pole'
    }
    if (!values.filter) {
        errors.filter = 'Povinné pole'
    }
    try {
        JSON.parse(values.filter)
    } catch (e) {
        errors.filter = 'Špatný formát JSON'
    }
    return errors
}
