import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {getAllLaunchScreens} from '../../actions/launchScreen'
import LaunchScreenFormContainer from '../../containers/LaunchScreen'

const LaunchScreen = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllLaunchScreens())
    }, [dispatch])

    return <LaunchScreenFormContainer />
}
export default LaunchScreen
