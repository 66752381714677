import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {fioPaymentsWithOrderSelector} from '../../selectors/fioPayments/fioPaymentsSelector'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import {isNotEmpty} from 'ramda-extension'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import {Button, Typography} from '@material-ui/core'
import {useHistory} from 'react-router'
import FioOrderSearcher from './FioOrderSearcher'
import {fetchAllPaymentsWithOrder} from '../../actions/fioPayments'
import {formatNumber} from '../../utils/formatNumber'
import {useStyles} from '../../css/useStyles'

const FioPaymentsWithtOrder = () => {
    const {content, totalElements, size, number} = useSelector(fioPaymentsWithOrderSelector)

    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [page, setPage] = useState(0)
    const [openFinder, setOpenFinder] = useState(false)
    const [paymentToSet, setPaymentToSet] = useState({})

    const dispatch = useDispatch()

    useEffect(() => {
        setPage(number)
        setRowsPerPage(size)
    }, [number, size])

    useEffect(() => {}, [dispatch, page, rowsPerPage])

    const {tableHeadGray, pointer} = useStyles()

    const history = useHistory()

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        dispatch(fetchAllPaymentsWithOrder(newPage, rowsPerPage))
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
        dispatch(fetchAllPaymentsWithOrder(0, parseInt(event.target.value, 10)))
    }

    const setPayment = (payment) => {
        setPaymentToSet(payment)
        setOpenFinder(true)
    }

    const closeModal = () => {
        setOpenFinder(false)
        setPaymentToSet(null)
    }

    return (
        <TableContainer component={Paper}>
            <FioOrderSearcher open={openFinder} payment={paymentToSet} close={closeModal} />
            <Table aria-label="simple table">
                <TableHead className={tableHeadGray}>
                    <TableRow>
                        <TableCell align="left">Externí ID</TableCell>
                        <TableCell align="left">Částka</TableCell>
                        <TableCell align="left">Popisek</TableCell>
                        <TableCell align="left">Objednávka</TableCell>
                        <TableCell align="left" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isNotEmpty(content) &&
                        content.map((payment, index) => (
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {payment.externalId}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {formatNumber(payment.amount)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {payment.description}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography
                                        className={pointer}
                                        color={'primary'}
                                        onClick={() => history.push(`/order/${payment.order}`)}>
                                        {payment.order}
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Button
                                        size={'small'}
                                        variant={'outlined'}
                                        onClick={() => setPayment(payment)}
                                        color={'primary'}>
                                        Přeřadit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </TableContainer>
    )
}

export default FioPaymentsWithtOrder
