import React, {useState} from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import {IconButton} from '@material-ui/core'
import ConfirmDialog from './index'

const ConfirmDeleteWithIcon = ({onApprove}) => {
    const [open, setOpen] = useState(false)

    const closeModal = () => {
        setOpen(false)
    }
    const openModal = () => {
        setOpen(true)
    }
    return (
        <React.Fragment>
            <IconButton onClick={openModal}>
                <DeleteIcon color="error" />
            </IconButton>
            <ConfirmDialog open={open} onConfirm={onApprove} closeModal={closeModal} />
        </React.Fragment>
    )
}

export default ConfirmDeleteWithIcon
