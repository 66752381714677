import React from 'react'
import {usePadding} from '../../css/usePadding'
import TypographyTitle from './TypographyTitle'

const HeadingTypography = ({title, children}) => {
    const {p1t} = usePadding()
    return (
        <div className={p1t}>
            {title && <TypographyTitle title={title} />}
            {children}
        </div>
    )
}
export default HeadingTypography
