import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'ID',
        minWidth: 100,
    },
    {
        id: 'code',
        numeric: false,
        disablePadding: false,
        label: 'Kód společnosti',
        minWidth: 120,
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Jméno společnosti',
        minWidth: 170,
    },
    {
        id: 'url',
        numeric: false,
        disablePadding: false,
        label: 'URL',
        minWidth: 170,
    },
]

const TableFlightCompanyHead = () => (
    <TableHead>
        <TableRow>
            {headCells.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.numeric ? 'left' : 'left'}>
                    {headCell.label}
                </TableCell>
            ))}
            <TableCell style={{minWidth: 100}} align="center">
                Akce
            </TableCell>
        </TableRow>
    </TableHead>
)

export default TableFlightCompanyHead
