import React from 'react'
import {Avatar, Paper, Grid, IconButton, ListItem, ListItemSecondaryAction} from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import {useDispatch} from 'react-redux'
import {changeInspirationOrderInDB, deleteInspiration} from '../../actions/inspirations'
import {initialize} from 'redux-form'
import {openInspirationModal} from '../../actions/modal'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import DragHandle from '../PropagateOffers/DragHandle'
import ConfirmDeleteWithIcon from '../ConfirmDialog/ConfirmDeleteWithIcon'
import formNames from '../../constants/form/formNames'
import {useColors} from '../../css/useColors'
import {useStyles} from '../../css/useStyles'

const InspirationItem = ({inspiration}) => {
    const {avatarSize5} = useStyles()
    const {svgGreen, svgRed} = useColors()
    const dispatch = useDispatch()
    const initInspirationForm = (data) => {
        dispatch(initialize(formNames.INSPIRATIONS_FORM, data))
        dispatch(openInspirationModal())
    }
    const deleteInspirationFromArray = () => {
        dispatch(deleteInspiration(inspiration.id))
        dispatch(changeInspirationOrderInDB())
    }
    return (
        <Paper square>
            <ListItem ContainerComponent="div">
                <DragHandle />
                <Grid container alignItems="center" spacing={2}>
                    <Grid item md={1}>
                        {inspiration.id}
                    </Grid>
                    <Grid item md={1}>
                        <Avatar alt="Remy Sharp" src={inspiration.imageUrl} className={avatarSize5} />
                    </Grid>
                    <Grid item md={8}>
                        {inspiration.title}
                    </Grid>
                    <Grid item md={1}>
                        <FiberManualRecordIcon className={inspiration.active ? svgGreen : svgRed} fontSize="small" />
                    </Grid>
                </Grid>
                <ListItemSecondaryAction>
                    <IconButton onClick={() => initInspirationForm(inspiration)}>
                        <CreateIcon color="primary" />
                    </IconButton>
                    <ConfirmDeleteWithIcon onApprove={deleteInspirationFromArray} />
                </ListItemSecondaryAction>
            </ListItem>
        </Paper>
    )
}

export default InspirationItem
