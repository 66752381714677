import {useEffect} from 'react'
import {withRouter} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {userAuthorizedSelector} from '../selectors/user'
import {isNilOrEmpty} from 'ramda-extension'
import {logout} from '../actions/users/logout'

const HistoryListener = ({history}) => {
    const isAuthorized = useSelector(userAuthorizedSelector)
    const dispatch = useDispatch()
    useEffect(
        () =>
            history.listen(() => {
                if (!isAuthorized || isNilOrEmpty(localStorage.getItem('token'))) {
                    console.log('change u')
                    dispatch(logout())
                }
            }),
        [dispatch, history, isAuthorized]
    )

    return ''
}

export default withRouter(HistoryListener)
