import React from 'react'
import {offersSelector} from '../../selectors/offers'
import {deleteOffer, reOrderOffers, setChangesInArray} from '../../actions/offers'
import {useDispatch, useSelector} from 'react-redux'
import SortableList from './SortableList'
import SpecialOffersModal from './Modal/SpecialOffersModal'
import arrayMove from 'array-move'

const ListOfOffers = ({classes, fillToUpdate, openSpecialOffersModal}) => {
    const offers = useSelector(offersSelector)
    const dispatch = useDispatch()

    const handleDeleteOffer = (id) => {
        dispatch(deleteOffer(id))
    }
    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex === newIndex) {
            return
        }
        dispatch(reOrderOffers(arrayMove(offers, oldIndex, newIndex)))
        dispatch(setChangesInArray())
    }
    return (
        <div>
            <SpecialOffersModal />
            <SortableList
                classes={classes}
                hideSortableGhost
                items={offers}
                distance={2}
                lockAxis="y"
                onSortEnd={onSortEnd}
                deleteOffer={handleDeleteOffer}
                fillToUpdate={fillToUpdate}
                openSpecialOffersModal={openSpecialOffersModal}
            />
        </div>
    )
}

export default ListOfOffers
