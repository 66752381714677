import React from 'react'
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import {useDispatch} from 'react-redux'
import {openReduxModal} from '../../actions/modal'
import modalNames from '../../constants/modal/modalNames'

const AddFlightCompanyButton = () => {
    const dispatch = useDispatch()
    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={() => dispatch(openReduxModal(modalNames.FLIGHT_COMPANIES_MODAL))}
            startIcon={<AddIcon />}>
            Přidej společnost
        </Button>
    )
}
export default AddFlightCompanyButton
