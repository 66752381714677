import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import {createAction} from 'redux-actions'
import ordersConstants from '../../constants/ordersConstants'
import fetchErrorHandler from '../../utils/fetchErrorHandler'

const fetchSuccess = createAction(ordersConstants.FETCH_ORDER_BY_ID)

export const resetDetail = createAction(ordersConstants.RESET_DETAIL)

export const fetchOrderDetail = (id) => (dispatch, getState) => {
    const {baseUrl, version, ordersPrefix} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.ORDER_DETAIL))
    Axios.get(`${baseUrl}${version}${ordersPrefix}/${id}`, config)
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.ORDER_DETAIL}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.ORDER_DETAIL))
}
