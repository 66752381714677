import React from 'react'
import PageWrapper from '../PageWrapper'
import FioCards from '../../assets/icons8-insert-card-64.png'
import {Button, Grid} from '@material-ui/core'
import FioAccountModal from './FioAccountModal'
import {useDispatch} from 'react-redux'
import {openFioAccountModal} from '../../actions/fioPayments'
import FioPaymentsWithoutOrder from './FioPaymentsWithoutOrder'
import FioPaymentsWithtOrder from './FioPaymentsWithOrder'
import FioPaymentIcon from '../../assets/icons8-card-payment-64.png'
import {useHistory} from 'react-router'
import {PAGES} from '../../utils/routes'
import LogIcon from '../../assets/icons8-event-log-48.png'
import {useMargin} from '../../css/useMargin'

const FioPaymentsTable = () => {
    const {mr10} = useMargin()

    const dispatch = useDispatch()

    const history = useHistory()

    const rightBar = (
        <Grid container spacing={2} alignItems={'center'}>
            <Grid item>
                <Button variant="outlined" color="primary" onClick={() => history.push('fio_payments/create_payment')}>
                    <img src={FioPaymentIcon} alt={'cardes'} width={30} className={mr10} />
                    Vytvořit platbu
                </Button>
            </Grid>
            <Grid item>
                <Button variant="outlined" color="primary" onClick={() => history.push('fio_payments/payments_log')}>
                    <img src={LogIcon} alt={'cardes'} width={30} className={mr10} />
                    Platební logy
                </Button>
            </Grid>
            <Grid item>
                <Button variant="outlined" color="primary" onClick={() => dispatch(openFioAccountModal())}>
                    <img src={FioCards} alt={'cardes'} width={30} className={mr10} />
                    Informace o účtu
                </Button>
            </Grid>
        </Grid>
    )

    return (
        <PageWrapper headingFor={PAGES.FIO_BANK} rightBar={rightBar}>
            <FioAccountModal />
            <Grid container direction={'column'} spacing={3}>
                <Grid item>
                    <FioPaymentsWithoutOrder />
                </Grid>
                <Grid item>
                    <FioPaymentsWithtOrder />
                </Grid>
            </Grid>
        </PageWrapper>
    )
}

export default FioPaymentsTable
