import React, {useEffect, useState} from 'react'
import {Collapse, Grid, Typography} from '@material-ui/core'
import {Link, useLocation} from 'react-router-dom'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {navUseStyles} from './styles'
import {PAGE_ROUTES, PAGE_TITLES} from '../../utils/routes'
import {isNilOrEmpty} from 'ramda-extension'
import {not} from 'ramda'
import {useStyles} from '../../css/useStyles'

const NavItem = ({itemFor, icon, children, collapse, clickable, onClick}) => {
    const classes = navUseStyles()
    const {pointer} = useStyles()
    const location = useLocation()
    const [openCollapse, setOpenCollapse] = useState(false)
    const isActive =
        not(isNilOrEmpty(itemFor)) && location.pathname.split('/')[1] === PAGE_ROUTES[itemFor].split('/')[1]
    useEffect(() => {
        setOpenCollapse(isActive)
    }, [location, isActive])
    return (
        <div>
            <div className={`${classes.navItem} `}>
                <div className={isActive ? classes.navItemSelected : ''} />
                <Grid container>
                    <Grid item xs={2} container justify="center" alignItems="center">
                        <img src={icon} className={isActive ? classes.iconActive : classes.icon} alt={'icon'} />
                    </Grid>
                    <Grid
                        item
                        xs={icon ? 8 : 10}
                        container
                        justify="flex-start"
                        alignItems="center"
                        className={classes.navItemText}>
                        {clickable ? (
                            <Typography
                                onClick={onClick}
                                className={`${isActive ? classes.navTextActive : classes.navText} ${pointer}`}
                                component={'span'}>
                                {not(isNilOrEmpty(itemFor)) ? PAGE_TITLES[itemFor] : children}
                            </Typography>
                        ) : (
                            <Link to={PAGE_ROUTES[itemFor]}>
                                <Typography
                                    className={isActive ? classes.navTextActive : classes.navText}
                                    component={'span'}>
                                    {not(isNilOrEmpty(itemFor)) ? PAGE_TITLES[itemFor] : children}
                                </Typography>
                            </Link>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        container
                        justify="flex-start"
                        alignItems="center"
                        onClick={() => setOpenCollapse(!openCollapse)}>
                        {collapse &&
                            (openCollapse ? (
                                <ExpandLess className={classes.expandIcon} />
                            ) : (
                                <ExpandMore className={classes.expandIcon} />
                            ))}
                    </Grid>
                </Grid>
            </div>
            <Collapse
                in={openCollapse}
                addEndListener={() => console.log('end event')}
                timeout="auto"
                unmountOnExit
                className={classes.collapseNavItem}>
                {children}
            </Collapse>
        </div>
    )
}

export default NavItem
