import React from 'react'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import {czkNumber} from '../../utils/formatNumber'
import TableContainer from '@material-ui/core/TableContainer'

const OrderTotalPrice = ({order}) => (
    <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableBody>
                <TableRow>
                    <TableCell scope="left">Celková cena</TableCell>
                    <TableCell align="left">{''}</TableCell>
                    <TableCell align="left" width={150}>
                        {czkNumber(order.totalPrice)}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
)
export default OrderTotalPrice
