import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import {createAction} from 'redux-actions'
import ordersConstants from '../../constants/ordersConstants'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import {toastSuccess} from '../../components/Toast/ToastSuccess'
import {fetchOrderDetail} from './orderDetail'
import {closeReduxModal} from '../modal'
import formNames from '../../constants/form/formNames'

const fetchSuccess = createAction(ordersConstants.FETCH_ORDERS_ALL_SUCCESS)

export const fetchAllOrders = (pageSize, pageNumber) => (dispatch, getState) => {
    const {baseUrl, version, ordersPrefix} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.ORDERS_ALL))
    Axios.get(`${baseUrl}${version}${ordersPrefix}`, {
        ...config,
        params: {
            page: pageNumber,
            size: pageSize,
        },
    })
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.ORDERS_ALL}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.ORDERS_ALL))
}

const afterInvoice = createAction(ordersConstants.AFTER_GENERATE)
export const getOrderInvoice = (orderID, regenerate, propagateToUser) => (dispatch, getState) => {
    const {baseUrl, version, ordersPrefix} = APIroutes
    const config = getAuthHeaders(getState())

    dispatch(setLoading(fetchConstants.ORDER_INVOICE))
    Axios.get(`${baseUrl}${version}${ordersPrefix}/${orderID}/contract`, {
        ...config,
        params: {
            generate: regenerate,
            propagateToUser,
        },
    })
        .then(({data}) => {
            dispatch(afterInvoice(data))
            dispatch(setNull({fetch: fetchConstants.ORDER_INVOICE}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.ORDER_INVOICE))
}

export const findOrderByString = (findString, cb) => (dispatch, getState) => {
    const {baseUrl, version, ordersPrefix, find} = APIroutes
    const config = getAuthHeaders(getState())

    Axios.get(`${baseUrl}${version}${ordersPrefix}${find}`, {
        ...config,
        params: {
            search: findString,
        },
    })
        .then(({data}) => {
            if (cb && typeof cb === 'function') {
                cb(data)
            }
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.ORDER_INVOICE))
}

const afterDelete = createAction(ordersConstants.DELETE_INVOICE_SUCCESS)

export const deleteContract = (orderId, contractId) => (dispatch, getState) => {
    const {baseUrl, version, ordersPrefix} = APIroutes
    const config = getAuthHeaders(getState())

    Axios.delete(`${baseUrl}${version}${ordersPrefix}/${orderId}/contract/${contractId}`, {
        ...config,
    })
        .then(({data}) => {
            dispatch(afterDelete(contractId))
            toastSuccess('Faktura úspěšně odstraněna')
            dispatch(fetchOrderDetail(orderId))
        })
        .catch((e) => {
            fetchErrorHandler(dispatch, e, fetchConstants.ORDER_INVOICE)
        })
}

export const stornoReservationWithAdminNote = (data) => (dispatch, getState) => {
    const {baseUrl, version, reservation} = APIroutes
    const config = getAuthHeaders(getState())

    Axios.post(`${baseUrl}${version}${reservation}/storno`, data, {
        ...config,
    })
        .then(() => {
            dispatch(fetchOrderDetail(data.orderId))
            toastSuccess('Rezervace stornována')
            dispatch(closeReduxModal(formNames.STORNO_ADMIN_NOTE_FORM))
        })
        .catch((e) => {
            fetchErrorHandler(dispatch, e, fetchConstants.ORDER_INVOICE)
            dispatch(closeReduxModal(formNames.ORDER_STORNO))
        })
}

export const setReservationDone = (data) => (dispatch, getState) => {
    const {baseUrl, version, reservation} = APIroutes
    const config = getAuthHeaders(getState())

    Axios.post(`${baseUrl}${version}${reservation}/done`, data, {
        ...config,
    })
        .then(() => {
            dispatch(fetchOrderDetail(data.orderId))
            toastSuccess('Rezervace dokončena')
        })
        .catch((e) => {
            fetchErrorHandler(dispatch, e, fetchConstants.ORDER_DONE)
        })
}
