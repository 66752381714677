import React from 'react'
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'
import toFormatDate from '../../utils/toFormatDate'
import {isNotEmpty, isNotNil} from 'ramda-extension'
import TypographyTitle from './TypographyTitle'
import {useStyles} from '../../css/useStyles'

const Notifications = ({notifications}) => {
    const {tableHeadGray} = useStyles()
    return (
        <div>
            {isNotNil(notifications) && (
                <React.Fragment>
                    <Grid container>
                        <Grid item md={5}>
                            <TypographyTitle title={'Odeslané notifikace'} />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead className={tableHeadGray}>
                                <TableRow>
                                    <TableCell align="left">Předmět</TableCell>
                                    <TableCell align="left">Obsah</TableCell>
                                    <TableCell align="left">Odesláno</TableCell>
                                    <TableCell align="left">Typ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isNotEmpty(notifications) &&
                                    notifications.map(({id, subject, content, sentAt, type}) => (
                                        <TableRow key={id}>
                                            <TableCell align="left">{subject}</TableCell>
                                            <TableCell align="left">{content}</TableCell>
                                            <TableCell width={150} align="left">
                                                {toFormatDate(sentAt)}
                                            </TableCell>
                                            <TableCell align="left">{type}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            )}
        </div>
    )
}
export default Notifications
