import codelistConstants from '../constants/codelistConstants'
import {handleActions} from 'redux-actions'

const initialState = {}
export default handleActions(
    {
        [codelistConstants.CODELIST_FETCH_SUCCESS]: (state, {payload}) => payload,
    },
    initialState
)
