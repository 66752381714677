import {handleActions} from 'redux-actions'
import offersConstants from '../constants/offersConstants'

const initialState = []

export default handleActions(
    {
        [offersConstants.FETCH_OFFERS_SUCCESS]: (state, {payload}) => payload,
        [offersConstants.RE_ORDER_OFFERS]: (state, {payload}) =>
            payload.map((offer, index) => ({...offer, location: index})),
        [offersConstants.FETCH_OFFER_DELETE_SUCCESS]: (state, {payload}) =>
            state.filter((offer) => offer.id !== payload),
        [offersConstants.AFTER_CREATE_OFFER]: (state, {payload}) => {
            const offers = [...state, payload]
            return offers.sort((a, b) => {
                return a.index - b.index
            })
        },
        [offersConstants.UPDATE_WITH_PICTURE_SUCCESS]: (state, {payload}) => {
            return state.map((offer) => (offer.id === payload.id ? payload : offer))
        },
        [offersConstants.UPDATE_WITHOUT_PICTURE_SUCCESS]: (state, {payload}) => {
            return state.map((offer) => (offer.id === payload.id ? payload : offer))
        },
    },
    initialState
)
