import {keyMirror} from 'ramda-extension'

export default keyMirror({
    FETCH_OFFERS_SUCCESS: null,
    FETCH_OFFER_DELETE_SUCCESS: null,
    AFTER_CREATE_OFFER: null,
    RE_ORDER_OFFERS: null,
    CHANGE_OFFERS_POSIITONS_SUCCESS: null,
    UPDATE_WITHOUT_PICTURE_SUCCESS: null,
    UPDATE_WITH_PICTURE_SUCCESS: null,
    OPEN_FORM: null,
    CLOSE_FORM: null,
    OFFERS_LOCATION_CHANGED: null,
    OFFERS_LOCATION_SAVED: null,
})
