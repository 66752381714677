import {Paper} from '@material-ui/core'
import React from 'react'
import {usePadding} from '../../css/usePadding'

const FlightNotes = ({order: {flightNote}}) => {
    const {p16px} = usePadding()
    return <Paper className={p16px}>{flightNote}</Paper>
}

export default FlightNotes
