import {SortableContainer} from 'react-sortable-hoc'
import {Card, Grid, ListItem, ListItemSecondaryAction} from '@material-ui/core'
import DragHandle from './DragHandle'
import SortableItem from './PropagateOfferItem'
import React from 'react'
import {usePadding} from '../../css/usePadding'

const SortablePropagateOfferList = SortableContainer(({items}) => {
    const {MUIListHeading} = usePadding()
    return (
        <Grid container justify="center" direction="column">
            <Card square>
                <ListItem ContainerComponent="div" className={MUIListHeading}>
                    <DragHandle unvisible={true} />
                    <Grid container alignItems="center" spacing={2} direction={'row'}>
                        <Grid item md={1}>
                            ID
                        </Grid>
                        <Grid item md={3}>
                            Titulek
                        </Grid>
                        <Grid item md={6}>
                            Filtr
                        </Grid>
                        <Grid item md={1}>
                            Aktivní
                        </Grid>
                    </Grid>
                    <ListItemSecondaryAction>{''}</ListItemSecondaryAction>
                </ListItem>
            </Card>
            {items.map((value, index) => (
                <SortableItem key={`item-${value.id}`} index={index} value={value} />
            ))}
        </Grid>
    )
})

export default SortablePropagateOfferList
