import {keyMirror} from 'ramda-extension'

export default keyMirror({
    PROPAGATE_OFFERS_FORM: null,
    SPECIAL_OFFERS_FORM: null,
    USERS_FORM_FORM: null,
    INSPIRATIONS_FORM: null,
    FLIGHT_COMPANIES_FORM: null,
    DESTINATIONS_TEMPERATURE_FORM: null,
    LAUNCH_SCREEN_FORM: null,
    STORNO_ADMIN_NOTE_FORM: null,
})
