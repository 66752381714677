import React from 'react'
import {Route} from 'react-router'
import OrderTable from '../../components/OrderTable'
import UsersTable from '../../components/UserTable/Table'
import {connect} from 'react-redux'
import PrivateRoute from '../../utils/PrivateRoute'
import roles from '../../constants/roles'
import {userSelector} from '../../selectors/user'
import OrderDetail from '../../pages/OrderDetail'
import SpecialOffer from '../../pages/SpecialOffers'
import FlightCompany from '../../pages/FlightCompany'
import DestinationTemperatures from '../../pages/DestinationTemperatures'
import PropagateOffers from '../../pages/PropagateOffers'
import Inspirations from '../../pages/Inspirations'
import Notification from '../../components/Notification'
import LaunchScreen from '../../pages/LaunchScreen'
import ErrorBoundary from '../../utils/ErrorBoundary'
import AppleSession from '../../pages/AppleSession'
import FioPayments from '../../pages/FioPayments'
import HistoryListener from '../../components/HistoryListener'

const DashboardRoutes = () => (
    <ErrorBoundary>
        <HistoryListener />
        <Route exact path={'/'} render={() => <OrderTable />} />
        <PrivateRoute path={'/users'} roles={[roles.ROLE_ADMIN]} component={UsersTable} />
        <PrivateRoute path={'/order/:id'} roles={[roles.ROLE_ADMIN]} component={OrderDetail} />
        <PrivateRoute path={'/offers'} roles={[roles.ROLE_ADMIN]} component={SpecialOffer} />
        <PrivateRoute path={'/flight_companies'} roles={[roles.ROLE_ADMIN]} component={FlightCompany} />
        <PrivateRoute
            path={'/destination_temperatures'}
            roles={[roles.ROLE_ADMIN]}
            component={DestinationTemperatures}
        />
        <PrivateRoute path={'/propagate_offers'} roles={[roles.ROLE_ADMIN]} component={PropagateOffers} />
        <PrivateRoute path={'/inspirations'} roles={[roles.ROLE_ADMIN]} component={Inspirations} />
        <PrivateRoute path={'/notification'} roles={[roles.ROLE_ADMIN]} component={Notification} />
        <PrivateRoute path={'/launch_screen'} roles={[roles.ROLE_ADMIN]} component={LaunchScreen} />
        <PrivateRoute path={'/apple_session_cert'} roles={[roles.ROLE_ADMIN]} component={AppleSession} />
        <PrivateRoute path={'/fio_payments'} roles={[roles.ROLE_ADMIN]} component={FioPayments} />
    </ErrorBoundary>
)

export default connect((state) => ({user: userSelector(state)}))(DashboardRoutes)
