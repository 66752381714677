import {keyMirror} from 'ramda-extension'

export default keyMirror({
    LOGIN: null,
    LOGIN_SUCCESS: null,
    LOGIN_ERROR: null,
    ALLUSERS_SUCCESS: null,
    CREATE_USER_SUCCESS: null,
    CREATE_USER_MODAL_OPEN: null,
    CREATE_USER_MODAL_CLOSE: null,
    SET_DELETING_USER: null,
    NULL_DELETING_USER: null,
    DELETE_USER: null,
    EDIT_USER_MODAL_OPEN: null,
    EDIT_USER_MODAL_CLOSE: null,
    EDIT_USER_SUCCESS: null,
    LOGOUT: null,
})
