import {Icon} from '@material-ui/core'
import React from 'react'
import {useStyles} from '../../css/useStyles'

const Icon8 = ({src}) => {
    const {flexCenter, heightFull} = useStyles()
    return (
        <Icon classes={{root: flexCenter}}>
            <img src={src} className={heightFull} alt={'pdf'} />
        </Icon>
    )
}
export default Icon8
