import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import {IconButton} from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import TableRow from '@material-ui/core/TableRow'
import toFormatDate from '../../utils/toFormatDate'
import {useColors} from '../../css/useColors'

const SearchOrderTableRow = ({
    row: {id, createdAt, lastName, firstName, externalId, status, totalPrice, provider},
    clickHandler,
}) => {
    const {arrowBlue} = useColors()

    return (
        <TableRow hover tabIndex={-1} key={id}>
            <TableCell component="th" scope="row">
                {id}
            </TableCell>

            <TableCell align="left">{externalId}</TableCell>
            <TableCell align="left">{toFormatDate(createdAt)}</TableCell>
            <TableCell align="left">{`${firstName} ${lastName}`}</TableCell>
            <TableCell align="left">{status}</TableCell>
            <TableCell align="left">{totalPrice} Kč</TableCell>
            <TableCell align="left">{provider}</TableCell>

            <TableCell align="center">
                <IconButton onClick={(e) => clickHandler(e, id)}>
                    <SendIcon color={'action'} className={arrowBlue} />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default SearchOrderTableRow
