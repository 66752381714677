import React from 'react'
import {Dialog} from '@material-ui/core'
import {createUser} from '../../actions/users/createUser'
import {connect} from 'react-redux'
import {closeUserModal} from '../../actions/modal'
import {reduxForm, reset} from 'redux-form'
import {validate} from './TextField'
import Form from './Form'
import {loadingUserCreateSelector} from '../../selectors/fetches/createUserFetch'
import {openCreateUserModalSelector} from '../../selectors/modals'
import PropTypes from 'prop-types'

const titleSize = 5
const fieldSize = 7

const UserFormDialog = ({open, reset, dispatch, loading, handleSubmit, closeUserModal, createUser}) => {
    const submit = (values) => {
        createUser(values)
    }
    return (
        <Dialog
            open={open}
            onBackdropClick={() => {
                reset('createUser')
                closeUserModal()
            }}>
            <Form
                formName="createUser"
                edit={false}
                close={closeUserModal}
                titleSize={titleSize}
                fieldSize={fieldSize}
                loading={loading}
                dispatch={dispatch}
                submit={submit}
                handleSubmit={handleSubmit}
            />
        </Dialog>
    )
}
UserFormDialog.propTypes = {
    open: PropTypes.bool,
    reset: PropTypes.func,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    handleSubmit: PropTypes.func,
    closeUserModal: PropTypes.func,
    createUser: PropTypes.func,
}
export default connect(
    (state) => ({
        loading: loadingUserCreateSelector(state),
        open: openCreateUserModalSelector(state),
    }),
    {createUser, reset, closeUserModal}
)(
    reduxForm({
        form: 'createUser',
        validate,
    })(UserFormDialog)
)
