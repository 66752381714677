import {keyMirror} from 'ramda-extension'

export default keyMirror({
    PROPAGATE_OFFER_MODAL: null,
    FLIGHT_COMPANIES_MODAL: null,
    SPECIAL_OFFERS_MODAL: null,
    INSPIRATIONS_MODAL: null,
    DESTINATION_TEMPERATURES_MODAL: null,
    LAUNCH_SCREEN_MODAL: null,
})

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
