import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import PropagateForm from './Form'
import {connect, useDispatch, useSelector} from 'react-redux'
import {closeReduxModal, openErrorModal} from '../../../actions/modal'
import {submit} from 'redux-form'
import SaveIcon from '@material-ui/icons/Save'
import {createPropagateOffers, updatePropagateOffers} from '../../../actions/propagateOffers'
import {createPropagateOfferLoading, updatePropagateOfferLoading} from '../../../selectors/fetches/propagateOffer'
import isNotNil from 'ramda-extension/lib/isNotNil'
import formNames from '../../../constants/form/formNames'
import {modalOpenSelector} from '../../../selectors/modals'
import modalNames from '../../../constants/modal/modalNames'

const PropagateOfferDialog = ({updatePropagateOffers, createPropagateOffers, submit, openErrorModal}) => {
    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch(closeReduxModal(modalNames.PROPAGATE_OFFER_MODAL))
    }

    const open = useSelector(modalOpenSelector(modalNames.PROPAGATE_OFFER_MODAL))

    const submitUpdate = (values) => {
        try {
            JSON.parse(values.filter)
        } catch (e) {
            openErrorModal({error: new Error('Špatný formát JSON'), fetch: 'Textové pole filter'})
            return
        }
        if (isNotNil(values.id)) {
            updatePropagateOffers(values)
        } else {
            createPropagateOffers(values)
        }
    }

    const submitForm = () => submit(formNames.PROPAGATE_OFFERS_FORM)

    return (
        <Dialog open={open} onEscapeKeyDown={closeModal} maxWidth="sm" fullWidth>
            <DialogTitle id="form-dialog-title">Propagační nabídky</DialogTitle>
            <DialogContent>
                <PropagateForm onSubmit={submitUpdate} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={closeModal}>
                    Zavřít
                </Button>

                <Button variant={'outlined'} color="primary" size="small" onClick={submitForm} startIcon={<SaveIcon />}>
                    Uložit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default connect(
    (state) => ({
        loadingUpdate: updatePropagateOfferLoading(state),
        loadingCreate: createPropagateOfferLoading(state),
    }),
    {
        submit,
        updatePropagateOffers,
        createPropagateOffers,
        openErrorModal,
    }
)(PropagateOfferDialog)
