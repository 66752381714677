import {keyMirror} from 'ramda-extension'

export default keyMirror({
    ALL_FIO_PAYMENTS_SUCCESS: null,
    OPEN_FIO_ACCOUNT_MODAL: null,
    CLOSE_FIO_ACCOUNT_MODAL: null,
    ALL_PAYMENTS_WITHOUT_ORDER: null,
    ALL_PAYMENTS_WITH_ORDER: null,
    ACCOUNT_SUCCESS: null,
    SET_PAYMENT_TO_ORDER: null,
})
