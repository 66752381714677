import React, {useEffect, useState} from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import {login} from '../../actions/users/login'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {errorLoginSelector, loadingLoginSelector} from '../../selectors/fetches/loginFetch'
import MenuItem from '@material-ui/core/MenuItem'
import {equals, identity, ifElse, not} from 'ramda'
import {API_URL, APIroutes, BASE_URL} from '../../constants/routes'
import {isNilOrEmpty} from 'ramda-extension'
import {useStyles} from '../../css/useStyles'
import {useMargin} from '../../css/useMargin'

const Login = ({loading, error: {status, msg}, errorBody, isAuthorized, push, login}) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [apiInput, setApiInput] = useState('https://')
    const [env, setEnv] = useState(API_URL.PROD_API)
    const {flexCenter, minHeight100vh} = useStyles()
    const {mt15} = useMargin()

    const onSubmit = (e) => {
        e.preventDefault()
        if (equals(env, API_URL.PROD_API)) {
            localStorage.setItem(API_URL.BASE_URL, APIroutes[API_URL.PROD_API])
            localStorage.setItem(API_URL.API_ACTIVE, API_URL.PROD_API)
            APIroutes[BASE_URL] = APIroutes[API_URL.PROD_API]
        }
        if (equals(env, API_URL.DEV_API)) {
            localStorage.setItem(API_URL.BASE_URL, APIroutes[API_URL.DEV_API])
            localStorage.setItem(API_URL.API_ACTIVE, API_URL.DEV_API)
            APIroutes[BASE_URL] = APIroutes[API_URL.DEV_API]
        }
        if (equals(env, API_URL.CUSTOM_API)) {
            localStorage.setItem(API_URL.BASE_URL, apiInput)
            localStorage.setItem(API_URL.API_ACTIVE, API_URL.CUSTOM_API)
            APIroutes[BASE_URL] = apiInput
        }
        login(username, password)
    }

    useEffect(() => {
        if (isAuthorized) {
            push('/')
        }
    }, [isAuthorized, push])

    useEffect(() => {
        ifElse(isNilOrEmpty, identity, () => {
            const activeAPI = localStorage.getItem(API_URL.API_ACTIVE)
            if (not(isNilOrEmpty(activeAPI))) {
                if (equals(API_URL.PROD_API, activeAPI)) {
                    setEnv(API_URL.PROD_API)
                    APIroutes[BASE_URL] = APIroutes[API_URL.PROD_API]
                }
                if (equals(API_URL.DEV_API, activeAPI)) {
                    setEnv(API_URL.DEV_API)
                    APIroutes[BASE_URL] = APIroutes[API_URL.DEV_API]
                }
                if (equals(API_URL.CUSTOM_API, activeAPI)) {
                    setEnv(API_URL.CUSTOM_API)
                    setApiInput(localStorage.getItem(API_URL.BASE_URL))
                    APIroutes[BASE_URL] = localStorage.getItem(API_URL.BASE_URL)
                }
            }
        })(localStorage.getItem(API_URL.BASE_URL))
    }, [])

    return (
        <Container component="main" maxWidth="xs" className={`${minHeight100vh} ${flexCenter}`}>
            <CssBaseline />
            <div>
                <Typography component="h1" variant="h5">
                    Přihlásit se
                </Typography>
                <form noValidate onSubmit={onSubmit}>
                    <TextField
                        select
                        fullWidth
                        margin={'normal'}
                        name="env"
                        value={env}
                        label={'Prostředí'}
                        id={'env'}
                        variant={'outlined'}
                        onChange={({target}) => setEnv(target.value)}>
                        <MenuItem value={API_URL.PROD_API}>{'Produkce'}</MenuItem>
                        <MenuItem value={API_URL.DEV_API}>{'Develop'}</MenuItem>
                        <MenuItem value={API_URL.CUSTOM_API}>{'Custom'}</MenuItem>
                    </TextField>
                    {equals(env, API_URL.CUSTOM_API) && (
                        <TextField
                            value={apiInput}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="API URL"
                            name="apiUrl"
                            autoFocus
                            onChange={({target}) => setApiInput(target.value)}
                        />
                    )}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        error={status}
                        fullWidth
                        id="email"
                        label="Uživatelské jméno"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={({target}) => setUsername(target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        error={status}
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={({target}) => setPassword(target.value)}
                    />
                    <Button
                        className={mt15}
                        type="submit"
                        fullWidth
                        margin={'normal'}
                        variant="outlined"
                        color="primary"
                        disabled={loading}>
                        {'Přihlásit'}
                    </Button>
                </form>
            </div>
        </Container>
    )
}

export default connect(
    (state) => ({
        loading: loadingLoginSelector(state),
        error: errorLoginSelector(state),
    }),
    {login, push}
)(Login)
