import {makeStyles} from '@material-ui/core/styles'
import colours from '../css/colors'

export const useStyles = makeStyles((theme) => ({
    avatarSize5: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    avatarSize7: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    grayScale: {
        filter: 'grayscale(1)',
    },
    pointer: {
        cursor: 'pointer',
    },
    minHeight100vh: {
        minHeight: '100vh',
    },
    w100: {
        width: '100%',
    },
    errorButton: {
        border: `1px solid ${colours.ERROR}`,
        color: colours.ERROR,
    },
    successButton: {
        border: `1px solid ${colours.SUCCESS}`,
        color: colours.SUCCESS,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
    },
    destinationInputWidth: {
        width: '230px',
    },
    relative: {
        position: 'relative',
    },
    fileInputSelectText: {
        fontWeight: 600,
        color: theme.palette.primary.main,
        fontSize: '1rem',
    },
    fileInput: {
        visibility: 'hidden',
        position: 'absolute',
        cursor: 'pointer',
    },
    fontSize07: {
        fontSize: '0.6rem',
    },
    tableHeadGray: {
        background: colours.TABLE_HEAD_GRAY,
    },
    userPreviewTableDetail: {
        borderRadius: '15px 15px 0px 0px',
        border: '1px solid #ececec',
        backgroundColor: '#ececec',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px -2px 4px',
        marginTop: '10px',
    },
    heightFull: {
        height: '100%',
    },
    iconRoot: {
        textAlign: 'center',
        display: 'flex',
    },
    airCompanyCodeInput: {
        width: '50px',
    },
    wrapperHeading: {
        color: theme.palette.primary.main,
        fontSize: '1.925rem',
        fontWeight: 500,
    },
}))
