import React, {useEffect, useState} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import {connect} from 'react-redux'
import {loadingGetAllOrdersSelector} from '../../selectors/fetches'
import TableOrdersHead from './TableOrdersHead'
import TableOrdersToolbar from './TableOrdersToolbar'
import {ordersSelector} from '../../selectors/orders'
import {fetchAllOrders, findOrderByString} from '../../actions/orders'
import {push} from 'connected-react-router'
import {resetDetail} from '../../actions/orders/orderDetail'
import PropTypes from 'prop-types'
import {toSortDate} from '../../utils/toFormatDate'
import PageWrapper from '../PageWrapper'
import OrderRefreshButton from '../Buttons/OrderRefreshButton'
import {PAGES} from '../../utils/routes'
import SearchOrderTableRow from './SearchOrderTableRow'
import {isNotEmpty} from 'ramda-extension'
import OrderTableRow from './OrderTableRow'

const OrdersTable = ({pageable, dispatch}) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [search, setSearch] = useState('')
    const [searchResult, setSearchResult] = useState([])

    const {content, totalElements} = pageable
    const rows = content

    const sortedRows = rows.sort((a, b) => toSortDate(b.createdAt) - toSortDate(a.createdAt))

    useEffect(() => {
        dispatch(fetchAllOrders(rowsPerPage, page))
        dispatch(resetDetail())
    }, [dispatch, page, rowsPerPage])

    const handleSearch = (value) => {
        dispatch(
            findOrderByString(value, (data) => {
                setSearchResult(data)
            })
        )
        setSearch(value)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const clickHandler = (event, id) => {
        event.stopPropagation()
        event.ctrlKey ? window.open(`/order/${id}`) : dispatch(push(`/order/${id}`))
    }

    const searchingActive = isNotEmpty(search)

    return (
        <PageWrapper
            headingFor={PAGES.RESERVATIONS}
            rightBar={<OrderRefreshButton page={page} rowsPerPage={rowsPerPage} />}>
            <Paper>
                <TableOrdersToolbar setSearch={handleSearch} search={search} />
                <TableContainer>
                    <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
                        <TableOrdersHead />
                        {
                            <TableBody>
                                {searchingActive
                                    ? searchResult.map((row) => (
                                          <SearchOrderTableRow row={row} clickHandler={clickHandler} />
                                      ))
                                    : sortedRows.map((row) => <OrderTableRow row={row} clickHandler={clickHandler} />)}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </PageWrapper>
    )
}

OrdersTable.propTypes = {
    loading: PropTypes.bool,
    pageable: PropTypes.object,
    dispatch: PropTypes.func,
}
export default connect((state) => ({
    loading: loadingGetAllOrdersSelector(state),
    pageable: ordersSelector(state),
}))(OrdersTable)
