import {handleActions} from 'redux-actions'
import flightConstants from '../constants/flightConstants'

const initialState = []

export default handleActions(
    {
        [flightConstants.FLIGHT_COMPANIES_ALL_SUCCESS]: (state, {payload}) => payload,
        [flightConstants.FLIGHT_COMPANIES_CREATE_SUCCESS]: (state, {payload}) => [...state, payload],
        [flightConstants.FLIGHT_COMPANIES_DELETE_SUCCESS]: (state, {payload}) =>
            state.filter((company) => company.id !== payload),
        [flightConstants.FLIGHT_COMPANIES_UPDATE_SUCCESS]: (state, {payload}) =>
            state.map((company) => (company.id === payload.id ? payload : company)),
    },
    initialState
)
