import {createSelector} from 'reselect'
import fetchConstants from '../../../constants/fetchConstants'

export const fetchRequestsSelector = (state) => state.fetch.requests

export const getAllOrdersFetchSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.ORDERS_ALL]
)
export const loadingGetAllOrdersSelector = createSelector(getAllOrdersFetchSelector, (state) => state.loading)
/* ORDERS */
export const getDetailOrderFetchSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.ORDER_DETAIL]
)
export const loadingDetailOrderSelector = createSelector(getDetailOrderFetchSelector, (state) => state.loading)

export const orderDetailSelector = (state) => state.order

export const orderPeopleCountSelector = createSelector(orderDetailSelector, (order) => order.orderPeople.length)
