import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {fetchAllFlightsCompanies} from '../../actions/flightCompanies'
import {flightCompaniesSelector} from '../../selectors/flightCompanies'
import FlightCompaniesTable from '../../components/FlightCompaniesTable/Table'

const FlightCompany = () => {
    const dispatch = useDispatch()
    const flightCompanies = useSelector(flightCompaniesSelector)
    useEffect(() => {
        dispatch(fetchAllFlightsCompanies())
    }, [dispatch])
    return <FlightCompaniesTable flightCompanies={flightCompanies} />
}

export default FlightCompany
