import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import {toastSuccess} from '../../components/Toast/ToastSuccess'

export const getAppleSessionTxtWellKnown = (cb) => (dispatch, getState) => {
    const {baseUrl, contentOfAppleTxt} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.GET_APPLE_SESSION_TXT))
    Axios.get(`${baseUrl}${contentOfAppleTxt}`, config)
        .then(({data}) => {
            cb(data)
            dispatch(setNull({fetch: fetchConstants.GET_APPLE_SESSION_TXT}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.GET_APPLE_SESSION_TXT))
}

export const postNewContent = (content, cb) => (dispatch, getState) => {
    const {baseUrl, appleWellknown} = APIroutes
    const data = {
        textContent: content,
    }
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.GET_APPLE_SESSION_TXT))
    Axios.put(`${baseUrl}${appleWellknown}`, data, config)
        .then(({data}) => {
            toastSuccess('Úspěšně změněno')
            cb(data)
            dispatch(setNull({fetch: fetchConstants.GET_APPLE_SESSION_TXT}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.GET_APPLE_SESSION_TXT))
}
