import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {fioAccountInfoSelector, fioAccountModalOpenSelector} from '../../selectors/fioPayments/fioPaymentsSelector'
import Dialog from '@material-ui/core/Dialog'
import {Button, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@material-ui/core'
import {closeFioAccountModal} from '../../actions/fioPayments'
import GridAccountInfoRow from './GridAccountInfoRow'

const FioAccountModal = () => {
    const {accountId, bankId, iban, bic, closingBalance, idLastDownload, currency, dateStart, dateEnd} = useSelector(
        fioAccountInfoSelector
    )
    const dispatch = useDispatch()

    const open = useSelector(fioAccountModalOpenSelector)

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle id="form-dialog-title">
                <Typography component={'span'} color={'primary'} variant={'h5'}>
                    Informace o účtu
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid contianer alignItems={'center'} direction={'column'} spacing={4}>
                    <GridAccountInfoRow heading={'ID účtu'} content={accountId} />
                    <GridAccountInfoRow heading={'ID banky'} content={bankId} />
                    <GridAccountInfoRow heading={'IBAN'} content={iban} />
                    <GridAccountInfoRow heading={'BIC'} content={bic} />
                    <GridAccountInfoRow heading={'Stav účtu'} content={`${closingBalance} ${currency}`} />
                    <GridAccountInfoRow heading={'ID poslední zarážky'} content={idLastDownload} />
                    <GridAccountInfoRow heading={'Od'} content={dateStart} />
                    <GridAccountInfoRow heading={'Do'} content={dateEnd} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => dispatch(closeFioAccountModal())}>
                    Zavřít
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default FioAccountModal
