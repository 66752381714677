import React from 'react'
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    MenuItem,
} from '@material-ui/core'
import roles from '../../constants/roles'
import {Field, reset} from 'redux-form'
import {renderSelectField, renderTextField} from './TextField'
import PropTypes from 'prop-types'

const Form = ({
    fieldSize,
    titleSize,
    edit,
    handleSubmit,
    submit,
    dispatch,
    loading,
    close,
    initLoading,
    password,
    setPassword,
    formName,
}) => (
    <form onSubmit={handleSubmit(submit)}>
        <DialogTitle id="form-dialog-title">{edit ? 'Editovat uživatele' : 'Přidat nového uživatele '}</DialogTitle>
        <DialogContent>
            <Grid container spacing={1} justify="center">
                <Grid item xs={12} sm={titleSize} container justify="center" alignItems="center">
                    Uživatelské jméno
                </Grid>
                <Grid item xs={12} sm={fieldSize}>
                    <Field required name="userName" component={renderTextField} />
                </Grid>
                <Grid item xs={12} sm={titleSize} container justify="center" alignItems="center">
                    Jméno
                </Grid>
                <Grid item xs={12} sm={fieldSize}>
                    <Field required name="firstName" component={renderTextField} />
                </Grid>
                <Grid item xs={12} sm={titleSize} container justify="center" alignItems="center">
                    Příjmení
                </Grid>
                <Grid item xs={12} sm={fieldSize}>
                    <Field required name="lastName" component={renderTextField} />
                </Grid>
                <Grid item xs={12} sm={titleSize} container justify="center" alignItems="center">
                    Email
                </Grid>
                <Grid item xs={12} sm={fieldSize}>
                    <Field required name="email" component={renderTextField} />
                </Grid>

                <Grid item xs={12} sm={titleSize} container justify="center" alignItems="center">
                    Heslo
                </Grid>
                <Grid item xs={12} sm={fieldSize}>
                    {edit ? (
                        !initLoading && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={password}
                                        onChange={() => setPassword(!password)}
                                        value="gilad"
                                    />
                                }
                                label="Změnit heslo ?"
                            />
                        )
                    ) : (
                        <Field required type="password" name="password" component={renderTextField} />
                    )}
                </Grid>
                {edit && password && (
                    <React.Fragment>
                        <Grid item xs={12} sm={titleSize} container justify="center" alignItems="center" />
                        <Grid item xs={12} sm={fieldSize}>
                            <Field required type="password" name="password" component={renderTextField} />
                        </Grid>
                    </React.Fragment>
                )}

                <Grid item xs={12} sm={titleSize} container justify="center" alignItems="center">
                    Role
                </Grid>
                <Grid item xs={12} sm={fieldSize}>
                    <Field required name="role" component={renderSelectField}>
                        {Object.keys(roles).map((role) => (
                            <MenuItem value={role} key={role}>
                                {role}
                            </MenuItem>
                        ))}
                    </Field>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    dispatch(reset(formName))
                    dispatch(close())
                }}
                color="primary">
                Zavřít
            </Button>
            <Button type="submit" color="primary">
                {loading ? '' : edit ? 'Editovat' : ' Přidat'}
            </Button>
        </DialogActions>
    </form>
)
Form.propTypes = {
    fieldSize: PropTypes.number,
    titleSize: PropTypes.number,
    edit: PropTypes.bool,
    handleSubmit: PropTypes.func,
    submit: PropTypes.func,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    close: PropTypes.func,
    initLoading: PropTypes.bool,
    password: PropTypes.bool,
    setPassword: PropTypes.func,
    formName: PropTypes.string,
}
export default Form
