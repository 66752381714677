import React from 'react'
import {Dialog, DialogContent} from '@material-ui/core'
import Logo from '../../assets/tringa-bird-svg.svg'
// TODO IMPLEMTNOVAT POMOCI @component webpack

const Loader = ({open}) => {
    return (
        <Dialog
            open={open}
            BackdropProps={{style: {backgroundColor: 'transparent'}}}
            style={{
                backgroundColor: 'transparent',
            }}
            overlayStyle={{backgroundColor: 'transparent'}}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    overflow: 'hidden',
                },
            }}>
            <DialogContent>
                <div
                    className="loader-wrapper"
                    style={{
                        // TODO STYLY classes
                        backgroundImage: `url(${Logo})`,
                        backgroundSize: '70%',
                        backgroundPosition: '26px center',
                        backgroundRepeat: 'no-repeat',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <div className="loader" />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default Loader
