import React from 'react'
import {toast} from 'react-toastify'
import {Alert} from '@material-ui/lab'

export const toastSuccess = (msg) =>
    toast.success(<Alert severity="success">{msg}</Alert>, {
        position: 'bottom-center',
        hideProgressBar: true,
        style: {
            backgroundColor: 'rgb(237, 247, 237)',
            borderRadius: '5px',
        },
    })
