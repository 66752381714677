import useStyles from '../Dashboard/classes'
import React from 'react'
import {Grid, Container, CssBaseline} from '@material-ui/core'
import Loader from '../../components/Loader'

const LoadingPage = () => {
    const classes = useStyles()
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Grid container justify="center" alignItems="center" className={classes.minHeight}>
                    <Loader />
                </Grid>
            </div>
        </Container>
    )
}
export default LoadingPage
