import Axios from 'axios'
import userConstants from '../../constants/userConstants'
import {createAction} from 'redux-actions'
import {setLoading, setNull} from '../fetch'
import fetchConstants from '../../constants/fetchConstants'
import {APIroutes} from '../../constants/routes'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import fetchErrorHandler from '../../utils/fetchErrorHandler'

const fetchSuccess = createAction(userConstants.ALLUSERS_SUCCESS)

export const loadAllUsrs = (pageSize, pageNumber) => (dispatch, getState) => {
    const {baseUrl, findAllUsers} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.USER_ALL))
    Axios.get(`${baseUrl}/v1${findAllUsers}`, {
        ...config,
        params: {
            page: pageNumber,
            size: pageSize,
        },
    })
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            return dispatch(setNull({fetch: fetchConstants.USER_ALL}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.USER_ALL))
}
