import React, {useEffect, useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core'
import DestinationTemperatureModal from '../../components/DestinationTemperatureModal'
import {useDispatch, useSelector} from 'react-redux'
import {fetchAllDestintionsTemperatures} from '../../actions/destinationTemperatures'
import {destinationsTemmperaturesSelector} from '../../selectors/destinationsTemperatures'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SearchIcon from '@material-ui/icons/Search'
import {isEmpty, isNil, length, toLower} from 'ramda'
import isNotEmpty from 'ramda-extension/lib/isNotEmpty'
import {months} from '../../utils/months'
import SeaImage from '../../utils/unnamed.png'
import Sun from '../../utils/sun.png'
import EditIcon from '@material-ui/icons/Edit'
import {initialize} from 'redux-form'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {fetchAllDestinationsSelector} from '../../selectors/fetches/destinationTemperatures'
import EmptyBox from '../../components/empty'
import ClearIcon from '@material-ui/icons/Clear'
import {notEqual} from 'ramda-extension'
import PageWrapper from '../../components/PageWrapper'
import {PAGES} from '../../utils/routes'
import {openReduxModal} from '../../actions/modal'
import modalNames from '../../constants/modal/modalNames'
import formNames from '../../constants/form/formNames'
import {useColors} from '../../css/useColors'
import {usePadding} from '../../css/usePadding'

const DestinationTemperatures = () => {
    const {svgGreen} = useColors()
    const dispatch = useDispatch()
    const {pl10px} = usePadding()
    const loadingDestinations = useSelector(fetchAllDestinationsSelector)
    const destinations = useSelector(destinationsTemmperaturesSelector)
    const [filter, setFilter] = useState('')

    useEffect(() => {
        dispatch(fetchAllDestintionsTemperatures())
    }, [dispatch])

    const searchInput = (
        <TextField
            fullWidth
            onChange={({target}) => setFilter(target.value)}
            id="input-with-icon-textfield"
            placeholder="Vyhledejte destinaci ..."
            variant="outlined"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    )
    return (
        <PageWrapper headingFor={PAGES.DEST_TEMPERATURES} rightBar={searchInput}>
            <DestinationTemperatureModal />
            <Container maxWidth={'sm'}>
                {!loadingDestinations && isEmpty(destinations) && <EmptyBox />}
                {isNotEmpty(destinations) &&
                    destinations
                        .filter((destination) => {
                            return destination['name'].toLowerCase().includes(toLower(filter))
                        })
                        .map((destination) => (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>{destination.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction="column">
                                        {destination.temperatures
                                            .sort(function (a, b) {
                                                return a.month - b.month
                                            })
                                            .map((temperature, index) => (
                                                <div key={index}>
                                                    <Grid container alignItems="center">
                                                        <Grid item md={3}>
                                                            <Typography variant="subtitle1" gutterBottom>
                                                                {months[temperature.month]}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            md={7}
                                                            item
                                                            container
                                                            direction="row"
                                                            justify="space-between"
                                                            alignItems="center">
                                                            <Grid
                                                                container
                                                                item
                                                                direction="row"
                                                                alignItems="center"
                                                                md={6}>
                                                                <img src={SeaImage} width={'25px'} alt="Logo" />
                                                                <Typography variant="subtitle1" className={pl10px}>
                                                                    {isNil(temperature.water) ? (
                                                                        <ClearIcon color="error" fontSize="small" />
                                                                    ) : (
                                                                        `${temperature.water} °C`
                                                                    )}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                alignItems="center"
                                                                item
                                                                md={6}>
                                                                <img src={Sun} width={'25px'} alt="Logo" />
                                                                <Typography variant="subtitle1" className={pl10px}>
                                                                    {temperature.air} °C
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid md={2} item>
                                                            <IconButton
                                                                onClick={() => {
                                                                    dispatch(
                                                                        initialize(
                                                                            formNames.DESTINATIONS_TEMPERATURE_FORM,
                                                                            {
                                                                                destinationId: destination.id,
                                                                                destinationName: destination.name,
                                                                                temperature,
                                                                                create: false,
                                                                            }
                                                                        )
                                                                    )
                                                                    dispatch(
                                                                        openReduxModal(
                                                                            modalNames.DESTINATION_TEMPERATURES_MODAL
                                                                        )
                                                                    )
                                                                }}>
                                                                <EditIcon color={'primary'} fontSize={'small'} />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider />
                                                </div>
                                            ))}
                                        {notEqual(12, length(destination.temperatures)) && (
                                            <Grid container direction="row" alignItems="center">
                                                <Grid item md={2}>
                                                    <IconButton
                                                        className={svgGreen}
                                                        onClick={() => {
                                                            dispatch(
                                                                initialize(formNames.DESTINATIONS_TEMPERATURE_FORM, {
                                                                    destinationId: destination.id,
                                                                    destinationName: destination.name,
                                                                    temperature: {},
                                                                    create: true,
                                                                })
                                                            )
                                                            dispatch(
                                                                openReduxModal(
                                                                    modalNames.DESTINATION_TEMPERATURES_MODAL
                                                                )
                                                            )
                                                        }}>
                                                        <AddCircleIcon className={svgGreen} />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item md={10}>
                                                    <Typography variant="subtitle1">Přidat měsíční teploty</Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
            </Container>
        </PageWrapper>
    )
}

export default DestinationTemperatures
