import {useDispatch, useSelector} from 'react-redux'
import React from 'react'
import {submit} from 'redux-form'
import formNames from '../../../constants/form/formNames'
import Dialog from '@material-ui/core/Dialog'
import {Button, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import {modalOpenSelector} from '../../../selectors/modals'
import StornoAdminNoteForm from './StornoAdminNoteForm'
import {closeReduxModal} from '../../../actions/modal'
import {stornoReservationWithAdminNote} from '../../../actions/orders'

const StornoAdminNoteDialog = () => {
    const dispatch = useDispatch()

    const open = useSelector(modalOpenSelector(formNames.STORNO_ADMIN_NOTE_FORM))
    const onSubmit = (data) => {
        dispatch(stornoReservationWithAdminNote(data))
    }
    const closeModal = () => {
        dispatch(closeReduxModal(formNames.STORNO_ADMIN_NOTE_FORM))
    }
    const handleSubmit = () => {
        dispatch(submit(formNames.STORNO_ADMIN_NOTE_FORM))
    }

    return (
        <Dialog open={open} fullWidth={true} onEscapeKeyDown={closeModal} maxWidth={'sm'}>
            <DialogTitle id="form-dialog-title">Přidejte poznámku</DialogTitle>
            <DialogContent>
                <StornoAdminNoteForm onSubmit={onSubmit} handleSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={closeModal}>
                    Zavřít
                </Button>
                <Button variant={'text'} color="primary" size="small" onClick={handleSubmit} startIcon={<SaveIcon />}>
                    Uložit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default StornoAdminNoteDialog
