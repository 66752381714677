import keyMirror from 'ramda-extension/lib/keyMirror'

export default keyMirror({
    INSPIRATIONS_SUCCESS: null,
    INSPIRATIONS_CREATE_SUCCESS: null,
    INSPIRATIONS_DELETE_SUCCESS: null,
    INSPIRATIONS_UPDATE_SUCCESS: null,
    INSPIRATIONS_UPDATE_NO_IMAGE_SUCCESS: null,
    OPEN_INPIRATION_MODAL: null,
    CLOSE_INPIRATION_MODAL: null,
    ON_SORT_END_INSPIRATIONS: null,
    SET_ARRAY_LOCATIONS_CHANGE_TRUE: null,
    SET_ARRAY_LOCATIONS_CHANGE_FALSE: null,
})
