import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import {openUserModal} from '../../actions/modal'
import {Button} from '@material-ui/core'
import {useDispatch} from 'react-redux'

const AddUserButton = () => {
    const dispatch = useDispatch()
    return (
        <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => dispatch(openUserModal())}>
            Přidej uživatele
        </Button>
    )
}
export default AddUserButton
