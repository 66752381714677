import {createAction} from 'redux-actions'
import userConstants from '../../constants/userConstants'
import errorModalConstants from '../../constants/errorModalConstants'
import inspirationConstants from '../../constants/inspirationsConstants'
import {CLOSE_MODAL, OPEN_MODAL} from '../../constants/modal/modalNames'

export const openUserModal = createAction(userConstants.CREATE_USER_MODAL_OPEN)
export const closeUserModal = createAction(userConstants.CREATE_USER_MODAL_CLOSE)

export const openEditUserModal = createAction(userConstants.EDIT_USER_MODAL_OPEN)
export const closeEditUserModal = createAction(userConstants.EDIT_USER_MODAL_CLOSE)

export const openErrorModal = createAction(errorModalConstants.OPEN_ERROR_MODAL)
export const closeErrorModal = createAction(errorModalConstants.CLOSE_ERROR_MODAL)

export const openInspirationModal = createAction(inspirationConstants.OPEN_INPIRATION_MODAL)
export const closeInspirationModal = createAction(inspirationConstants.CLOSE_INPIRATION_MODAL)

export const openReduxModal = createAction(OPEN_MODAL)
export const closeReduxModal = createAction(CLOSE_MODAL)
