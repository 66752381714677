import {makeStyles} from '@material-ui/core/styles'

export const useMargin = makeStyles(() => ({
    mr15: {
        marginRight: '15px',
    },
    mr10: {
        marginRight: '10px',
    },
    mt15: {
        marginTop: '15px',
    },
}))
