import React, {useEffect} from 'react'
import {Button, Grid} from '@material-ui/core'
import ListOfOffers from '../../components/ListOfOffers'
import {changeOrderInDB, fetchAllOffers} from '../../actions/offers'
import {useDispatch, useSelector} from 'react-redux'
import {openReduxModal} from '../../actions/modal'
import PageWrapper from '../../components/PageWrapper'
import AddIcon from '@material-ui/icons/Add'
import {PAGES} from '../../utils/routes'
import modalNames from '../../constants/modal/modalNames'
import {identity} from 'ramda'
import {initialize} from 'redux-form'
import formNames from '../../constants/form/formNames'
import SaveIcon from '@material-ui/icons/Save'
import {offersLocationChangedSelector} from '../../selectors/offers'

const SpecialOffer = () => {
    const dispatch = useDispatch()

    useEffect(() => dispatch(fetchAllOffers()), [dispatch])

    const locationChanges = useSelector(offersLocationChangedSelector)

    const fillToUpdate = (offer) => {
        dispatch(initialize(formNames.SPECIAL_OFFERS_FORM, offer))
        openModal()
    }
    const saveOffersNewLocations = () => dispatch(changeOrderInDB())
    const openModal = () => dispatch(openReduxModal(modalNames.SPECIAL_OFFERS_MODAL))
    return (
        <PageWrapper
            headingFor={PAGES.SPECIAL_OFFERS}
            rightBar={
                <Grid container spacing={2}>
                    <Grid item>
                        {locationChanges && (
                            <Button
                                variant="outlined"
                                color={'primary'}
                                startIcon={<SaveIcon />}
                                onClick={saveOffersNewLocations}>
                                Uložit pořadí
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color={'primary'} startIcon={<AddIcon />} onClick={openModal}>
                            Přidat nabídku
                        </Button>
                    </Grid>
                </Grid>
            }>
            <div className="container">
                <ListOfOffers fillToUpdate={fillToUpdate} openSpecialOffersModal={identity} />
            </div>
        </PageWrapper>
    )
}

export default SpecialOffer
