import {createSelector} from 'reselect'
import fetchConstants from '../../constants/fetchConstants'

export const fetchRequestsSelector = (state) => state.fetch.requests

export const getAllUsersFetchSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.USER_ALL]
)

export const loadingGetAllUsersSelector = createSelector(getAllUsersFetchSelector, (state) => state.loading)

/* ORDERS */
export const getAllOrdersFetchSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.ORDERS_ALL]
)
export const loadingGetAllOrdersSelector = createSelector(getAllOrdersFetchSelector, (state) => state.loading)

export const loadingSelector = (state) => {
    return Object.keys(fetchRequestsSelector(state))
        .map((key) => state.fetch.requests[key].loading)
        .reduce((tot, val) => tot || val)
}
