import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'

const ConfirmDialog = ({title, children, open, closeModal, onConfirm}) => {
    return (
        <Dialog open={open} onClose={closeModal} aria-labelledby="confirm-dialog">
            <DialogTitle id="confirm-dialog">Opravdu odstranit ?</DialogTitle>
            <DialogActions>
                <Grid container alignItems={'center'} justify={'center'} spacing={2} direction={'row'}>
                    <Grid item>
                        <Button variant={'outlined'} onClick={closeModal} color="secondary">
                            Ne
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={'outlined'}
                            onClick={() => {
                                onConfirm()
                                closeModal()
                            }}
                            color="primary">
                            Ano
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
export default ConfirmDialog
