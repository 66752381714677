import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core'
import React from 'react'
import DestinationTemperatureForm from './Form'
import {useDispatch, connect, useSelector} from 'react-redux'
import {destinationTemperatureModalOpen, modalOpenSelector} from '../../selectors/modals'
import {updateTemperatures} from '../../actions/destinationTemperatures'
import {formValueSelector, initialize} from 'redux-form'
import {destinationSeletor} from '../../selectors/destinationsTemperatures'
import {submit} from 'redux-form'
import {toastError} from '../Toast/ToastError'
import modalNames from '../../constants/modal/modalNames'
import {closeReduxModal} from '../../actions/modal'
import formNames from '../../constants/form/formNames'

const DestinationTemperatureModal = ({destination, submit, updateTemperatures, create}) => {
    const dispatch = useDispatch()
    const open = useSelector(modalOpenSelector(modalNames.DESTINATION_TEMPERATURES_MODAL))
    const changeMonth = (e) => {
        const temperature =
            destination.temperatures &&
            destination.temperatures.find((temperature) => temperature.month === Number(e.target.value))
        dispatch(
            initialize(formNames.DESTINATIONS_TEMPERATURE_FORM, {
                destinationId: destination.id,
                destinationName: destination.name,
                temperature,
                create,
            })
        )
    }
    const submitForm = (values) => {
        if (values.temperature.water === '') {
            values.temperature.water = null
        }
        if (values.temperature.air === '') {
            toastError('Vyplňte pole teploty vzduchu')
            return
        }
        updateTemperatures(values)
    }
    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle id="form-dialog-title">Teploty pro destinaci</DialogTitle>
            <DialogContent>
                <DestinationTemperatureForm changeMonth={changeMonth} onSubmit={submitForm} />
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => dispatch(closeReduxModal(modalNames.DESTINATION_TEMPERATURES_MODAL))}>
                    Zavřít
                </Button>
                <Button color="primary" onClick={() => submit(formNames.DESTINATIONS_TEMPERATURE_FORM)}>
                    Uložit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default connect(
    (state) => {
        const selector = formValueSelector(formNames.DESTINATIONS_TEMPERATURE_FORM)
        const destinationIdSelected = selector(state, 'destinationId')
        return {
            destinationIdSelected,
            open: destinationTemperatureModalOpen(state),
            destination: destinationSeletor(state, destinationIdSelected),
            create: selector(state, 'create'),
        }
    },
    {submit, updateTemperatures}
)(DestinationTemperatureModal)
