import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {useColors} from '../../css/useColors'

const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'ID',
        minWidth: 100,
    },
    {
        id: 'externalId',
        numeric: true,
        disablePadding: false,
        label: 'Číslo rezervace',
        minWidth: 170,
    },
    {
        id: 'createdAt',
        numeric: true,
        disablePadding: false,
        label: 'Datum',
        minWidth: 170,
    },
    {
        id: 'lastname',
        numeric: false,
        disablePadding: false,
        label: 'Zákazník',
        minWidth: 170,
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
        minWidth: 170,
    },
    {
        id: 'totalPrice',
        numeric: true,
        disablePadding: false,
        label: 'Cena Celkem',
        minWidth: 170,
    },
    {
        id: 'provider',
        numeric: true,
        disablePadding: false,
        label: 'Cestovní kancelář',
        minWidth: 170,
    },
]

const TableOrdersHead = () => {
    const {tableHeadGray} = useColors()
    return (
        <TableHead className={tableHeadGray}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.numeric ? 'left' : 'left'} width={headCell.minWidth}>
                        {headCell.label}
                    </TableCell>
                ))}
                <TableCell align="center">Detail</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default TableOrdersHead
