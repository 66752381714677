import {setNull} from '../actions/fetch'
import {toastError} from '../components/Toast/ToastError'
import {isNotNil} from 'ramda-extension'
import {logout} from '../actions/users/logout'

const fetchErrorHandler = (dispatch, e, fetch) => {
    dispatch(
        setNull({
            fetch,
        })
    )
    if (e.response && e.response.status === 401) {
        toastError('Váš přístupový token vypšel, prosímde znovu se přihlašte.')
        return dispatch(logout())
    }
    const error = e.response.data.error
    toastError(isNotNil(error) ? error : 'Něco se nezdařilo')
}

export default fetchErrorHandler
