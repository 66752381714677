import React from 'react'
import {Button} from '@material-ui/core'
import {useMargin} from '../../css/useMargin'
import {useStyles} from '../../css/useStyles'

const Buttons = ({
    children,
    onClick,
    variant,
    success,
    error,
    className,
    startIcon,
    color,
    marginRight,
    type,
    ...other
}) => {
    const {mr15} = useMargin()
    const {successButton, errorButton} = useStyles()
    const classNames = `${marginRight ? mr15 : ''} ${className} ${success ? successButton : ''} ${
        error ? errorButton : ''
    }`
    return (
        <Button
            onClick={onClick}
            variant={variant ? variant : 'outlined'}
            className={classNames}
            color={color ? color : 'primary'}
            startIcon={startIcon}
            type={type ? type : 'button'}
            {...other}>
            {children}
        </Button>
    )
}
export default Buttons
