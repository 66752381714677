import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import {useDispatch, useSelector} from 'react-redux'
import {modalOpenSelector} from '../../../selectors/modals'
import modalNames from '../../../constants/modal/modalNames'
import {closeReduxModal} from '../../../actions/modal'
import FlightCompaniesForm from './FlightCompaniesForm'
import {isNil} from 'ramda'
import {createFlightsCompanies, putFlightsCompanies} from '../../../actions/flightCompanies'
import formNames from '../../../constants/form/formNames'
import {submit} from 'redux-form'
import Buttons from '../../Buttons/Buttons'

const FlightCompaniesModal = () => {
    const dispatch = useDispatch()
    const open = useSelector(modalOpenSelector(modalNames.FLIGHT_COMPANIES_MODAL))
    const closeModal = () => dispatch(closeReduxModal(modalNames.FLIGHT_COMPANIES_MODAL))
    const submitForm = () => dispatch(submit(formNames.FLIGHT_COMPANIES_FORM))
    const onSubmit = (values) => {
        if (isNil(values.id)) {
            dispatch(createFlightsCompanies(values))
        } else {
            dispatch(putFlightsCompanies(values.id, values))
        }
    }
    return (
        <Dialog open={open} onEscapeKeyDown={closeModal} onClose={closeModal} maxWidth="sm" fullWidth>
            <DialogTitle id="form-dialog-title">Letecké společnosti</DialogTitle>
            <DialogContent>
                <FlightCompaniesForm onSubmit={onSubmit} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={closeModal}>
                    Zavřít
                </Button>
                <Buttons variant={'text'} onClick={submitForm} startIcon={<SaveIcon />}>
                    Uložit
                </Buttons>
            </DialogActions>
        </Dialog>
    )
}
export default FlightCompaniesModal
