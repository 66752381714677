export const cFieldNames = {
    code: 'code',
    name: 'name',
    url: 'url',
}

export const initialValues = {
    [cFieldNames.code]: '',
    [cFieldNames.name]: '',
    [cFieldNames.url]: '',
}

export const validate = (values) => {
    const errors = {}
    if (!values.code) {
        errors.code = 'Povinné pole'
    }
    if (values.code && values.code.length > 2) {
        errors.code = 'Maximální délka kódu je 2 znaky'
    }
    if (!values.name) {
        errors.name = 'Povinné pole'
    }
    if (!values.url) {
        errors.url = 'Povinné pole'
    }
    return errors
}
