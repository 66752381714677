import fioPaymentsConstants from '../constants/fioPaymentsConstants'
import {handleActions} from 'redux-actions'

const initialState = {
    account: {},
    paymentsWithoutOrder: {
        content: [],
    },
    paymentsWithOrder: {
        content: [],
    },
}
export default handleActions(
    {
        [fioPaymentsConstants.ACCOUNT_SUCCESS]: (state, {payload}) => ({
            ...state,
            account: payload,
        }),
        [fioPaymentsConstants.ALL_PAYMENTS_WITHOUT_ORDER]: (state, {payload}) => ({
            ...state,
            paymentsWithoutOrder: payload,
        }),
        [fioPaymentsConstants.ALL_PAYMENTS_WITH_ORDER]: (state, {payload}) => ({
            ...state,
            paymentsWithOrder: payload,
        }),
    },
    initialState
)
