import {Grid, MenuItem} from '@material-ui/core'
import React from 'react'
import {Field, Form, formValueSelector, reduxForm} from 'redux-form'
import {renderSelectField, renderTextField} from '../UserFormDialog/TextField'
import {months} from '../../utils/months'
import {validate} from './validate'
import {findIndex} from 'ramda'
import {connect} from 'react-redux'
import {destinationSeletor} from '../../selectors/destinationsTemperatures'
import formNames from '../../constants/form/formNames'
import {cFieldNames} from '../../constants/form/DestinationTemperaturesFormSchema'
import {useStyles} from '../../css/useStyles'

const DestinationTemperatureForm = ({
    changeMonth,
    handleSubmit,
    destinationInfo: {temperatures},
    create,
    initTemperatures,
}) => {
    const monthsValues = Object.keys(months)
    const {destinationInputWidth} = useStyles()
    return (
        <Form onSubmit={handleSubmit}>
            <Grid container spacing={2} direction={'column'} alignItems={'center'} justify={'center'}>
                <Grid item md={6}>
                    <Field
                        name={cFieldNames.destinationName}
                        className={destinationInputWidth}
                        component={renderTextField}
                        disabled
                        label="Destinace"
                    />
                </Grid>
                <Grid item md={6}>
                    <Field
                        name={cFieldNames.tempMonth}
                        component={renderSelectField}
                        label={'Měsíc'}
                        className={destinationInputWidth}
                        onChange={changeMonth}
                        fullWidth
                        disabled={!create}>
                        {!create ? (
                            <MenuItem value={initTemperatures.month} key={initTemperatures.month}>
                                {months[initTemperatures.month]}
                            </MenuItem>
                        ) : (
                            monthsValues
                                .filter(
                                    (month) => findIndex((v) => Number(v.month) === Number(month))(temperatures) === -1
                                )
                                .map((month) => (
                                    <MenuItem value={month} key={month}>
                                        {months[month]}
                                    </MenuItem>
                                ))
                        )}
                    </Field>
                </Grid>
                <Grid item md={6}>
                    <Field
                        name={cFieldNames.tempWater}
                        className={destinationInputWidth}
                        component={renderTextField}
                        label="Teplota vody"
                    />
                </Grid>
                <Grid item md={6}>
                    <Field
                        name={cFieldNames.tempAir}
                        className={destinationInputWidth}
                        component={renderTextField}
                        label="Teplota vzduchu"
                    />
                </Grid>
            </Grid>
        </Form>
    )
}

const selector = formValueSelector(formNames.DESTINATIONS_TEMPERATURE_FORM)

export default reduxForm({
    form: formNames.DESTINATIONS_TEMPERATURE_FORM,
    validate,
})(
    connect((state) => {
        const destinationId = selector(state, 'destinationId')
        return {
            destinationId,
            destinationInfo: destinationSeletor(state, destinationId),
            create: selector(state, 'create'),
            initTemperatures: selector(state, 'temperature'),
        }
    })(DestinationTemperatureForm)
)
