import React from 'react'
import {Typography} from '@material-ui/core'
import {useColors} from '../../css/useColors'

const TypographyTitle = ({title}) => {
    const {orderTextColor} = useColors()
    return (
        <Typography className={orderTextColor} variant="h5" gutterBottom>
            {title}
        </Typography>
    )
}
export default TypographyTitle
