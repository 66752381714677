export const getAuthHeaders = (state) => {
    //const token = userTokenSelector(state)
    const localStorageToken = localStorage.getItem('token')
    return {
        headers: {Authorization: `Bearer ${localStorageToken}`},
    }
}

export const getAuthTokenFromLocalStorage = () => ({
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
})
