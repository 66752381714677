import userConstants from '../../constants/userConstants'
import {createAction} from 'redux-actions'
import Axios from 'axios'
import {push} from 'connected-react-router'
import {routes, APIroutes} from '../../constants/routes'
import errorMessages from '../../constants/errorMessages'
import {setLoading, setError, setNull} from '../fetch'
import fetchConstants from '../../constants/fetchConstants'
import {getAuthTokenFromLocalStorage} from '../../utils/authFetchHeaders'
import {toastError} from '../../components/Toast/ToastError'

const loginSuccess = createAction(userConstants.LOGIN_SUCCESS)

export const login = (username, password) => (dispatch, getState) => {
    const {baseUrl, login} = APIroutes
    dispatch(setLoading(fetchConstants.USER_LOGIN))

    Axios.post(`${baseUrl}/v1${login}`, {username, password})
        .then(({data}) => {
            localStorage.setItem('token', data.token)
            dispatch(loginSuccess(data))
            dispatch(setNull({fetch: fetchConstants.USER_LOGIN}))
            if (window.location.pathname === '/login') {
                return dispatch(push(routes.dashboard))
            }
        })
        .catch(({response}) => {
            if (response && response.status === 401) {
                toastError(errorMessages.wrongCredentials)
                return dispatch(
                    setError({
                        fetch: fetchConstants.USER_LOGIN,
                    })
                )
            } else {
                dispatch(setNull({fetch: fetchConstants.USER_LOGIN}))
                toastError('Nepodařilo se Vás přihlásit')
            }
        })
}

export const autoLogin = () => (dispatch, getState) => {
    const {baseUrl, autoLogin} = APIroutes
    if (!localStorage.getItem('token')) return

    dispatch(setLoading(fetchConstants.USER_AUTO_LOGIN))
    Axios.post(`${baseUrl}/v1${autoLogin}`, {}, getAuthTokenFromLocalStorage())
        .then(({data}) => {
            dispatch(loginSuccess(data))
            dispatch(setNull({fetch: fetchConstants.USER_AUTO_LOGIN}))
            localStorage.setItem('token', data.token)
        })
        .catch(({response}) => {
            localStorage.removeItem('token')
            if (response && response.status === 401) {
                return dispatch(
                    setError({
                        fetch: fetchConstants.USER_AUTO_LOGIN,
                        msg: errorMessages.wrongCredentials,
                    })
                )
            }
            return dispatch(push(routes.login))
        })
}
