import React from 'react'
import {Grid, Typography} from '@material-ui/core'

const GridAccountInfoRow = ({heading, content}) => (
    <Grid container item spacing={2}>
        <Grid item xs={4}>
            <Typography component={'span'}>{heading}</Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography component={'span'} color={'primary'}>
                {content}
            </Typography>
        </Grid>
    </Grid>
)

export default GridAccountInfoRow
