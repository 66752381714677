import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import {createAction} from 'redux-actions'
import flightConstants from '../../constants/flightConstants'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import {toastSuccess} from '../../components/Toast/ToastSuccess'
import {closeReduxModal} from '../modal'
import modalNames from '../../constants/modal/modalNames'

const fetchSuccess = createAction(flightConstants.FLIGHT_COMPANIES_ALL_SUCCESS)

export const setUiAddingFlightsCompany = createAction(flightConstants.UI_ADDING_NEW_COMPANY)

export const setUiNonAddingFlightsCompany = createAction(flightConstants.UI_NON_ADDING_COMPANY)

export const fetchAllFlightsCompanies = () => (dispatch, getState) => {
    const {baseUrl, version, flightCompanies} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.FLIGHT_COMPANIES_ALL))
    Axios.get(`${baseUrl}${version}${flightCompanies}`, config)
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.FLIGHT_COMPANIES_ALL}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.FLIGHT_COMPANIES_ALL))
}

const createSuccess = createAction(flightConstants.FLIGHT_COMPANIES_CREATE_SUCCESS)

export const createFlightsCompanies = (company) => (dispatch, getState) => {
    const {baseUrl, version, flightCompanies} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.FLIGHT_COMPANIES_CREATE))
    Axios.post(`${baseUrl}${version}${flightCompanies}`, company, config)
        .then(({data}) => {
            dispatch(createSuccess(data))
            dispatch(setNull({fetch: fetchConstants.FLIGHT_COMPANIES_CREATE}))
            dispatch(setUiNonAddingFlightsCompany())
            toastSuccess('Společnost přidána')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.FLIGHT_COMPANIES_CREATE))
        .finally(() => dispatch(closeReduxModal(modalNames.FLIGHT_COMPANIES_MODAL)))
}

const deleteSuccess = createAction(flightConstants.FLIGHT_COMPANIES_DELETE_SUCCESS)

export const deleteFlightsCompanies = (id) => (dispatch, getState) => {
    const {baseUrl, version, flightCompanies} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.FLIGHT_COMPANIES_DELETE))
    Axios.delete(`${baseUrl}${version}${flightCompanies}/${id}`, config)
        .then(({data}) => {
            dispatch(deleteSuccess(data))
            dispatch(setNull({fetch: fetchConstants.FLIGHT_COMPANIES_DELETE}))
            toastSuccess('Společnost odstraněna')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.FLIGHT_COMPANIES_DELETE))
}

const updateFlightCompany = createAction(flightConstants.FLIGHT_COMPANIES_UPDATE_SUCCESS)
export const putFlightsCompanies = (id, company) => (dispatch, getState) => {
    const {baseUrl, version, flightCompanies} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.FLIGHT_COMPANIES_UPDATE))
    Axios.put(`${baseUrl}${version}${flightCompanies}/${id}`, company, config)
        .then(({data}) => {
            dispatch(updateFlightCompany(data))
            dispatch(setNull({fetch: fetchConstants.FLIGHT_COMPANIES_UPDATE}))
            dispatch(setUiNonAddingFlightsCompany())
            toastSuccess('Společnost upravena')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.FLIGHT_COMPANIES_UPDATE))
        .finally(() => dispatch(closeReduxModal(modalNames.FLIGHT_COMPANIES_MODAL)))
}
