import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import {Button, Grid, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core'
import {setPaymentToOrder} from '../../actions/fioPayments'
import OrderAutocomplete from './OrderAutoComplete'

const FioOrderSearcher = ({open, payment, close}) => {
    const [order, setOrder] = useState(null)

    const dispatch = useDispatch()

    const satPaymentToOrder = () => {
        dispatch(setPaymentToOrder(order.id, payment.id, () => close()))
    }

    const changeSelectedOrder = (order) => {
        setOrder(order)
    }

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle id="form-dialog-title">
                <Typography component={'span'} color={'primary'} variant={'h5'}>
                    Přiřadit objednávku
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container justify={'center'} alignItems={'center'}>
                    <Grid item>
                        <OrderAutocomplete changeSelectedOrder={changeSelectedOrder} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={close}>
                    Zavřít
                </Button>
                <Button color="primary" onClick={satPaymentToOrder}>
                    Uložit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default FioOrderSearcher
