import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {fetchAllInspirations} from '../../actions/inspirations'
import InspirationList from '../../components/InspirationsList'

const Inspirations = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAllInspirations())
    }, [dispatch])
    return <InspirationList />
}

export default Inspirations
