import {handleActions} from 'redux-actions'
import destinationTemperaturesConstants from '../constants/destinationTemperaturesConstants'

const initialState = []

export default handleActions(
    {
        [destinationTemperaturesConstants.TEMPERATURE_ALL_SUCCESS]: (state, {payload}) => payload,
        [destinationTemperaturesConstants.UPDATE_TEMPERATURE]: (state, {payload}) =>
            state.map((destination) => (destination.id === payload.id ? payload : destination)),
    },
    initialState
)
