import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import SearchIcon from '@material-ui/icons/Search'
import {Grid, TextField} from '@material-ui/core'

const FlightTableToolbar = ({search, setSearch}) => (
    <div>
        <Toolbar>
            <Grid container spacing={1} alignItems="flex-end" justify="flex-start">
                <Grid item>
                    <SearchIcon />
                </Grid>
                <Grid item>
                    <TextField
                        id="input-with-icon-grid"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        label="Hledej..."
                    />
                </Grid>
            </Grid>
        </Toolbar>
    </div>
)
export default FlightTableToolbar
