import React, {useEffect, useState} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {toFormatDateNoHours} from '../../utils/toFormatDate'
import PropTypes from 'prop-types'
import {codeListLoadingSelector, getFoodTypeSelector, roomDescriptionSelector} from '../../selectors/codelist'
import {connect, useDispatch} from 'react-redux'
import {Tooltip} from '@material-ui/core'
import {fetchHotelDetailInfo} from '../../actions/destinationTemperatures'
import {isNotNil} from 'ramda-extension'
import {propOr} from 'ramda'
import {useStyles} from '../../css/useStyles'

const OrderDetailInfoTable = ({
    orderDetail: {hotel, room, food, termFrom, termTo},
    foodTypeDescription,
    roomDescription,
}) => {
    const {tableHeadGray} = useStyles()
    const dispatch = useDispatch()
    const [hotelInfo, setHotelInfo] = useState(null)

    useEffect(() => {
        if (isNotNil(hotel)) {
            dispatch(fetchHotelDetailInfo(hotel, (data) => setHotelInfo(data)))
        }
    }, [dispatch, hotel])
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead className={tableHeadGray}>
                    <TableRow>
                        <TableCell>Strava</TableCell>
                        <TableCell align="left">Pokoj</TableCell>
                        <TableCell align="left">Od</TableCell>
                        <TableCell align="left">Do</TableCell>
                        <TableCell align="center">Hotel</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <Tooltip title={foodTypeDescription ? foodTypeDescription.groupName : ''}>
                                <span>
                                    {foodTypeDescription && foodTypeDescription.description}
                                    {` (${food})`}
                                </span>
                            </Tooltip>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Tooltip title={room}>
                                <span>
                                    {roomDescription && roomDescription}
                                    {` (${room})`}
                                </span>
                            </Tooltip>
                        </TableCell>
                        <TableCell align="left">{toFormatDateNoHours(termFrom)}</TableCell>
                        <TableCell align="left">{toFormatDateNoHours(termTo)}</TableCell>
                        <Tooltip
                            arrow
                            title={`${propOr('', 'name', hotelInfo)}, ${propOr('', 'countryName', hotelInfo)}`}
                            placement={'top'}>
                            <TableCell align="center">{hotel}</TableCell>
                        </Tooltip>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
OrderDetailInfoTable.propTypes = {
    orderDetail: PropTypes.object,
}

export default connect((state, props) => ({
    loadingCodeList: codeListLoadingSelector(state),
    foodTypeDescription: getFoodTypeSelector(state, props),
    roomDescription: roomDescriptionSelector(state, props),
}))(OrderDetailInfoTable)
