import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import toFormatDate from '../../utils/toFormatDate'
import {formatNumber} from '../../utils/formatNumber'
import {useStyles} from '../../css/useStyles'

const OrderPaymentsTable = ({orderPayments}) => {
    const {tableHeadGray} = useStyles()

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead className={tableHeadGray}>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Datum vytvoření</TableCell>
                        <TableCell align="left">Hodnota</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Datum zaplacení</TableCell>
                        <TableCell align="left">Zdroj</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderPayments.map(({id, externalId, dateCreated, datePaid, provider, amount, status}) => (
                        <TableRow key={id}>
                            <TableCell component="th" scope="row">
                                {externalId}
                            </TableCell>
                            <TableCell align="left">{toFormatDate(dateCreated)}</TableCell>
                            <TableCell align="left">{formatNumber(amount)}</TableCell>
                            <TableCell align="left">{status}</TableCell>
                            <TableCell align="left">{datePaid ? toFormatDate(datePaid) : 'Nezaplaceno'}</TableCell>
                            <TableCell align="left">{provider}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OrderPaymentsTable
