import React from 'react'
import {Grid, Tooltip, Typography} from '@material-ui/core'
import toFormatDate from '../../utils/toFormatDate'
import PropTypes from 'prop-types'
import {useStyles} from '../../css/useStyles'

const DateCell = ({createdAt, expireAt, emailContractSentAt, dateFirstPayment}) => {
    const {fontSize07} = useStyles()
    return (
        <Grid container direction="column">
            <Grid item>
                <Tooltip title="Datum vytvoření" aria-label="add">
                    <Typography variant="caption" className={fontSize07} display="block">{`V:  ${
                        createdAt !== null ? toFormatDate(createdAt) : ''
                    }`}</Typography>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Datum první platby zapalcené platby" aria-label="add">
                    <Typography variant="caption" className={fontSize07} display="block">{`PP:  ${
                        dateFirstPayment !== null ? toFormatDate(dateFirstPayment) : ''
                    }`}</Typography>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Expirace" aria-label="add">
                    <Typography variant="caption" className={fontSize07} display="block">{`E:  ${
                        expireAt !== null ? toFormatDate(expireAt) : ''
                    }`}</Typography>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Potvrzeni odeslani smlouvy" aria-label="add">
                    <Typography variant="caption" className={fontSize07} display="block">{`PO:  ${
                        emailContractSentAt !== null ? toFormatDate(emailContractSentAt) : ''
                    }`}</Typography>
                </Tooltip>
            </Grid>
        </Grid>
    )
}

DateCell.propTypes = {
    createdAt: PropTypes.string,
    expireAt: PropTypes.string,
    expiredAt: PropTypes.string,
    dateFirstPayment: PropTypes.string,
}

export default DateCell
