import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {useDispatch, useSelector} from 'react-redux'
import {closeReduxModal} from '../../../actions/modal'
import SaveIcon from '@material-ui/icons/Save'
import {modalOpenSelector} from '../../../selectors/modals'
import modalNames from '../../../constants/modal/modalNames'
import LaunchScreenForm from '../../../components/LaunchScreen/LaunchScreenForm'
import {useStyles} from '../styles'
import formNames from '../../../constants/form/formNames'
import {submit} from 'redux-form'
import {createScreen, updateScreen} from '../../../actions/launchScreen'

const LaunchScreenModal = () => {
    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch(closeReduxModal(modalNames.LAUNCH_SCREEN_MODAL))
    }
    const classes = useStyles()
    const open = useSelector(modalOpenSelector(modalNames.LAUNCH_SCREEN_MODAL))

    const onSubmit = (values) => {
        if (values.id) {
            dispatch(updateScreen(values))
        } else {
            dispatch(createScreen(values))
        }
    }
    const submitForm = () => {
        dispatch(submit(formNames.LAUNCH_SCREEN_FORM))
    }
    return (
        <Dialog open={open} onEscapeKeyDown={closeModal} maxWidth="sm" fullWidth>
            <DialogTitle id="form-dialog-title">Propagační nabídky</DialogTitle>
            <DialogContent>
                <LaunchScreenForm classes={classes} onSubmit={onSubmit} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={closeModal}>
                    Zavřít
                </Button>
                <Button variant={'outlined'} color="primary" size="small" onClick={submitForm} startIcon={<SaveIcon />}>
                    Uložit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default LaunchScreenModal
