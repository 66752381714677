import React from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import toFormatDate from '../../utils/toFormatDate'
import {isNotEmpty} from 'ramda-extension'
import {useStyles} from '../../css/useStyles'

const OrderEmails = ({order: {emails}}) => {
    const {tableHeadGray} = useStyles()
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead className={tableHeadGray}>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Předmět</TableCell>
                        <TableCell align="left">Typ</TableCell>
                        <TableCell align="left">Obsah</TableCell>
                        <TableCell align="left">Čas</TableCell>
                        <TableCell align="left">E-mail příjemce</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isNotEmpty(emails) &&
                        emails.map(({id, sentAt, content, type, subject, receiveEmail}) => (
                            <TableRow>
                                <TableCell>{id}</TableCell>
                                <TableCell align="left">{subject}</TableCell>
                                <TableCell align="left">{type}</TableCell>
                                <TableCell align="left">{content}</TableCell>
                                <TableCell align="left">{toFormatDate(sentAt)}</TableCell>
                                <TableCell align="left">{receiveEmail}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export default OrderEmails
