import React from 'react'
import {Grid, Typography} from '@material-ui/core'
import ErrorBoundary from '../../utils/ErrorBoundary'
import {PAGE_TITLES} from '../../utils/routes'
import {usePadding} from '../../css/usePadding'
import {useStyles} from '../../css/useStyles'

const PageWrapper = ({children, headingFor, heading, rightBar, headingBar}) => {
    const {pb30px} = usePadding()
    const {wrapperHeading} = useStyles()
    return (
        <ErrorBoundary>
            <Grid container justify="space-between" alignItems="center" className={pb30px}>
                <Grid item>
                    {headingFor ? (
                        <Typography component={'span'} className={wrapperHeading}>
                            {PAGE_TITLES[headingFor]}
                        </Typography>
                    ) : (
                        heading && (
                            <Typography component={'span'} className={wrapperHeading}>
                                {heading}
                            </Typography>
                        )
                    )}
                    {headingBar && headingBar}
                </Grid>
                <Grid item>{rightBar}</Grid>
            </Grid>
            <div>{children}</div>
        </ErrorBoundary>
    )
}

export default PageWrapper
