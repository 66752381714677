export const cFieldNames = {
    headingTitle: 'headingTitle',
    active: 'active',
    sections: 'sections',
}
export const sectionFieldNames = {
    title: 'title',
    text: 'text',
    active: 'active',
    location: 'location',
}
export const initialValues = {
    [cFieldNames.headingTitle]: '',
    [cFieldNames.active]: false,
    [cFieldNames.sections]: [],
}

export const validate = (values) => {
    const errors = {}

    const keys = ['headingTitle']
    keys.filter((key) => !values[key]).forEach((key) => (errors[key] = 'Povinne'))

    const sectionErrorsArray = []
    if (values.sections) {
        values.sections.forEach((section, index) => {
            const sectionErrors = {}
            if (!section || !section[sectionFieldNames.title]) {
                sectionErrors.title = 'Povinne'
                sectionErrorsArray[index] = sectionErrors
            }
            if (!section || !section[sectionFieldNames.text]) {
                sectionErrors[sectionFieldNames.text] = 'Povinne'
                sectionErrorsArray[index] = sectionErrors
            }
            if (!section || !section[sectionFieldNames.location]) {
                sectionErrors[sectionFieldNames.location] = 'Povinne'
                sectionErrorsArray[index] = sectionErrors
            }
        })
    }
    if (sectionErrorsArray.length) {
        errors.sections = sectionErrorsArray
    }
    return errors
}
