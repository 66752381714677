import React from 'react'
import PageWrapper from '../PageWrapper'
import {Paper, Grid, Container, FormControl, Typography, RadioGroup, FormControlLabel, Radio} from '@material-ui/core'
import {Field, reduxForm} from 'redux-form'
import {renderTextField} from '../UserFormDialog/TextField'
import {cFieldNames, validateFioPayment} from './FioPaymentCreate/schema'
import {connect} from 'react-redux'
import {fioAccountInfoSelector} from '../../selectors/fioPayments/fioPaymentsSelector'
import {propOr} from 'ramda'
import {usePadding} from '../../css/usePadding'

const FioCreatePayment = () => {
    const {p16px} = usePadding()
    const typePaymentsRadioGroup = ({input, ...rest}) => (
        <FormControl>
            <RadioGroup {...input} {...rest} row>
                <FormControlLabel
                    value="local"
                    color={'primary'}
                    control={<Radio color={'primary'} />}
                    label="Standartní"
                />
                <FormControlLabel
                    value="world"
                    color={'primary'}
                    control={<Radio color={'primary'} />}
                    label="Prioritní"
                />
                <FormControlLabel
                    value="europe"
                    color={'primary'}
                    control={<Radio color={'primary'} />}
                    label="Příkaz k inkasu"
                />
            </RadioGroup>
        </FormControl>
    )

    return (
        <PageWrapper heading={'Založení platby pro FIO'}>
            <Container maxWidth={'sm'}>
                <Paper className={p16px}>
                    <form>
                        <Grid container spacing={2} alignItems={'center'} direction={'column'}>
                            <Grid container item>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="type" row name="type" value={'local'} onChange={() => {}}>
                                        <FormControlLabel
                                            value="local"
                                            color={'primary'}
                                            control={<Radio color={'primary'} />}
                                            label="Tuzemská platba"
                                        />
                                        <FormControlLabel
                                            disabled
                                            value="world"
                                            color={'primary'}
                                            control={<Radio color={'primary'} />}
                                            label="Zahraniční platba"
                                        />
                                        <FormControlLabel
                                            disabled
                                            value="europe"
                                            color={'primary'}
                                            control={<Radio color={'primary'} />}
                                            label="Evropská platba"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid container item spacing={1} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'} component={'span'}>
                                        Z účtu
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        label={'Číslo účtu'}
                                        disabled
                                        disableErrorCode
                                        name={cFieldNames.accountFrom}
                                        component={renderTextField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        label={'Banka'}
                                        disableErrorCode
                                        name={cFieldNames.bankCodeFrom}
                                        disabled
                                        defaultValue={'2010'}
                                        component={renderTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'} component={'span'}>
                                        Částka
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        label={'Částka'}
                                        name={cFieldNames.amount}
                                        disableErrorCode
                                        component={renderTextField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field label={'Měna'} name={cFieldNames.currency} component={renderTextField} />
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Field component={typePaymentsRadioGroup} name={cFieldNames.paymentType} />
                            </Grid>
                            <Grid container item spacing={1} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'} component={'span'}>
                                        Na účet
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        label={'Číslo účtu'}
                                        disableErrorCode
                                        name={cFieldNames.accountTo}
                                        component={renderTextField}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        label={'Banka'}
                                        disableErrorCode
                                        name={cFieldNames.bankCode}
                                        component={renderTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'} component={'span'}>
                                        Konstantní symbol
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Field
                                        label={'Konstantní symbol'}
                                        name={cFieldNames.ks}
                                        component={renderTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'} component={'span'}>
                                        Variabilní symbol
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Field
                                        label={'Variabilní symbol'}
                                        name={cFieldNames.vs}
                                        component={renderTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'} component={'span'}>
                                        Specifický symbol
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Field
                                        label={'Specifický symbol'}
                                        name={cFieldNames.ss}
                                        component={renderTextField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'} component={'span'}>
                                        Komentář
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Field
                                        component={renderTextField}
                                        multiline
                                        name={cFieldNames.comment}
                                        rows={2}
                                        rowsMax={4}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1} alignItems={'center'}>
                                <Grid item xs={3}>
                                    <Typography variant={'body1'} component={'span'}>
                                        Zpráva pro příjemce
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Field
                                        component={renderTextField}
                                        multiline
                                        rows={2}
                                        name={cFieldNames.messageForRecipient}
                                        rowsMax={4}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </PageWrapper>
    )
}
export default connect((state) => ({
    initialValues: {
        [cFieldNames.accountFrom]: propOr('', 'accountId', fioAccountInfoSelector(state)),
        [cFieldNames.bankCodeFrom]: propOr('', 'bankId', fioAccountInfoSelector(state)),
    },
}))(
    reduxForm({
        enableReinitialize: true,
        validate: validateFioPayment,
        form: 'paymentCreate',
    })(FioCreatePayment)
)
