import {createSelector} from 'reselect'

export const userSelector = (state) => state.user

export const userAuthorizedSelector = createSelector(userSelector, (user) => user.isAuthorized)

export const userRoleSelector = createSelector(userSelector, (user) => user.role)

export const userTokenSelector = createSelector(userSelector, (user) => user.token)

export const usersSelector = (state) => state.users
