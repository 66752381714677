export default JSON.parse(`[
        {
            "Entity / Location": "AFGHANISTAN",
            "Currency": "Afghani",
            "Alphabetic Code": "AFN",
            "Numeric Code": 971,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ÅLAND ISLANDS",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ALBANIA",
            "Currency": "Lek",
            "Alphabetic Code": "ALL",
            "Numeric Code": 8,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ALGERIA",
            "Currency": "Algerian Dinar",
            "Alphabetic Code": "DZD",
            "Numeric Code": 12,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "AMERICAN SAMOA",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ANDORRA",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ANGOLA",
            "Currency": "Kwanza",
            "Alphabetic Code": "AOA",
            "Numeric Code": 973,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ANGUILLA",
            "Currency": "East Caribbean Dollar",
            "Alphabetic Code": "XCD",
            "Numeric Code": 951,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ANTARCTICA",
            "Currency": "No universal currency",
            "Alphabetic Code": "",
            "Numeric Code": null,
            "Minor Unit": ""
        },
        {
            "Entity / Location": "ANTIGUA AND BARBUDA",
            "Currency": "East Caribbean Dollar",
            "Alphabetic Code": "XCD",
            "Numeric Code": 951,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ARGENTINA",
            "Currency": "Argentine Peso",
            "Alphabetic Code": "ARS",
            "Numeric Code": 32,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ARMENIA",
            "Currency": "Armenian Dram",
            "Alphabetic Code": "AMD",
            "Numeric Code": 51,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ARUBA",
            "Currency": "Aruban Florin",
            "Alphabetic Code": "AWG",
            "Numeric Code": 533,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "AUSTRALIA",
            "Currency": "Australian Dollar",
            "Alphabetic Code": "AUD",
            "Numeric Code": 36,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "AUSTRIA",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "AZERBAIJAN",
            "Currency": "Azerbaijan Manat",
            "Alphabetic Code": "AZN",
            "Numeric Code": 944,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BAHAMAS (THE)",
            "Currency": "Bahamian Dollar",
            "Alphabetic Code": "BSD",
            "Numeric Code": 44,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BAHRAIN",
            "Currency": "Bahraini Dinar",
            "Alphabetic Code": "BHD",
            "Numeric Code": 48,
            "Minor Unit": "3"
        },
        {
            "Entity / Location": "BANGLADESH",
            "Currency": "Taka",
            "Alphabetic Code": "BDT",
            "Numeric Code": 50,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BARBADOS",
            "Currency": "Barbados Dollar",
            "Alphabetic Code": "BBD",
            "Numeric Code": 52,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BELARUS",
            "Currency": "Belarusian Ruble",
            "Alphabetic Code": "BYN",
            "Numeric Code": 933,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BELGIUM",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BELIZE",
            "Currency": "Belize Dollar",
            "Alphabetic Code": "BZD",
            "Numeric Code": 84,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BENIN",
            "Currency": "CFA Franc BCEAO",
            "Alphabetic Code": "XOF",
            "Numeric Code": 952,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "BERMUDA",
            "Currency": "Bermudian Dollar",
            "Alphabetic Code": "BMD",
            "Numeric Code": 60,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BHUTAN",
            "Currency": "Indian Rupee",
            "Alphabetic Code": "INR",
            "Numeric Code": 356,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BHUTAN",
            "Currency": "Ngultrum",
            "Alphabetic Code": "BTN",
            "Numeric Code": 64,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BOLIVIA (PLURINATIONAL STATE OF)",
            "Currency": "Boliviano",
            "Alphabetic Code": "BOB",
            "Numeric Code": 68,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BOLIVIA (PLURINATIONAL STATE OF)",
            "Currency": "Mvdol",
            "Alphabetic Code": "BOV",
            "Numeric Code": 984,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BONAIRE, SINT EUSTATIUS AND SABA",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BOSNIA AND HERZEGOVINA",
            "Currency": "Convertible Mark",
            "Alphabetic Code": "BAM",
            "Numeric Code": 977,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BOTSWANA",
            "Currency": "Pula",
            "Alphabetic Code": "BWP",
            "Numeric Code": 72,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BOUVET ISLAND",
            "Currency": "Norwegian Krone",
            "Alphabetic Code": "NOK",
            "Numeric Code": 578,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BRAZIL",
            "Currency": "Brazilian Real",
            "Alphabetic Code": "BRL",
            "Numeric Code": 986,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BRITISH INDIAN OCEAN TERRITORY (THE)",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BRUNEI DARUSSALAM",
            "Currency": "Brunei Dollar",
            "Alphabetic Code": "BND",
            "Numeric Code": 96,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BULGARIA",
            "Currency": "Bulgarian Lev",
            "Alphabetic Code": "BGN",
            "Numeric Code": 975,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "BURKINA FASO",
            "Currency": "CFA Franc BCEAO",
            "Alphabetic Code": "XOF",
            "Numeric Code": 952,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "BURUNDI",
            "Currency": "Burundi Franc",
            "Alphabetic Code": "BIF",
            "Numeric Code": 108,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "CABO VERDE",
            "Currency": "Cabo Verde Escudo",
            "Alphabetic Code": "CVE",
            "Numeric Code": 132,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CAMBODIA",
            "Currency": "Riel",
            "Alphabetic Code": "KHR",
            "Numeric Code": 116,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CAMEROON",
            "Currency": "CFA Franc BEAC",
            "Alphabetic Code": "XAF",
            "Numeric Code": 950,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "CANADA",
            "Currency": "Canadian Dollar",
            "Alphabetic Code": "CAD",
            "Numeric Code": 124,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CAYMAN ISLANDS (THE)",
            "Currency": "Cayman Islands Dollar",
            "Alphabetic Code": "KYD",
            "Numeric Code": 136,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CENTRAL AFRICAN REPUBLIC (THE)",
            "Currency": "CFA Franc BEAC",
            "Alphabetic Code": "XAF",
            "Numeric Code": 950,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "CHAD",
            "Currency": "CFA Franc BEAC",
            "Alphabetic Code": "XAF",
            "Numeric Code": 950,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "CHILE",
            "Currency": "Chilean Peso",
            "Alphabetic Code": "CLP",
            "Numeric Code": 152,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "CHILE",
            "Currency": "Unidad de Fomento",
            "Alphabetic Code": "CLF",
            "Numeric Code": 990,
            "Minor Unit": "4"
        },
        {
            "Entity / Location": "CHINA",
            "Currency": "Yuan Renminbi",
            "Alphabetic Code": "CNY",
            "Numeric Code": 156,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CHRISTMAS ISLAND",
            "Currency": "Australian Dollar",
            "Alphabetic Code": "AUD",
            "Numeric Code": 36,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "COCOS (KEELING) ISLANDS (THE)",
            "Currency": "Australian Dollar",
            "Alphabetic Code": "AUD",
            "Numeric Code": 36,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "COLOMBIA",
            "Currency": "Colombian Peso",
            "Alphabetic Code": "COP",
            "Numeric Code": 170,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "COLOMBIA",
            "Currency": "Unidad de Valor Real",
            "Alphabetic Code": "COU",
            "Numeric Code": 970,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "COMOROS (THE)",
            "Currency": "Comorian Franc",
            "Alphabetic Code": "KMF",
            "Numeric Code": 174,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "CONGO (THE DEMOCRATIC REPUBLIC OF THE)",
            "Currency": "Congolese Franc",
            "Alphabetic Code": "CDF",
            "Numeric Code": 976,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CONGO (THE)",
            "Currency": "CFA Franc BEAC",
            "Alphabetic Code": "XAF",
            "Numeric Code": 950,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "COOK ISLANDS (THE)",
            "Currency": "New Zealand Dollar",
            "Alphabetic Code": "NZD",
            "Numeric Code": 554,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "COSTA RICA",
            "Currency": "Costa Rican Colon",
            "Alphabetic Code": "CRC",
            "Numeric Code": 188,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CÔTE D'IVOIRE",
            "Currency": "CFA Franc BCEAO",
            "Alphabetic Code": "XOF",
            "Numeric Code": 952,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "CROATIA",
            "Currency": "Kuna",
            "Alphabetic Code": "HRK",
            "Numeric Code": 191,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CUBA",
            "Currency": "Cuban Peso",
            "Alphabetic Code": "CUP",
            "Numeric Code": 192,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CUBA",
            "Currency": "Peso Convertible",
            "Alphabetic Code": "CUC",
            "Numeric Code": 931,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CURAÇAO",
            "Currency": "Netherlands Antillean Guilder",
            "Alphabetic Code": "ANG",
            "Numeric Code": 532,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CYPRUS",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "CZECHIA",
            "Currency": "Czech Koruna",
            "Alphabetic Code": "CZK",
            "Numeric Code": 203,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "DENMARK",
            "Currency": "Danish Krone",
            "Alphabetic Code": "DKK",
            "Numeric Code": 208,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "DJIBOUTI",
            "Currency": "Djibouti Franc",
            "Alphabetic Code": "DJF",
            "Numeric Code": 262,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "DOMINICA",
            "Currency": "East Caribbean Dollar",
            "Alphabetic Code": "XCD",
            "Numeric Code": 951,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "DOMINICAN REPUBLIC (THE)",
            "Currency": "Dominican Peso",
            "Alphabetic Code": "DOP",
            "Numeric Code": 214,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ECUADOR",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "EGYPT",
            "Currency": "Egyptian Pound",
            "Alphabetic Code": "EGP",
            "Numeric Code": 818,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "EL SALVADOR",
            "Currency": "El Salvador Colon",
            "Alphabetic Code": "SVC",
            "Numeric Code": 222,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "EL SALVADOR",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "EQUATORIAL GUINEA",
            "Currency": "CFA Franc BEAC",
            "Alphabetic Code": "XAF",
            "Numeric Code": 950,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "ERITREA",
            "Currency": "Nakfa",
            "Alphabetic Code": "ERN",
            "Numeric Code": 232,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ESTONIA",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ETHIOPIA",
            "Currency": "Ethiopian Birr",
            "Alphabetic Code": "ETB",
            "Numeric Code": 230,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "EUROPEAN UNION",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "FALKLAND ISLANDS (THE) [MALVINAS]",
            "Currency": "Falkland Islands Pound",
            "Alphabetic Code": "FKP",
            "Numeric Code": 238,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "FAROE ISLANDS (THE)",
            "Currency": "Danish Krone",
            "Alphabetic Code": "DKK",
            "Numeric Code": 208,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "FIJI",
            "Currency": "Fiji Dollar",
            "Alphabetic Code": "FJD",
            "Numeric Code": 242,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "FINLAND",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "FRANCE",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "FRENCH GUIANA",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "FRENCH POLYNESIA",
            "Currency": "CFP Franc",
            "Alphabetic Code": "XPF",
            "Numeric Code": 953,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "FRENCH SOUTHERN TERRITORIES (THE)",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GABON",
            "Currency": "CFA Franc BEAC",
            "Alphabetic Code": "XAF",
            "Numeric Code": 950,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "GAMBIA (THE)",
            "Currency": "Dalasi",
            "Alphabetic Code": "GMD",
            "Numeric Code": 270,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GEORGIA",
            "Currency": "Lari",
            "Alphabetic Code": "GEL",
            "Numeric Code": 981,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GERMANY",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GHANA",
            "Currency": "Ghana Cedi",
            "Alphabetic Code": "GHS",
            "Numeric Code": 936,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GIBRALTAR",
            "Currency": "Gibraltar Pound",
            "Alphabetic Code": "GIP",
            "Numeric Code": 292,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GREECE",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GREENLAND",
            "Currency": "Danish Krone",
            "Alphabetic Code": "DKK",
            "Numeric Code": 208,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GRENADA",
            "Currency": "East Caribbean Dollar",
            "Alphabetic Code": "XCD",
            "Numeric Code": 951,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GUADELOUPE",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GUAM",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GUATEMALA",
            "Currency": "Quetzal",
            "Alphabetic Code": "GTQ",
            "Numeric Code": 320,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GUERNSEY",
            "Currency": "Pound Sterling",
            "Alphabetic Code": "GBP",
            "Numeric Code": 826,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "GUINEA",
            "Currency": "Guinean Franc",
            "Alphabetic Code": "GNF",
            "Numeric Code": 324,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "GUINEA-BISSAU",
            "Currency": "CFA Franc BCEAO",
            "Alphabetic Code": "XOF",
            "Numeric Code": 952,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "GUYANA",
            "Currency": "Guyana Dollar",
            "Alphabetic Code": "GYD",
            "Numeric Code": 328,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "HAITI",
            "Currency": "Gourde",
            "Alphabetic Code": "HTG",
            "Numeric Code": 332,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "HAITI",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "HEARD ISLAND AND McDONALD ISLANDS",
            "Currency": "Australian Dollar",
            "Alphabetic Code": "AUD",
            "Numeric Code": 36,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "HOLY SEE (THE)",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "HONDURAS",
            "Currency": "Lempira",
            "Alphabetic Code": "HNL",
            "Numeric Code": 340,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "HONG KONG",
            "Currency": "Hong Kong Dollar",
            "Alphabetic Code": "HKD",
            "Numeric Code": 344,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "HUNGARY",
            "Currency": "Forint",
            "Alphabetic Code": "HUF",
            "Numeric Code": 348,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ICELAND",
            "Currency": "Iceland Krona",
            "Alphabetic Code": "ISK",
            "Numeric Code": 352,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "INDIA",
            "Currency": "Indian Rupee",
            "Alphabetic Code": "INR",
            "Numeric Code": 356,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "INDONESIA",
            "Currency": "Rupiah",
            "Alphabetic Code": "IDR",
            "Numeric Code": 360,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "INTERNATIONAL MONETARY FUND (IMF)",
            "Currency": "SDR (Special Drawing Right)",
            "Alphabetic Code": "XDR",
            "Numeric Code": 960,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "IRAN (ISLAMIC REPUBLIC OF)",
            "Currency": "Iranian Rial",
            "Alphabetic Code": "IRR",
            "Numeric Code": 364,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "IRAQ",
            "Currency": "Iraqi Dinar",
            "Alphabetic Code": "IQD",
            "Numeric Code": 368,
            "Minor Unit": "3"
        },
        {
            "Entity / Location": "IRELAND",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ISLE OF MAN",
            "Currency": "Pound Sterling",
            "Alphabetic Code": "GBP",
            "Numeric Code": 826,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ISRAEL",
            "Currency": "New Israeli Sheqel",
            "Alphabetic Code": "ILS",
            "Numeric Code": 376,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ITALY",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "JAMAICA",
            "Currency": "Jamaican Dollar",
            "Alphabetic Code": "JMD",
            "Numeric Code": 388,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "JAPAN",
            "Currency": "Yen",
            "Alphabetic Code": "JPY",
            "Numeric Code": 392,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "JERSEY",
            "Currency": "Pound Sterling",
            "Alphabetic Code": "GBP",
            "Numeric Code": 826,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "JORDAN",
            "Currency": "Jordanian Dinar",
            "Alphabetic Code": "JOD",
            "Numeric Code": 400,
            "Minor Unit": "3"
        },
        {
            "Entity / Location": "KAZAKHSTAN",
            "Currency": "Tenge",
            "Alphabetic Code": "KZT",
            "Numeric Code": 398,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "KENYA",
            "Currency": "Kenyan Shilling",
            "Alphabetic Code": "KES",
            "Numeric Code": 404,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "KIRIBATI",
            "Currency": "Australian Dollar",
            "Alphabetic Code": "AUD",
            "Numeric Code": 36,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "KOREA (THE DEMOCRATIC PEOPLE'S REPUBLIC OF)",
            "Currency": "North Korean Won",
            "Alphabetic Code": "KPW",
            "Numeric Code": 408,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "KOREA (THE REPUBLIC OF)",
            "Currency": "Won",
            "Alphabetic Code": "KRW",
            "Numeric Code": 410,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "KUWAIT",
            "Currency": "Kuwaiti Dinar",
            "Alphabetic Code": "KWD",
            "Numeric Code": 414,
            "Minor Unit": "3"
        },
        {
            "Entity / Location": "KYRGYZSTAN",
            "Currency": "Som",
            "Alphabetic Code": "KGS",
            "Numeric Code": 417,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "LAO PEOPLE'S DEMOCRATIC REPUBLIC (THE)",
            "Currency": "Lao Kip",
            "Alphabetic Code": "LAK",
            "Numeric Code": 418,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "LATVIA",
            "Currency": "UR",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "LEBANON",
            "Currency": "Lebanese Pound",
            "Alphabetic Code": "LBP",
            "Numeric Code": 422,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "LESOTHO",
            "Currency": "Loti",
            "Alphabetic Code": "LSL",
            "Numeric Code": 426,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "LESOTHO",
            "Currency": "Rand",
            "Alphabetic Code": "ZAR",
            "Numeric Code": 710,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "LIBERIA",
            "Currency": "Liberian Dollar",
            "Alphabetic Code": "LRD",
            "Numeric Code": 430,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "LIBYA",
            "Currency": "Libyan Dinar",
            "Alphabetic Code": "LYD",
            "Numeric Code": 434,
            "Minor Unit": "3"
        },
        {
            "Entity / Location": "LIECHTENSTEIN",
            "Currency": "Swiss Franc",
            "Alphabetic Code": "CHF",
            "Numeric Code": 756,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "LITHUANIA",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "LUXEMBOURG",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MACAO",
            "Currency": "Pataca",
            "Alphabetic Code": "MOP",
            "Numeric Code": 446,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MACEDONIA (THE FORMER YUGOSLAV REPUBLIC OF)",
            "Currency": "Denar",
            "Alphabetic Code": "MKD",
            "Numeric Code": 807,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MADAGASCAR",
            "Currency": "Malagasy Ariary",
            "Alphabetic Code": "MGA",
            "Numeric Code": 969,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MALAWI",
            "Currency": "Malawi Kwacha",
            "Alphabetic Code": "MWK",
            "Numeric Code": 454,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MALAYSIA",
            "Currency": "Malaysian Ringgit",
            "Alphabetic Code": "MYR",
            "Numeric Code": 458,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MALDIVES",
            "Currency": "Rufiyaa",
            "Alphabetic Code": "MVR",
            "Numeric Code": 462,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MALI",
            "Currency": "CFA Franc BCEAO",
            "Alphabetic Code": "XOF",
            "Numeric Code": 952,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "MALTA",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MARSHALL ISLANDS (THE)",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MARTINIQUE",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MAURITANIA",
            "Currency": "Ouguiya",
            "Alphabetic Code": "MRU",
            "Numeric Code": 929,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MAURITIUS",
            "Currency": "Mauritius Rupee",
            "Alphabetic Code": "MUR",
            "Numeric Code": 480,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MAYOTTE",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP",
            "Currency": "ADB Unit of Account",
            "Alphabetic Code": "XUA",
            "Numeric Code": 965,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "MEXICO",
            "Currency": "Mexican Peso",
            "Alphabetic Code": "MXN",
            "Numeric Code": 484,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MEXICO",
            "Currency": "Mexican Unidad de Inversion (UDI)",
            "Alphabetic Code": "MXV",
            "Numeric Code": 979,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MICRONESIA (FEDERATED STATES OF)",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MOLDOVA (THE REPUBLIC OF)",
            "Currency": "Moldovan Leu",
            "Alphabetic Code": "MDL",
            "Numeric Code": 498,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MONACO",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MONGOLIA",
            "Currency": "Tugrik",
            "Alphabetic Code": "MNT",
            "Numeric Code": 496,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MONTENEGRO",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MONTSERRAT",
            "Currency": "East Caribbean Dollar",
            "Alphabetic Code": "XCD",
            "Numeric Code": 951,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MOROCCO",
            "Currency": "Moroccan Dirham",
            "Alphabetic Code": "MAD",
            "Numeric Code": 504,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MOZAMBIQUE",
            "Currency": "Mozambique Metical",
            "Alphabetic Code": "MZN",
            "Numeric Code": 943,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "MYANMAR",
            "Currency": "Kyat",
            "Alphabetic Code": "MMK",
            "Numeric Code": 104,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NAMIBIA",
            "Currency": "Namibia Dollar",
            "Alphabetic Code": "NAD",
            "Numeric Code": 516,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NAMIBIA",
            "Currency": "Rand",
            "Alphabetic Code": "ZAR",
            "Numeric Code": 710,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NAURU",
            "Currency": "Australian Dollar",
            "Alphabetic Code": "AUD",
            "Numeric Code": 36,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NEPAL",
            "Currency": "Nepalese Rupee",
            "Alphabetic Code": "NPR",
            "Numeric Code": 524,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NETHERLANDS (THE)",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NEW CALEDONIA",
            "Currency": "CFP Franc",
            "Alphabetic Code": "XPF",
            "Numeric Code": 953,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "NEW ZEALAND",
            "Currency": "New Zealand Dollar",
            "Alphabetic Code": "NZD",
            "Numeric Code": 554,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NICARAGUA",
            "Currency": "Cordoba Oro",
            "Alphabetic Code": "NIO",
            "Numeric Code": 558,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NIGER (THE)",
            "Currency": "CFA Franc BCEAO",
            "Alphabetic Code": "XOF",
            "Numeric Code": 952,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "NIGERIA",
            "Currency": "Naira",
            "Alphabetic Code": "NGN",
            "Numeric Code": 566,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NIUE",
            "Currency": "New Zealand Dollar",
            "Alphabetic Code": "NZD",
            "Numeric Code": 554,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NORFOLK ISLAND",
            "Currency": "Australian Dollar",
            "Alphabetic Code": "AUD",
            "Numeric Code": 36,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NORTHERN MARIANA ISLANDS (THE)",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "NORWAY",
            "Currency": "Norwegian Krone",
            "Alphabetic Code": "NOK",
            "Numeric Code": 578,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "OMAN",
            "Currency": "Rial Omani",
            "Alphabetic Code": "OMR",
            "Numeric Code": 512,
            "Minor Unit": "3"
        },
        {
            "Entity / Location": "PAKISTAN",
            "Currency": "Pakistan Rupee",
            "Alphabetic Code": "PKR",
            "Numeric Code": 586,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "PALAU",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "PALESTINE, STATE OF",
            "Currency": "No universal currency",
            "Alphabetic Code": "",
            "Numeric Code": null,
            "Minor Unit": ""
        },
        {
            "Entity / Location": "PANAMA",
            "Currency": "Balboa",
            "Alphabetic Code": "PAB",
            "Numeric Code": 590,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "PANAMA",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "PAPUA NEW GUINEA",
            "Currency": "Kina",
            "Alphabetic Code": "PGK",
            "Numeric Code": 598,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "PARAGUAY",
            "Currency": "Guarani",
            "Alphabetic Code": "PYG",
            "Numeric Code": 600,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "PERU",
            "Currency": "Sol",
            "Alphabetic Code": "PEN",
            "Numeric Code": 604,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "PHILIPPINES (THE)",
            "Currency": "Philippine Peso",
            "Alphabetic Code": "PHP",
            "Numeric Code": 608,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "PITCAIRN",
            "Currency": "New Zealand Dollar",
            "Alphabetic Code": "NZD",
            "Numeric Code": 554,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "POLAND",
            "Currency": "Zloty",
            "Alphabetic Code": "PLN",
            "Numeric Code": 985,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "PORTUGAL",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "PUERTO RICO",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "QATAR",
            "Currency": "Qatari Rial",
            "Alphabetic Code": "QAR",
            "Numeric Code": 634,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "RÉUNION",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ROMANIA",
            "Currency": "Romanian Leu",
            "Alphabetic Code": "RON",
            "Numeric Code": 946,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "RUSSIAN FEDERATION (THE)",
            "Currency": "Russian Ruble",
            "Alphabetic Code": "RUB",
            "Numeric Code": 643,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "RWANDA",
            "Currency": "Rwanda Franc",
            "Alphabetic Code": "RWF",
            "Numeric Code": 646,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "SAINT BARTHÉLEMY",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
            "Currency": "Saint Helena Pound",
            "Alphabetic Code": "SHP",
            "Numeric Code": 654,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SAINT KITTS AND NEVIS",
            "Currency": "East Caribbean Dollar",
            "Alphabetic Code": "XCD",
            "Numeric Code": 951,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SAINT LUCIA",
            "Currency": "East Caribbean Dollar",
            "Alphabetic Code": "XCD",
            "Numeric Code": 951,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SAINT MARTIN (FRENCH PART)",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SAINT PIERRE AND MIQUELON",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SAINT VINCENT AND THE GRENADINES",
            "Currency": "East Caribbean Dollar",
            "Alphabetic Code": "XCD",
            "Numeric Code": 951,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SAMOA",
            "Currency": "Tala",
            "Alphabetic Code": "WST",
            "Numeric Code": 882,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SAN MARINO",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SAO TOME AND PRINCIPE",
            "Currency": "Dobra",
            "Alphabetic Code": "STN",
            "Numeric Code": 930,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SAUDI ARABIA",
            "Currency": "Saudi Riyal",
            "Alphabetic Code": "SAR",
            "Numeric Code": 682,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SENEGAL",
            "Currency": "CFA Franc BCEAO",
            "Alphabetic Code": "XOF",
            "Numeric Code": 952,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "SERBIA",
            "Currency": "Serbian Dinar",
            "Alphabetic Code": "RSD",
            "Numeric Code": 941,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SEYCHELLES",
            "Currency": "Seychelles Rupee",
            "Alphabetic Code": "SCR",
            "Numeric Code": 690,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SIERRA LEONE",
            "Currency": "Leone",
            "Alphabetic Code": "SLL",
            "Numeric Code": 694,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SINGAPORE",
            "Currency": "Singapore Dollar",
            "Alphabetic Code": "SGD",
            "Numeric Code": 702,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SINT MAARTEN (DUTCH PART)",
            "Currency": "Netherlands Antillean Guilder",
            "Alphabetic Code": "ANG",
            "Numeric Code": 532,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS ",
            "Currency": "Sucre",
            "Alphabetic Code": "XSU",
            "Numeric Code": 994,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "SLOVAKIA",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SLOVENIA",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SOLOMON ISLANDS",
            "Currency": "Solomon Islands Dollar",
            "Alphabetic Code": "SBD",
            "Numeric Code": 90,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SOMALIA",
            "Currency": "Somali Shilling",
            "Alphabetic Code": "SOS",
            "Numeric Code": 706,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SOUTH AFRICA",
            "Currency": "Rand",
            "Alphabetic Code": "ZAR",
            "Numeric Code": 710,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
            "Currency": "No universal currency",
            "Alphabetic Code": "",
            "Numeric Code": null,
            "Minor Unit": ""
        },
        {
            "Entity / Location": "SOUTH SUDAN",
            "Currency": "South Sudanese Pound",
            "Alphabetic Code": "SSP",
            "Numeric Code": 728,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SPAIN",
            "Currency": "Euro",
            "Alphabetic Code": "EUR",
            "Numeric Code": 978,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SRI LANKA",
            "Currency": "Sri Lanka Rupee",
            "Alphabetic Code": "LKR",
            "Numeric Code": 144,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SUDAN (THE)",
            "Currency": "Sudanese Pound",
            "Alphabetic Code": "SDG",
            "Numeric Code": 938,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SURINAME",
            "Currency": "Surinam Dollar",
            "Alphabetic Code": "SRD",
            "Numeric Code": 968,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SVALBARD AND JAN MAYEN",
            "Currency": "Norwegian Krone",
            "Alphabetic Code": "NOK",
            "Numeric Code": 578,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ESWATINI",
            "Currency": "Lilangeni",
            "Alphabetic Code": "SZL",
            "Numeric Code": 748,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SWEDEN",
            "Currency": "Swedish Krona",
            "Alphabetic Code": "SEK",
            "Numeric Code": 752,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SWITZERLAND",
            "Currency": "Swiss Franc",
            "Alphabetic Code": "CHF",
            "Numeric Code": 756,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SWITZERLAND",
            "Currency": "WIR Euro",
            "Alphabetic Code": "CHE",
            "Numeric Code": 947,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SWITZERLAND",
            "Currency": "WIR Franc",
            "Alphabetic Code": "CHW",
            "Numeric Code": 948,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "SYRIAN ARAB REPUBLIC",
            "Currency": "Syrian Pound",
            "Alphabetic Code": "SYP",
            "Numeric Code": 760,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "TAIWAN (PROVINCE OF CHINA)",
            "Currency": "New Taiwan Dollar",
            "Alphabetic Code": "TWD",
            "Numeric Code": 901,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "TAJIKISTAN",
            "Currency": "Somoni",
            "Alphabetic Code": "TJS",
            "Numeric Code": 972,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "TANZANIA, UNITED REPUBLIC OF",
            "Currency": "Tanzanian Shilling",
            "Alphabetic Code": "TZS",
            "Numeric Code": 834,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "THAILAND",
            "Currency": "Baht",
            "Alphabetic Code": "THB",
            "Numeric Code": 764,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "TIMOR-LESTE",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "TOGO",
            "Currency": "CFA Franc BCEAO",
            "Alphabetic Code": "XOF",
            "Numeric Code": 952,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "TOKELAU",
            "Currency": "New Zealand Dollar",
            "Alphabetic Code": "NZD",
            "Numeric Code": 554,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "TONGA",
            "Currency": "Pa'anga",
            "Alphabetic Code": "TOP",
            "Numeric Code": 776,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "TRINIDAD AND TOBAGO",
            "Currency": "Trinidad and Tobago Dollar",
            "Alphabetic Code": "TTD",
            "Numeric Code": 780,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "TUNISIA",
            "Currency": "Tunisian Dinar",
            "Alphabetic Code": "TND",
            "Numeric Code": 788,
            "Minor Unit": "3"
        },
        {
            "Entity / Location": "TURKEY",
            "Currency": "Turkish Lira",
            "Alphabetic Code": "TRY",
            "Numeric Code": 949,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "TURKMENISTAN",
            "Currency": "Turkmenistan New Manat",
            "Alphabetic Code": "TMT",
            "Numeric Code": 934,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "TURKS AND CAICOS ISLANDS (THE)",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "TUVALU",
            "Currency": "Australian Dollar",
            "Alphabetic Code": "AUD",
            "Numeric Code": 36,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "UGANDA",
            "Currency": "Uganda Shilling",
            "Alphabetic Code": "UGX",
            "Numeric Code": 800,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "UKRAINE",
            "Currency": "Hryvnia",
            "Alphabetic Code": "UAH",
            "Numeric Code": 980,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "UNITED ARAB EMIRATES (THE)",
            "Currency": "UAE Dirham",
            "Alphabetic Code": "AED",
            "Numeric Code": 784,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)",
            "Currency": "Pound Sterling",
            "Alphabetic Code": "GBP",
            "Numeric Code": 826,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "UNITED STATES MINOR OUTLYING ISLANDS (THE)",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "UNITED STATES OF AMERICA (THE)",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "UNITED STATES OF AMERICA (THE)",
            "Currency": "US Dollar (Next day)",
            "Alphabetic Code": "USN",
            "Numeric Code": 997,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "URUGUAY",
            "Currency": "Peso Uruguayo",
            "Alphabetic Code": "UYU",
            "Numeric Code": 858,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "URUGUAY",
            "Currency": "Uruguay Peso en Unidades Indexadas (UI)",
            "Alphabetic Code": "UYI",
            "Numeric Code": 940,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "URUGUAY",
            "Currency": "Unidad Previsional",
            "Alphabetic Code": "UYW",
            "Numeric Code": 927,
            "Minor Unit": "4"
        },
        {
            "Entity / Location": "UZBEKISTAN",
            "Currency": "Uzbekistan Sum",
            "Alphabetic Code": "UZS",
            "Numeric Code": 860,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "VANUATU",
            "Currency": "Vatu",
            "Alphabetic Code": "VUV",
            "Numeric Code": 548,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
            "Currency": "Bolívar Soberano",
            "Alphabetic Code": "VES",
            "Numeric Code": 928,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "VIET NAM",
            "Currency": "Dong",
            "Alphabetic Code": "VND",
            "Numeric Code": 704,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "VIRGIN ISLANDS (BRITISH)",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "VIRGIN ISLANDS (U.S.)",
            "Currency": "US Dollar",
            "Alphabetic Code": "USD",
            "Numeric Code": 840,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "WALLIS AND FUTUNA",
            "Currency": "CFP Franc",
            "Alphabetic Code": "XPF",
            "Numeric Code": 953,
            "Minor Unit": "0"
        },
        {
            "Entity / Location": "WESTERN SAHARA",
            "Currency": "Moroccan Dirham",
            "Alphabetic Code": "MAD",
            "Numeric Code": 504,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "YEMEN",
            "Currency": "Yemeni Rial",
            "Alphabetic Code": "YER",
            "Numeric Code": 886,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ZAMBIA",
            "Currency": "Zambian Kwacha",
            "Alphabetic Code": "ZMW",
            "Numeric Code": 967,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ZIMBABWE",
            "Currency": "Zimbabwe Dollar",
            "Alphabetic Code": "ZWL",
            "Numeric Code": 932,
            "Minor Unit": "2"
        },
        {
            "Entity / Location": "ZZ01_Bond Markets Unit European_EURCO",
            "Currency": "Bond Markets Unit European Composite Unit (EURCO)",
            "Alphabetic Code": "XBA",
            "Numeric Code": 955,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "ZZ02_Bond Markets Unit European_EMU-6",
            "Currency": "Bond Markets Unit European Monetary Unit (E.M.U.-6)",
            "Alphabetic Code": "XBB",
            "Numeric Code": 956,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "ZZ03_Bond Markets Unit European_EUA-9",
            "Currency": "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",
            "Alphabetic Code": "XBC",
            "Numeric Code": 957,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "ZZ04_Bond Markets Unit European_EUA-17",
            "Currency": "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",
            "Alphabetic Code": "XBD",
            "Numeric Code": 958,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "ZZ06_Testing_Code",
            "Currency": "Codes specifically reserved for testing purposes",
            "Alphabetic Code": "XTS",
            "Numeric Code": 963,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "ZZ07_No_Currency",
            "Currency": "The codes assigned for transactions where no currency is involved",
            "Alphabetic Code": "XXX",
            "Numeric Code": 999,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "ZZ08_Gold",
            "Currency": "Gold",
            "Alphabetic Code": "XAU",
            "Numeric Code": 959,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "ZZ09_Palladium",
            "Currency": "Palladium",
            "Alphabetic Code": "XPD",
            "Numeric Code": 964,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "ZZ10_Platinum",
            "Currency": "Platinum",
            "Alphabetic Code": "XPT",
            "Numeric Code": 962,
            "Minor Unit": "NA"
        },
        {
            "Entity / Location": "ZZ11_Silver",
            "Currency": "Silver",
            "Alphabetic Code": "XAG",
            "Numeric Code": 961,
            "Minor Unit": "NA"
        }
    ]`)
