import React, {useEffect, useState} from 'react'
import PageWrapper from '../../components/PageWrapper'
import {useDispatch} from 'react-redux'
import {getAppleSessionTxtWellKnown, postNewContent} from '../../actions/appleSession'
import Button from '@material-ui/core/Button'
import RepeatIcon from '@material-ui/icons/Repeat'
import {Paper, Grid, TextField} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import {PAGES} from '../../utils/routes'
import Buttons from '../../components/Buttons/Buttons'
import {usePadding} from '../../css/usePadding'
import {useMargin} from '../../css/useMargin'

const AppleSession = () => {
    const [txtContent, setTxtContent] = useState('')
    const [changing, setChanging] = useState(false)
    const dispatch = useDispatch()
    const {p16px} = usePadding()
    const {mt15} = useMargin()

    const handleChange = ({target: {value}}) => {
        setTxtContent(value)
    }

    const handleChanging = () => {
        setChanging(!changing)
    }

    const postData = () => {
        dispatch(postNewContent(txtContent, (data) => setTxtContent(data)))
        setChanging(false)
    }

    useEffect(() => {
        dispatch(getAppleSessionTxtWellKnown((data) => setTxtContent(data)))
    }, [dispatch])

    const shorterText = `${txtContent.substring(0, 20)}  .  .  .   ${txtContent.substring(
        txtContent.length - 20,
        txtContent.length
    )}`

    const rightButton = (
        <Button
            variant={'outlined'}
            startIcon={changing ? <CancelIcon color={'error'} /> : <RepeatIcon />}
            color={changing ? 'secondary' : 'primary'}
            onClick={handleChanging}>
            {changing ? 'Zrušit editaci' : 'Změnit obsah'}
        </Button>
    )

    return (
        <PageWrapper headingFor={PAGES.APPLE_CERT} rightBar={rightButton}>
            <Grid container justify={'center'} alignItems={'center'}>
                <Grid item md={6}>
                    <Paper className={p16px}>
                        {changing ? (
                            <TextField
                                value={txtContent}
                                rowsMax={20}
                                rows={20}
                                multiline
                                fullWidth
                                onChange={handleChange}
                                variant={'outlined'}
                            />
                        ) : (
                            shorterText
                        )}
                        {changing && (
                            <div className={mt15}>
                                <Buttons onClick={postData} startIcon={<SaveIcon />}>
                                    Ulozit
                                </Buttons>
                            </div>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    )
}
export default AppleSession
