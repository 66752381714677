import React from 'react'
import {Route} from 'react-router-dom'
import {connect} from 'react-redux'
import {includes, isEmpty} from 'ramda'
import {userAuthorizedSelector, userRoleSelector} from '../selectors/user'
import {loadingAutoLoginSelector} from '../selectors/fetches/loginFetch'
import Login from '../pages/Login'
import LoadingPage from '../pages/LoadingPage'
import {isNilOrEmpty} from 'ramda-extension'

const PrivateRoute = ({component: Component, roles = [], user, path, isAuthorized, role, waitting, ...rest}) => (
    <Route
        path={path}
        {...rest}
        render={(props) => {
            if (waitting) {
                return <LoadingPage />
            }
            if (!isAuthorized || isNilOrEmpty(localStorage.getItem('token'))) {
                return <Login />
            }
            if (isEmpty(roles) || includes(role, roles)) {
                return <Component {...props} />
            }
        }}
    />
)
export default connect((state) => ({
    isAuthorized: userAuthorizedSelector(state),
    role: userRoleSelector(state),
    waitting: loadingAutoLoginSelector(state),
}))(PrivateRoute)
