export const cFieldNames = {
    destinationName: 'destinationName',
    tempMonth: 'temperature.month',
    tempWater: 'temperature.water',
    tempAir: 'temperature.air',
}

export const initialValues = {
    [cFieldNames.destinationName]: '',
    [cFieldNames.tempMonth]: 0,
    [cFieldNames.tempWater]: 0,
    [cFieldNames.tempAir]: 0,
}

export const validate = (values) => {
    const errors = {}

    if (!values.destinationName) {
        errors.destinationName = 'Povinné pole'
    }
    if (!values.tempMonth) {
        errors.tempMonth = 'Povinné pole'
    }
    if (!values.tempWater) {
        errors.tempWater = 'Povinné pole'
    }
    if (!values.tempAir) {
        errors.tempAir = 'Povinné pole'
    }
    return errors
}
