import React from 'react'
import {useDispatch} from 'react-redux'
import {ClickAwayListener, Fade, Grid, Paper, Popper, Typography} from '@material-ui/core'
import PdfSVG from '../../assets/pdfSVG.svg'
import {getOrderInvoice} from '../../actions/orders/index'
import Icon8 from '../IconFromPNG/Icon8'
import Buttons from './Buttons'
import {usePadding} from '../../css/usePadding'

const GenerateOrderPDF = ({id}) => {
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [open, setOpen] = React.useState(false)
    const [placement, setPlacement] = React.useState()
    const {p1rem} = usePadding()

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget)
        setOpen((prev) => placement !== newPlacement || !prev)
        setPlacement(newPlacement)
    }

    const fetchForInvoice = (propagateToUser) => (e) => {
        dispatch(getOrderInvoice(id, true, propagateToUser))
        handleClickAway()
    }

    const handleClickAway = () => {
        setAnchorEl(null)
        setOpen(false)
    }
    return (
        <Grid container spacing={2}>
            <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <Paper>
                                <Grid container direction={'column'} className={p1rem}>
                                    <Grid item>
                                        <Typography variant={'body1'}>Poslat email a notifikaci uživateli ?</Typography>
                                    </Grid>
                                    <Grid container justify={'center'} alignItems={'center'} spacing={3}>
                                        <Grid item>
                                            <Buttons success onClick={fetchForInvoice(true)}>
                                                Ano
                                            </Buttons>
                                        </Grid>
                                        <Grid item>
                                            <Buttons error onClick={fetchForInvoice(false)}>
                                                Ne
                                            </Buttons>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </ClickAwayListener>
                    </Fade>
                )}
            </Popper>
            <Grid item>
                <Buttons startIcon={<Icon8 src={PdfSVG} />} onClick={handleClick('bottom')}>
                    {'Generovat PDF'}
                </Buttons>
            </Grid>
        </Grid>
    )
}
export default GenerateOrderPDF
