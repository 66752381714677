import {handleActions} from 'redux-actions'
import inspirationsConstants from '../constants/inspirationsConstants'

const initialState = []

export default handleActions(
    {
        [inspirationsConstants.INSPIRATIONS_SUCCESS]: (state, {payload}) => payload,
        [inspirationsConstants.INSPIRATIONS_CREATE_SUCCESS]: (state, {payload}) => [...state, payload],
        [inspirationsConstants.INSPIRATIONS_DELETE_SUCCESS]: (state, {payload}) =>
            state.filter((insp) => insp.id !== payload),
        [inspirationsConstants.ON_SORT_END_INSPIRATIONS]: (state, {payload}) =>
            payload.map((insp, index) => ({...insp, location: index})),
        [inspirationsConstants.INSPIRATIONS_UPDATE_SUCCESS]: (state, {payload}) => {
            return state.map((insp) => (insp.id === payload.id ? payload : insp))
        },
    },
    initialState
)
