import React, {useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import {Button, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {connect, useDispatch} from 'react-redux'
import SaveIcon from '@material-ui/icons/Save'
import InsppirationForm from './InsppirationForm'
import {formValueSelector, submit} from 'redux-form'
import {createNewInspirations, updateInspirationWithImage, updateWithOldIamge} from '../../../actions/inspirations'
import {inspirationModalOpen} from '../../../selectors/modals'
import {closeInspirationModal, openErrorModal} from '../../../actions/modal'
import formNames from '../../../constants/form/formNames'

const InspirationModal = ({closeInspirationModal, open, id, openErrorModal}) => {
    const dispatch = useDispatch()
    const [withNewImage, setWithNewImage] = useState(false)

    const onSubmit = (data) => {
        if (id) {
            withNewImage ? dispatch(updateInspirationWithImage(data)) : dispatch(updateWithOldIamge(data))
        } else {
            dispatch(createNewInspirations(data))
        }
    }
    const closeModal = () => {
        closeInspirationModal()
    }
    const handleSubmit = () => {
        dispatch(submit(formNames.INSPIRATIONS_FORM))
    }

    return (
        <Dialog open={open} fullWidth={true} onEscapeKeyDown={closeModal} maxWidth={'sm'}>
            <DialogTitle id="form-dialog-title">Inspirace na cesty</DialogTitle>
            <DialogContent>
                <InsppirationForm
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    withNewImage={withNewImage}
                    setWithNewImage={setWithNewImage}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => closeInspirationModal()}>
                    Zavřít
                </Button>
                <Button variant="outlined" color="primary" size="small" onClick={handleSubmit} startIcon={<SaveIcon />}>
                    Uložit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const selector = formValueSelector(formNames.INSPIRATIONS_FORM)
export default connect(
    (state) => ({
        open: inspirationModalOpen(state),
        id: selector(state, 'id'),
        imageUrl: selector(state, 'imageUrl'),
    }),
    {
        closeInspirationModal,
        openErrorModal,
    }
)(InspirationModal)
