import React from 'react'
import {sortableElement} from 'react-sortable-hoc'
import InspirationItem from './InspirationItem'

const SortableItem = sortableElement(({value}) => (
    <div tabIndex={0}>
        <InspirationItem inspiration={value} />
    </div>
))

export default SortableItem
