import {keyMirror} from 'ramda-extension'

export default keyMirror({
    LAUNCH_SCREEN_ALL_SUCCESS: null,
    LAUNCH_SCREEN_UPDATE_SUCCESS: null,
    LAUNCH_SCREEN_DELETE_SUCCESS: null,
    LAUNCH_SCREEN_OPEN_FORM: null,
    LAUNCH_SCREEN_CLOSE_FORM: null,
    LAUNCH_SCREEN_CREATE_SUCCESS: null,
})
