import {APIroutes} from '../../constants/routes'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import {setLoading, setNull} from '../fetch'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import launchScreenConstants from '../../constants/launchScreenConstants'
import {createAction} from 'redux-actions'
import {toastSuccess} from '../../components/Toast/ToastSuccess'
import {closeReduxModal} from '../modal'
import modalNames from '../../constants/modal/modalNames'

const fetchSuccess = createAction(launchScreenConstants.LAUNCH_SCREEN_ALL_SUCCESS)

export const getAllLaunchScreens = () => (dispatch, getState) => {
    const {baseUrl, version, allLaunchScreens} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.LAUNCH_SCREEN_FETCH_ALL))
    Axios.get(`${baseUrl}${version}${allLaunchScreens}`, config)
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.LAUNCH_SCREEN_FETCH_ALL}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.LAUNCH_SCREEN_FETCH_ALL))
}

const fetchUpdateSuccess = createAction(launchScreenConstants.LAUNCH_SCREEN_UPDATE_SUCCESS)

export const updateScreen = (data) => (dispatch, getState) => {
    const {baseUrl, version, launchScreen} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.LAUNCH_SCREEN_UPDATE))
    Axios.put(`${baseUrl}${version}${launchScreen}`, data, config)
        .then(({data}) => {
            dispatch(fetchUpdateSuccess(data))
            dispatch(setNull({fetch: fetchConstants.LAUNCH_SCREEN_UPDATE}))
            dispatch(closeReduxModal(modalNames.LAUNCH_SCREEN_MODAL))
            toastSuccess('Obrazovka aktualizována')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.LAUNCH_SCREEN_UPDATE))
}

const deleteSuccessfull = createAction(launchScreenConstants.LAUNCH_SCREEN_DELETE_SUCCESS)

export const deleteScreen = (id) => (dispatch, getState) => {
    const {baseUrl, version, launchScreen} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.LAUNCH_SCREEN_DELETE))
    Axios.delete(`${baseUrl}${version}${launchScreen}/${id}`, config)
        .then(({data}) => {
            dispatch(deleteSuccessfull(id))
            dispatch(setNull({fetch: fetchConstants.LAUNCH_SCREEN_DELETE}))
            toastSuccess('Obrazovka odstraněna')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.LAUNCH_SCREEN_DELETE))
}

const fetchCreateSuccess = createAction(launchScreenConstants.LAUNCH_SCREEN_CREATE_SUCCESS)

export const createScreen = (data) => (dispatch, getState) => {
    const {baseUrl, version, launchScreen} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.LAUNCH_SCREEN_CREATE))
    Axios.post(`${baseUrl}${version}${launchScreen}`, data, config)
        .then(({data}) => {
            dispatch(fetchCreateSuccess(data))
            dispatch(setNull({fetch: fetchConstants.LAUNCH_SCREEN_CREATE}))
            dispatch(closeReduxModal(modalNames.LAUNCH_SCREEN_MODAL))
            toastSuccess('Obrazovka byla přidána')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.LAUNCH_SCREEN_CREATE))
}
