import {handleActions} from 'redux-actions'
import launchScreenConstants from '../constants/launchScreenConstants'

const initialState = []

export default handleActions(
    {
        [launchScreenConstants.LAUNCH_SCREEN_ALL_SUCCESS]: (state, {payload}) => payload,
        [launchScreenConstants.LAUNCH_SCREEN_DELETE_SUCCESS]: (state, {payload}) =>
            state.filter((sec) => sec.id !== payload),
        [launchScreenConstants.LAUNCH_SCREEN_CREATE_SUCCESS]: (state, {payload}) => [...state, payload],
        [launchScreenConstants.LAUNCH_SCREEN_UPDATE_SUCCESS]: (state, {payload}) =>
            state.map((screen) => (screen.id === payload.id ? payload : screen)),
    },
    initialState
)
