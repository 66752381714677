import React from 'react'
import {Grid} from '@material-ui/core'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {renderCheckbox, renderTextField, TextFileInput} from '../../UserFormDialog/TextField'
import {connect} from 'react-redux'
import formNames from '../../../constants/form/formNames'
import {cFieldNames, initialValues, validate} from '../../../constants/form/inspirationsForm'

const InspirationForm = ({handleSubmit, imageUrl, setWithNewImage}) => (
    <form onSubmit={handleSubmit} onKeyDown={(e) => e.keyCode === 13 && handleSubmit()}>
        <Grid container spacing={1} justify="center">
            <Grid item md={3} xs={12} container justify="center" alignItems="center">
                Titulek
            </Grid>
            <Grid item xs={12} md={9}>
                <Field name={cFieldNames.title} component={renderTextField} multiline />
            </Grid>
            <Grid item md={3} xs={12} container justify="center" alignItems="center">
                Aktivní
            </Grid>
            <Grid item xs={12} md={9}>
                <Field
                    name={cFieldNames.active}
                    color={'primary'}
                    value={false}
                    component={renderCheckbox}
                    defaultValue={false}
                    defaultChecked={false}
                />
            </Grid>
            <Grid item md={3} xs={12} container justify="center" alignItems="center">
                Obrázek
            </Grid>
            <Grid item xs={12} md={9}>
                <Field
                    name={cFieldNames.image}
                    editImage={imageUrl}
                    withNewImage={setWithNewImage}
                    component={TextFileInput}
                    type="file"
                    value={null}
                />
            </Grid>
            <Grid item md={3} xs={12} container justify="center" alignItems="center">
                Filtr
            </Grid>
            <Grid item xs={12} md={9}>
                <Field name={cFieldNames.filter} component={renderTextField} value="{ }" rows={12} multiline />
            </Grid>
        </Grid>
    </form>
)

const selector = formValueSelector(formNames.INSPIRATIONS_FORM)

export default connect((state) => ({
    id: selector(state, 'id'),
    imageUrl: selector(state, 'imageUrl'),
}))(
    reduxForm({
        form: formNames.INSPIRATIONS_FORM,
        validate,
        initialValues,
    })(InspirationForm)
)
