import {useDispatch, connect} from 'react-redux'
import {Button} from '@material-ui/core'
import SyncIcon from '@material-ui/icons/Sync'
import {fetchAllOrders} from '../../actions/orders'
import React from 'react'
import {fetchOrderDetail} from '../../actions/orders/orderDetail'
import {loadingGetAllOrdersSelector} from '../../selectors/fetches'
import {loadingDetailOrderSelector} from '../../selectors/fetches/ordersFetch'

const OrderRefreshButton = ({id, page, rowsPerPage}) => {
    const dispatch = useDispatch()
    return (
        <Button
            onClick={() => dispatch(id ? fetchOrderDetail(id) : fetchAllOrders(rowsPerPage, page))}
            variant="outlined"
            color={'primary'}
            startIcon={<SyncIcon />}>
            Aktualizovat
        </Button>
    )
}

export default connect((state) => ({
    loading: loadingGetAllOrdersSelector(state),
    loadingOrder: loadingDetailOrderSelector(state),
}))(OrderRefreshButton)
