import React, {useEffect} from 'react'
import {Button, Grid} from '@material-ui/core'
import {useDispatch, useSelector} from 'react-redux'
import {launchScreenSelector} from '../../selectors/launchScreen'
import {deleteScreen} from '../../actions/launchScreen'
import {destroy, initialize} from 'redux-form'
import {useStyles} from './styles'
import PageWrapper from '../../components/PageWrapper'
import {PAGES} from '../../utils/routes'
import LaunchScreenModal from './Dialog/launchScrenDialog'
import {closeReduxModal, openReduxModal} from '../../actions/modal'
import modalNames from '../../constants/modal/modalNames'
import formNames from '../../constants/form/formNames'
import LaunchScreenTable from '../../components/LaunchScreen/LuanchScreenTable'
import AddIcon from '@material-ui/icons/Add'

const LaunchScreenFormContainer = () => {
    const launchScreens = useSelector(launchScreenSelector)
    const dispatch = useDispatch()
    const classes = useStyles()
    const closeForm = () => dispatch(closeReduxModal(modalNames.LAUNCH_SCREEN_MODAL))

    const openForm = () => dispatch(openReduxModal(modalNames.LAUNCH_SCREEN_MODAL))

    const deleteLaunchScreen = (id) => {
        dispatch(deleteScreen(id))
    }

    const updateLaunchScreenForm = (data) => {
        dispatch(initialize(formNames.LAUNCH_SCREEN_FORM, data))
        dispatch(openForm())
    }

    const destroyForm = () => {
        dispatch(destroy(formNames.LAUNCH_SCREEN_FORM))
    }

    useEffect(() => {
        return () => {
            destroyForm()
            closeForm()
        }
        // eslint-disable-next-line
    }, [])

    return (
        <PageWrapper
            headingFor={PAGES.LANDING_PAGE}
            rightBar={
                <Button
                    color={'primary'}
                    variant={'outlined'}
                    onClick={openForm}
                    startIcon={<AddIcon color={'primary'} />}>
                    Přidat sekci
                </Button>
            }>
            <LaunchScreenModal />

            <Grid container spacing={3} direction={'row'}>
                <LaunchScreenTable
                    launchScreens={launchScreens}
                    classes={classes}
                    deleteLaunchScreen={deleteLaunchScreen}
                    updateLaunchScreenForm={updateLaunchScreenForm}
                />
            </Grid>
        </PageWrapper>
    )
}
export default LaunchScreenFormContainer
