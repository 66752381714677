import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import {createAction} from 'redux-actions'
import destinationTemperaturesConstants from '../../constants/destinationTemperaturesConstants'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import {toastSuccess} from '../../components/Toast/ToastSuccess'
import {closeReduxModal} from '../modal'
import modalNames from '../../constants/modal/modalNames'

const fetchSuccess = createAction(destinationTemperaturesConstants.TEMPERATURE_ALL_SUCCESS)

export const fetchAllDestintionsTemperatures = () => (dispatch, getState) => {
    const {baseUrl, version, temperatures} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.FETCH_DESTINATION_ALL))
    Axios.get(`${baseUrl}${version}${temperatures}`, config)
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.FETCH_DESTINATION_ALL}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.FETCH_DESTINATION_ALL))
}

const putSuccess = createAction(destinationTemperaturesConstants.UPDATE_TEMPERATURE)

export const updateTemperatures = ({destinationId, temperature: {month, air, water}}) => (dispatch, getState) => {
    const {baseUrl, version, temperatures} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.UPDATE_TEMPERATURE_FETCH))
    Axios.put(
        `${baseUrl}${version}${temperatures}/${destinationId}/${month}`,
        {airTemperature: air, waterTemperature: water},
        config
    )
        .then(({data}) => {
            dispatch(putSuccess(data))
            dispatch(setNull({fetch: fetchConstants.UPDATE_TEMPERATURE_FETCH}))
            dispatch(closeReduxModal(modalNames.DESTINATION_TEMPERATURES_MODAL))
            toastSuccess('Teploty úspěšně aktualizovány')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.UPDATE_TEMPERATURE_FETCH))
}

export const fetchHotelDetailInfo = (hotel, cb) => (dispatch, getState) => {
    const {searchApiHotelId} = APIroutes
    Axios.get(`${searchApiHotelId}/${hotel}`)
        .then(({data}) => {
            if (typeof cb === 'function') {
                cb(data)
            }
        })
        .catch((e) => console.log(e))
}
