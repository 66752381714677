import React, {useEffect} from 'react'
import PropagateOffersList from '../../components/PropagateOffers'
import {useDispatch} from 'react-redux'
import {fetchAllPropagateOffers} from '../../actions/propagateOffers'

const PropagateOffers = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAllPropagateOffers())
    }, [dispatch])

    return <PropagateOffersList />
}
export default PropagateOffers
