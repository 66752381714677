import {keyMirror} from 'ramda-extension'

const fetchConstants = keyMirror({
    SET_LOADING: null,
    SET_ERROR: null,
    SET_NULL: null,
})

const userFetchConstants = keyMirror({
    USER_ALL: null,
    USER_LOGIN: null,
    USER_AUTO_LOGIN: null,
    USER_EDIT: null,
    USER_INIT: null,
    USER_DELETE: null,
    USER_CREATE: null,
})

const ordersFetchConstants = keyMirror({
    ORDERS_ALL: null,
    ORDER_DETAIL: null,
    ORDER_INVOICE: null,
    ORDER_STORNO: null,
    ORDER_DONE: null,
})
const offersFetchConstatns = keyMirror({
    OFFERS_ALL: null,
    OFFER_DELETE: null,
    OFFERS_CHANGE_POSITIONS: null,
    UPDATE_WITHOUT_PICTURE: null,
    UPDATE_WITH_PICTURE: null,
    UPLOAD_OFFERS: null,
})

const codeList = keyMirror({
    CODELIST_FETCH: null,
})

const flightCompanies = keyMirror({
    FLIGHT_COMPANIES_ALL: null,
    FLIGHT_COMPANIES_CREATE: null,
    FLIGHT_COMPANIES_DELETE: null,
    FLIGHT_COMPANIES_UPDATE: null,
})

const propagateOffers = keyMirror({
    PROPAGATE_OFFERS_ALL: null,
    UPDATE_PROPAGATE_OFFER: null,
    DELETE_PROPAGATE: null,
    CREATE_PROPAGATE: null,
    CHANGE_LOCATIONS: null,
})

const destinationTemperatures = keyMirror({
    FETCH_DESTINATION_ALL: null,
    UPDATE_TEMPERATURE_FETCH: null,
})

const inspirations = keyMirror({
    FETCH_ALL_INSPIRATIONS: null,
    CREATE_INSPIRATION: null,
    DELETE_INSPIRATION: null,
    CHANGE_ORDER_INSPIRATIONS: null,
    UDPATE_INSPIRATIONS: null,
    UPDATE_INSPIRATIONS_WITHOUT_IMAGE: null,
})

const notification = keyMirror({
    SEND_NOTIFICATION: null,
})

const launchScreen = keyMirror({
    LAUNCH_SCREEN_FETCH_ALL: null,
    LAUNCH_SCREEN_UPDATE: null,
    LAUNCH_SCREEN_DELETE: null,
    LAUNCH_SCREEN_CREATE: null,
})

const appleSession = keyMirror({
    GET_APPLE_SESSION_TXT: null,
})

const fioPayments = keyMirror({
    ALL_FIO_PAYMENTS: null,
    ALL_PAYMENTS_WITHOUT_ORDER_FETCH: null,
    ALL_PAYMENTS_WITH_ORDER_FETCH: null,
    ACCOUNT_FETCH: null,
})

export default {
    ...codeList,
    ...fetchConstants,
    ...userFetchConstants,
    ...ordersFetchConstants,
    ...offersFetchConstatns,
    ...flightCompanies,
    ...propagateOffers,
    ...inspirations,
    ...notification,
    ...destinationTemperatures,
    ...launchScreen,
    ...appleSession,
    ...fioPayments,
}
