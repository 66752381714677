import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    section: {
        padding: '10px',
    },
    screenHeading: {
        padding: '0px 25px',
        fontSize: '1.1rem',
        fontWeight: '500',
    },
    addButton: {
        fill: 'green',
    },
    addSection: {
        fill: 'green',
        paddingRight: '5px',
    },
    formSection: {
        margin: '10px 0px',
    },
    cancelButton: {
        marginTop: '5px',
    },
    dividerMargin: {
        marginTop: '5px',
    },
    fieldLocation: {
        width: '50px',
    },
    form: {
        paddingTop: '20px',
        padding: '10px',
    },
    cancelSectionButton: {
        position: 'absolute',
        top: '0px',
        right: '0px',
        transform: 'translate(50%, -50%)',
    },
    cancelOrSubmitButtons: {
        paddingTop: '15px',
    },
    screenActive: {
        paddingLeft: '10px',
    },
    screenActiveSVG: {
        fill: 'green',
    },
    screenActiveText: {
        color: 'green',
    },
    addNewScreenButtonSection: {
        minHeight: '200px',
    },
}))
