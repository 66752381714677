import React, {useEffect} from 'react'
import {Router, Switch} from 'react-router-dom'
import {ConnectedRouter} from 'connected-react-router'
import {history} from './index'
import Dashboard from './pages/Dashboard'
import PrivateRoute from './utils/PrivateRoute'
import {useDispatch, useSelector} from 'react-redux'
import {autoLogin} from './actions/users/login'
import {CssBaseline} from '@material-ui/core'
import useStyles from './pages/Dashboard/classes'
import {ToastContainer} from 'react-toastify'
import Loader from './components/Loader'
import {loadingSelector} from './selectors/fetches'

function App() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const loading = useSelector(loadingSelector)

    useEffect(() => {
        dispatch(autoLogin())
    }, [dispatch])

    return (
        <div className={classes.root}>
            <ToastContainer />
            <CssBaseline />
            <Loader open={loading} />
            <Router history={history}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <PrivateRoute path={'/'} roles={['ROLE_ADMIN']} component={Dashboard} />
                    </Switch>
                </ConnectedRouter>
            </Router>
        </div>
    )
}

export default App
