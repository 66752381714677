import React, {useEffect, useState} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TableUsersHead from './TableUsersHead'
import {loadAllUsrs} from '../../actions/users/loadAllUsers'
import {connect, useDispatch} from 'react-redux'
import {IconButton} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import UserFormDialog from '../UserFormDialog'
import {deleteUser} from '../../actions/users/deleteUser'
import {initUser} from '../../actions/users/initUser'
import EditFormDialog from '../UserFormDialog/EditFormDialog'
import {loadingGetAllUsersSelector} from '../../selectors/fetches'
import {IdDeletingUserSelector, loadingUserDeleteSelector} from '../../selectors/fetches/deleteUserFetch'
import {usersSelector} from '../../selectors/user'
import PropTypes from 'prop-types'
import PageWrapper from '../PageWrapper'
import AddUserButton from './AddUserButton'
import {PAGES} from '../../utils/routes'
import ConfirmDeleteWithIcon from '../ConfirmDialog/ConfirmDeleteWithIcon'

const UsersTable = ({pageable, loadingDelete}) => {
    const dispatch = useDispatch()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const {content, totalElements} = pageable

    let rows = content
    useEffect(() => {
        dispatch(loadAllUsrs(rowsPerPage, page))
    }, [dispatch, rowsPerPage, page])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
    }

    return (
        <PageWrapper headingFor={PAGES.USERS} rightBar={<AddUserButton />}>
            <div>
                <UserFormDialog />
                <EditFormDialog />
                <Paper>
                    <TableContainer>
                        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
                            <TableUsersHead />
                            <TableBody>
                                {rows.map((row) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.id}>
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="left">{row.userName}</TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="left">{row.firstName}</TableCell>
                                            <TableCell align="left">{row.lastName}</TableCell>
                                            <TableCell align="left">{row.role}</TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    onClick={() => dispatch(initUser(row.id))}
                                                    disabled={loadingDelete}>
                                                    <EditIcon color={'primary'} />
                                                </IconButton>

                                                <ConfirmDeleteWithIcon onApprove={() => dispatch(deleteUser(row.id))} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
        </PageWrapper>
    )
}
UsersTable.propTypes = {
    loading: PropTypes.bool,
    pageable: PropTypes.object,
    deletingID: PropTypes.number,
    loadingDelete: PropTypes.bool,
}
export default connect((state) => ({
    loading: loadingGetAllUsersSelector(state),
    pageable: usersSelector(state),
    deletingID: IdDeletingUserSelector(state),
    loadingDelete: loadingUserDeleteSelector(state),
}))(UsersTable)
