import {keyMirror} from 'ramda-extension'

export default keyMirror({
    PROPAGATE_OFFERS_ALL_SUCCESS: null,
    PROPAGATE_UPDATE_SUCCESS: null,
    DELETE_PROPAGATE_OFFER: null,
    CREATE_PROPAGATE_OFFER: null,
    UI_EDITTING: null,
    UI_CLOSE_EDITTING: null,
    OPEN_MODAL_PROPAGATE: null,
    CLOSE_MODAL_PROPAGATE: null,
    PRE_ORDER_ARRAY: null,
    LOCATION_CHANGED: null,
    SET_NULL_LOCATION_CHANGED: null,
})
