import {createSelector} from 'reselect'
import fetchConstants from '../../../constants/fetchConstants'

export const fetchRequestsSelector = (state) => state.fetch.requests

export const deleteUserFetchSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.USER_DELETE]
)

export const loadingUserDeleteSelector = createSelector(deleteUserFetchSelector, (state) => state.loading)
export const deleteUISelector = (state) => state.ui.deleteUser
export const IdDeletingUserSelector = createSelector(deleteUISelector, (state) => state.id)
