import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import {useStyles} from '../../css/useStyles'

const OrderCustomerTable = ({
    order: {
        orderCustomer: {firstname, lastname, email, phone, street, city, zip},
    },
}) => {
    const {tableHeadGray} = useStyles()

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead className={tableHeadGray}>
                    <TableRow>
                        <TableCell>Jméno</TableCell>
                        <TableCell align="left">Příjmení</TableCell>
                        <TableCell align="left">E-mail</TableCell>
                        <TableCell align="left">Ulice, ČP</TableCell>
                        <TableCell align="left"> Město</TableCell>
                        <TableCell align="left"> PSČ</TableCell>
                        <TableCell align="left">Telefon</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            {firstname}
                        </TableCell>
                        <TableCell align="left">{lastname}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{street}</TableCell>
                        <TableCell align="left">{city}</TableCell>
                        <TableCell align="left">{zip}</TableCell>
                        <TableCell align="left">{phone}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
OrderCustomerTable.propTypes = {
    order: PropTypes.object,
}

export default OrderCustomerTable
