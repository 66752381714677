import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import {IconButton} from '@material-ui/core'
import {not} from 'ramda'
import TableContainer from '@material-ui/core/TableContainer'
import React from 'react'
import {isNilOrEmpty} from 'ramda-extension'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import ConfirmDeleteWithIcon from '../ConfirmDialog/ConfirmDeleteWithIcon'
import CreateIcon from '@material-ui/icons/Create'
import {useStyles} from '../../css/useStyles'
import {useColors} from '../../css/useColors'
import {usePadding} from '../../css/usePadding'

const LaunchScreenTable = ({launchScreens, deleteLaunchScreen, updateLaunchScreenForm}) => {
    const {tableHeadGray} = useStyles()
    const {svgGreen, svgRed} = useColors()
    const {plr8px} = usePadding()
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead className={tableHeadGray}>
                    <TableRow>
                        <TableCell width={100}>ID</TableCell>
                        <TableCell width={450} align="left">
                            Titulek
                        </TableCell>
                        <TableCell align="left">Počet subsekcí</TableCell>
                        <TableCell align="left">Aktivní</TableCell>
                        <TableCell align="left"> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {not(isNilOrEmpty(launchScreens)) &&
                        launchScreens.map((screen) => (
                            <TableRow>
                                <TableCell className={plr8px} component="th" scope="row">
                                    {screen.id}
                                </TableCell>
                                <TableCell className={plr8px} component="th" scope="row">
                                    {screen.headingTitle}
                                </TableCell>
                                <TableCell className={plr8px} component="th" scope="row">
                                    {screen.sections.length}
                                </TableCell>
                                <TableCell className={plr8px} align="left">
                                    <FiberManualRecordIcon
                                        className={screen.active ? svgGreen : svgRed}
                                        fontSize="small"
                                    />
                                </TableCell>
                                <TableCell className={plr8px} align={'right'}>
                                    <ConfirmDeleteWithIcon onApprove={() => deleteLaunchScreen(screen.id)} />
                                    <IconButton>
                                        <CreateIcon color={'primary'} onClick={() => updateLaunchScreenForm(screen)} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export default LaunchScreenTable
