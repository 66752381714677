import codelistConstants from '../../constants/codelistConstants'
import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import {createAction} from 'redux-actions'
import fetchErrorHandler from '../../utils/fetchErrorHandler'

const fetchSuccess = createAction(codelistConstants.CODELIST_FETCH_SUCCESS)

export const fetchCodeListTringa = () => (dispatch, getState) => {
    const {codelist} = APIroutes
    dispatch(setLoading(fetchConstants.CODELIST_FETCH))
    Axios.get(codelist)
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.CODELIST_FETCH}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.CODELIST_FETCH))
}
