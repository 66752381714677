export default JSON.parse(`[
    {
        "Banka": "Komerční banka, a.s.",
        "Kód banky": "0100"
    },
    {
        "Banka": "ČSOB, a.s.",
        "Kód banky": "0300"
    },
    {
        "Banka": "MONETA Money Bank, a.s.",
        "Kód banky": "0600"
    },
    {
        "Banka": "Česká národní banka",
        "Kód banky": "0710"
    },
    {
        "Banka": "Česká spořitelna, a.s.",
        "Kód banky": "0800"
    },
    {
        "Banka": "Fio banka, a.s.",
        "Kód banky": "2010"
    },
    {
        "Banka": "MUFG Bank (Europe) N.V. Prague Branch",
        "Kód banky": "2020"
    },
    {
        "Banka": "AKCENTA, spořitelní a úvěrní družstvo",
        "Kód banky": "2030"
    },
    {
        "Banka": "Citfin, spořitelní družstvo",
        "Kód banky": "2060"
    },
    {
        "Banka": "Moravský Peněžní Ústav – spořitelní družstvo",
        "Kód banky": "2070"
    },
    {
        "Banka": "Hypoteční banka, a.s.",
        "Kód banky": "2100"
    },
    {
        "Banka": "Peněžní dům, spořitelní družstvo",
        "Kód banky": "2200"
    },
    {
        "Banka": "Artesa, spořitelní družstvo",
        "Kód banky": "2220"
    },
    {
        "Banka": "Poštová banka, a.s., pobočka Česká republika",
        "Kód banky": "2240"
    },
    {
        "Banka": "Banka CREDITAS a.s.",
        "Kód banky": "2250"
    },
    {
        "Banka": "ANO spořitelní družstvo",
        "Kód banky": "2260"
    },
    {
        "Banka": "ZUNO BANK AG, organizační složka",
        "Kód banky": "2310"
    },
    {
        "Banka": "Citibank Europe plc, organizační složka",
        "Kód banky": "2600"
    },
    {
        "Banka": "UniCredit Bank Czech Republic and Slovakia, a.s.",
        "Kód banky": "2700"
    },
    {
        "Banka": "Air Bank a. s.",
        "Kód banky": "3030"
    },
    {
        "Banka": "BNP Paribas Personal Finance SA, odštěpný závod",
        "Kód banky": "3050"
    },
    {
        "Banka": "PKO BP S.A., Czech Branch",
        "Kód banky": "3060"
    },
    {
        "Banka": "ING Bank N.V.",
        "Kód banky": "3500"
    },
    {
        "Banka": "Expobank CZ a.s.",
        "Kód banky": "4000"
    },
    {
        "Banka": "Českomoravská záruční a rozvojová banka, a.s.",
        "Kód banky": "4300"
    },
    {
        "Banka": "Raiffeisenbank, a.s.",
        "Kód banky": "5500"
    },
    {
        "Banka": "J&T Banka, a.s.",
        "Kód banky": "5800"
    },
    {
        "Banka": "PPF banka a.s.",
        "Kód banky": "6000"
    },
    {
        "Banka": "Equa bank a. s.",
        "Kód banky": "6100"
    },
    {
        "Banka": "COMMERZBANK Aktiengesellschaft, pobočka Praha",
        "Kód banky": "6200"
    },
    {
        "Banka": "mBank S.A., organizační složka",
        "Kód banky": "6210"
    },
    {
        "Banka": "BNP Paribas Fortis SA/NV, pobočka Česká republika",
        "Kód banky": "6300"
    },
    {
        "Banka": "Všeobecná úverová banka a.s., pobočka Praha",
        "Kód banky": "6700"
    },
    {
        "Banka": "Sberbank CZ, a.s.",
        "Kód banky": "6800"
    },
    {
        "Banka": "Deutsche Bank A.G. Filiale Prag",
        "Kód banky": "7910"
    },
    {
        "Banka": "Waldviertler Sparkasse Bank AG",
        "Kód banky": "7940"
    },
    {
        "Banka": "Raiffeisen stavební spořitelna a.s.",
        "Kód banky": "7950"
    },
    {
        "Banka": "Českomoravská stavební spořitelna a. s.",
        "Kód banky": "7960"
    },
    {
        "Banka": "Wüstenrot - stavební spořitelna a.s.",
        "Kód banky": "7970"
    },
    {
        "Banka": "Wüstenrot hypoteční banka, a.s.",
        "Kód banky": "7980"
    },
    {
        "Banka": "Modrá pyramida stavební spořitelna, a.s.",
        "Kód banky": "7990"
    },
    {
        "Banka": "Raiffeisenbank im Stiftland Waldsassen eG pobočka Cheb, odštěpný závod",
        "Kód banky": "8030"
    },
    {
        "Banka": "Oberbank AG pobočka Česká republika",
        "Kód banky": "8040"
    },
    {
        "Banka": "Stavební spořitelna České spořitelny, a.s.",
        "Kód banky": "8060"
    },
    {
        "Banka": "Česká exportní banka, a.s.",
        "Kód banky": "8090"
    },
    {
        "Banka": "HSBC Bank plc - pobočka Praha",
        "Kód banky": "8150"
    },
    {
        "Banka": "PRIVAT BANK AG der Raiffeisenlandesbank Oberösterreich v České republice",
        "Kód banky": "8200"
    },
    {
        "Banka": "Payment Execution s.r.o.",
        "Kód banky": "8220"
    },
    {
        "Banka": "EEPAYS s. r. o.",
        "Kód banky": "8230"
    },
    {
        "Banka": "Družstevní záložna Kredit",
        "Kód banky": "8240"
    },
    {
        "Banka": "Bank of China (Hungary) Close Ltd. Prague branch, odštěpný závod",
        "Kód banky": "8250"
    }
]`)
