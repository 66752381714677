import React, {useEffect} from 'react'
import clsx from 'clsx'
import withWidth, {isWidthDown} from '@material-ui/core/withWidth'
import useStyles from './classes'
import DrawerNav from '../../components/DrawerNav'
import DashboardRoutes from '../../containers/DasbboardRoutes'
import {connect, useDispatch} from 'react-redux'
import {autoLoginFetchSelector} from '../../selectors/fetches/loginFetch'
import {fetchCodeListTringa} from '../../actions/tringaCodeList'

function Dashboard({width, match}) {
    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const [open, setOpen] = React.useState(true)
    const dispatch = useDispatch()
    const handleDrawerToggle = () => {
        isWidthDown('xs', width) ? setMobileOpen(!mobileOpen) : setOpen(!open)
    }

    useEffect(() => {
        dispatch(fetchCodeListTringa())
    }, [dispatch])

    return (
        <React.Fragment>
            <nav className={classes.drawer}>
                <DrawerNav
                    classes={classes}
                    handleDrawerToggle={handleDrawerToggle}
                    mobileOpen={mobileOpen}
                    open={open}
                    match={match}
                />
            </nav>

            <div
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}>
                <DashboardRoutes match={match} />
            </div>
        </React.Fragment>
    )
}

export default connect((state) => ({
    loadingAutoLogin: autoLoginFetchSelector(state),
}))(withWidth()(Dashboard))
