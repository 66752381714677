import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {applyMiddleware, compose, createStore} from 'redux'
import {Provider} from 'react-redux'
import {reducers} from './reducers'
import reduxThunk from 'redux-thunk'
import {routerMiddleware} from 'connected-react-router'
import {createBrowserHistory} from 'history'
import 'react-toastify/dist/ReactToastify.css'
import './css/index.css'
import {MuiThemeProvider} from '@material-ui/core'
import {THEME} from './assets/theme'

export const history = createBrowserHistory()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers(history), composeEnhancers(applyMiddleware(reduxThunk, routerMiddleware(history))))

ReactDOM.render(
    <MuiThemeProvider theme={THEME}>
        <Provider store={store}>
            <App />
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
