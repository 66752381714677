import {createSelector} from 'reselect'
import fetchConstants from '../../../constants/fetchConstants'

export const fetchRequestsSelector = (state) => state.fetch.requests

export const createUserFetchSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.USER_CREATE]
)

export const loadingUserCreateSelector = createSelector(createUserFetchSelector, (state) => state.loading)
