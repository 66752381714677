import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import {createAction} from 'redux-actions'
import propagateConstants from '../../constants/propagateConstants'
import {initialize} from 'redux-form'
import {closeReduxModal} from '../modal'
import {propagateOffersSelector} from '../../selectors/propagateOffers'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import {toastSuccess} from '../../components/Toast/ToastSuccess'
import formNames from '../../constants/form/formNames'
import modalNames from '../../constants/modal/modalNames'

export const initPropagateModalForm = (offer) => (dispatch) =>
    dispatch(initialize(formNames.PROPAGATE_OFFERS_FORM, offer))

const fetchSuccess = createAction(propagateConstants.PROPAGATE_OFFERS_ALL_SUCCESS)

export const fetchAllPropagateOffers = () => (dispatch, getState) => {
    const {baseUrl, version, propagateOffers} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.PROPAGATE_OFFERS_ALL))
    Axios.get(`${baseUrl}${version}${propagateOffers}/all`, config)
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.PROPAGATE_OFFERS_ALL}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.PROPAGATE_OFFERS_ALL))
}

const updateSuccess = createAction(propagateConstants.PROPAGATE_UPDATE_SUCCESS)

export const updatePropagateOffers = (offer) => (dispatch, getState) => {
    const {baseUrl, version, propagateOffers} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.UPDATE_PROPAGATE_OFFER))
    Axios.put(`${baseUrl}${version}${propagateOffers}`, offer, config)
        .then(({data}) => {
            dispatch(updateSuccess(data))
            dispatch(setNull({fetch: fetchConstants.UPDATE_PROPAGATE_OFFER}))
            dispatch(closeReduxModal(modalNames.PROPAGATE_OFFER_MODAL))
            toastSuccess('Nabídka byla aktualizována')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.UPDATE_PROPAGATE_OFFER))
}

const deleteSuccess = createAction(propagateConstants.DELETE_PROPAGATE_OFFER)

export const deletePropagateOffers = (id) => (dispatch, getState) => {
    const state = getState()
    const offerIndex = propagateOffersSelector(state).findIndex((offer) => offer.id === id)
    const offersAfterDeletedItem = propagateOffersSelector(state).slice(offerIndex + 1)
    offersAfterDeletedItem.map((offer) => {
        const loadedOffer = offer
        loadedOffer.location = offer.location - 1
        return loadedOffer
    })

    const {baseUrl, version, propagateOffers} = APIroutes
    const config = getAuthHeaders(state)
    dispatch(setLoading(fetchConstants.DELETE_PROPAGATE))
    Axios.delete(`${baseUrl}${version}${propagateOffers}/${id}`, config)
        .then(({data}) => {
            dispatch(deleteSuccess(id))
            dispatch(setNull({fetch: fetchConstants.DELETE_PROPAGATE}))
            dispatch(saveNewLocationsIndBD(offersAfterDeletedItem))
            toastSuccess('Nabídka byla odstraněna')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.DELETE_PROPAGATE))
}

const createSuccess = createAction(propagateConstants.CREATE_PROPAGATE_OFFER)

export const createPropagateOffers = (offer) => (dispatch, getState) => {
    if (!offer.active) {
        offer.active = false
    }
    const propagates = propagateOffersSelector(getState())
    const createOffer = {
        ...offer,
        location: propagates.length + 1,
    }
    const {baseUrl, version, propagateOffers} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.CREATE_PROPAGATE))
    Axios.post(`${baseUrl}${version}${propagateOffers}`, createOffer, config)
        .then(({data}) => {
            dispatch(createSuccess(data))
            dispatch(setNull({fetch: fetchConstants.CREATE_PROPAGATE}))
            dispatch(closeReduxModal(modalNames.PROPAGATE_OFFER_MODAL))
            toastSuccess('Nabídka byla vytvořena')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.CREATE_PROPAGATE))
}

const calculateChanges = (state) => {
    const propagates = propagateOffersSelector(state)
    return propagates.map((offer, index) => {
        const updateOffer = offer
        updateOffer.location = index + 1
        return updateOffer
    })
}
export const saveNewLocationsIndBD = (changes) => (dispatch, getState) => {
    const {baseUrl, version, propagateOffers} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.CHANGE_LOCATIONS))
    Axios.post(
        `${baseUrl}${version}${propagateOffers}/change-positions`,
        {
            changes,
        },
        config
    )
        .then(({data}) => {
            dispatch(setNull({fetch: fetchConstants.CHANGE_LOCATIONS}))
            dispatch(setNullLocationChanged())
            toastSuccess('Pořadí bylo změněno')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.CHANGE_LOCATIONS))
}
export const saveNewLocations = () => (dispatch, getState) => {
    const state = getState()
    const changes = calculateChanges(state)
    const {baseUrl, version, propagateOffers} = APIroutes
    const config = getAuthHeaders(state)
    dispatch(setLoading(fetchConstants.CHANGE_LOCATIONS))
    Axios.post(
        `${baseUrl}${version}${propagateOffers}/change-positions`,
        {
            changes,
        },
        config
    )
        .then(({data}) => {
            dispatch(setNull({fetch: fetchConstants.CHANGE_LOCATIONS}))
            dispatch(setNullLocationChanged())
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.CHANGE_LOCATIONS))
}

export const preOrderPropagateOffers = createAction(propagateConstants.PRE_ORDER_ARRAY)

export const locationWasChanged = createAction(propagateConstants.LOCATION_CHANGED)

export const setNullLocationChanged = createAction(propagateConstants.SET_NULL_LOCATION_CHANGED)
