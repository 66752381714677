import React from 'react'
import {Grid} from '@material-ui/core'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {renderCheckbox, renderTextField, TextFileInput} from '../../UserFormDialog/TextField'
import {cFieldNames, initialValues, validate} from '../../../constants/form/specialOfferFormSchema'
import formNames from '../../../constants/form/formNames'
import {useSelector} from 'react-redux'

const selector = formValueSelector(formNames.SPECIAL_OFFERS_FORM)

const SpecialOfferForm = ({handleSubmit, setWithNewImage}) => {
    const editImage = useSelector((state) => selector(state, 'imageUrl'))

    return (
        <form onSubmit={handleSubmit} onKeyDown={(e) => e.keyCode === 13 && handleSubmit()}>
            <Grid container spacing={1} justify="center">
                <Grid item md={3} xs={12} container justify="center" alignItems="center">
                    Titulek
                </Grid>
                <Grid item xs={12} md={9}>
                    <Field name={cFieldNames.title} component={renderTextField} />
                </Grid>
                <Grid item md={3} xs={12} container justify="center" alignItems="center">
                    Popisek
                </Grid>
                <Grid item xs={12} md={9}>
                    <Field name={cFieldNames.description} component={renderTextField} multiline rows={2} />
                </Grid>
                <Grid item md={3} xs={12} container justify="center" alignItems="flex-start">
                    Filtr
                </Grid>
                <Grid item xs={12} md={9}>
                    <Field name={cFieldNames.filter} component={renderTextField} multiline rows={5} />
                </Grid>
                <Grid item md={3} xs={12} container justify="center" alignItems="center">
                    Aktivní
                </Grid>
                <Grid item xs={12} md={9}>
                    <Field
                        name={cFieldNames.active}
                        color={'primary'}
                        component={renderCheckbox}
                        defaultValue={false}
                        defaultChecked={false}
                    />
                </Grid>
                <Grid item md={3} xs={12} container justify="center" alignItems="center">
                    Obrázek
                </Grid>
                <Grid item xs={12} md={9}>
                    <Field
                        name={cFieldNames.files}
                        editImage={editImage}
                        withNewImage={setWithNewImage}
                        component={TextFileInput}
                        type="file"
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export default reduxForm({
    form: formNames.SPECIAL_OFFERS_FORM,
    initialValues,
    validate: validate,
})(SpecialOfferForm)
