import {createSelector} from 'reselect'
import fetchConstants from '../../constants/fetchConstants'
import {keys} from 'ramda'
import {orderPeopleCountSelector} from '../fetches/ordersFetch'
import {isNotEmpty, isNotNil} from 'ramda-extension'

export const codeListSelector = (state) => state.codeList

export const codeListRoomsSelector = createSelector(codeListSelector, (codeList) => codeList.rooms)

const getRoomTypeFromProps = (state, props) => props.orderDetail.room

export const roomDescriptionSelector = createSelector(
    [codeListRoomsSelector, orderPeopleCountSelector, getRoomTypeFromProps],
    (rooms, peopleCount, room) => {
        if (isNotEmpty(rooms) && rooms && peopleCount) {
            return rooms[room].personCountToDescription[peopleCount]
        }
    }
)
export const codeListFoodsKeys = createSelector(codeListSelector, (codeList) => {
    if (isNotNil(codeList.foods)) {
        return keys(codeList.foods)
            .map((key) => codeList.foods[key])
            .reduce((accumulator, currentValue) => [...accumulator, ...currentValue.foods], [])
    }
})
const getProps = (state, props) => props.orderDetail.food

export const getFoodTypeSelector = createSelector([codeListFoodsKeys, getProps], (foods, foodId) => {
    if (isNotEmpty(foods) && foods) {
        return foods.find((food) => food.externalId === foodId)
    }
})

export const fetchRequestsSelector = (state) => state.fetch.requests

export const codeListLoadingSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.CODELIST_FETCH].loading
)
