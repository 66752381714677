import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {useDispatch, useSelector} from 'react-redux'
import {closeReduxModal} from '../../../actions/modal'
import modalNames from '../../../constants/modal/modalNames'
import SaveIcon from '@material-ui/icons/Save'
import SpecialOfferForm from './Form'
import {modalOpenSelector} from '../../../selectors/modals'
import {offersSelector} from '../../../selectors/offers'
import {updateNoImageChange, updateWithNewImage, uploadNewOffer} from '../../../actions/offers'
import {destroy, SubmissionError, submit, touch} from 'redux-form'
import formNames from '../../../constants/form/formNames'
import {cFieldNames} from '../../../constants/form/specialOfferFormSchema'
import {isNilOrEmpty} from 'ramda-extension'

const SpecialOffersModal = () => {
    const [withNewImage, setWithNewImage] = useState(false)

    const offers = useSelector(offersSelector)

    const modalName = modalNames.SPECIAL_OFFERS_MODAL

    const open = useSelector(modalOpenSelector(modalName))

    const dispatch = useDispatch()

    const closeModal = () => dispatch(closeReduxModal(modalName))

    const manualFileValidation = () => {
        throw new SubmissionError({
            files: 'Pole je povinné',
        })
    }

    useEffect(() => {
        return () => {
            setWithNewImage(false)
            dispatch(destroy(formNames.SPECIAL_OFFERS_FORM))
        }
    }, [dispatch])

    useEffect(() => {
        setWithNewImage(false)
    }, [open])

    const submitForm = (values) => {
        if (values.id) {
            if (withNewImage) {
                dispatch(updateWithNewImage(fillFormData(values), values.id))
            } else {
                dispatch(updateNoImageChange(values))
            }
        } else {
            if (isNilOrEmpty(values[cFieldNames.files])) {
                dispatch(touch(formNames.SPECIAL_OFFERS_FORM, cFieldNames.files))
                manualFileValidation()
            } else {
                dispatch(uploadNewOffer(fillFormData(values)))
            }
        }
    }

    const fillFormData = (values) => {
        const formData = new FormData()
        formData.append('files', values.files)
        formData.append('title', values.title)
        formData.append('description', values.description)
        formData.append('location', offers.length)
        formData.append('filter', values.filter)
        formData.append('active', values.active)
        return formData
    }

    const remoteSubmitForm = () => {
        dispatch(submit(formNames.SPECIAL_OFFERS_FORM))
    }

    return (
        <Dialog open={open} onEscapeKeyDown={closeModal} onClose={closeModal}>
            <DialogTitle id="form-dialog-title">Speciální nabídky</DialogTitle>
            <DialogContent>
                <SpecialOfferForm onSubmit={submitForm} setWithNewImage={setWithNewImage} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={closeModal}>
                    Zavřít
                </Button>
                <Button
                    variant={'outlined'}
                    color="primary"
                    size="small"
                    onClick={remoteSubmitForm}
                    startIcon={<SaveIcon />}>
                    Uložit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default SpecialOffersModal
