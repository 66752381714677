import {makeStyles} from '@material-ui/core/styles'

export const usePadding = makeStyles((theme) => ({
    p1rem: {
        padding: '1rem',
    },
    p1t: {
        paddingTop: '1rem',
    },
    p10px: {
        padding: '10px',
    },
    py16px: {
        padding: '16px 0px',
    },
    p16px: {
        padding: '16px',
    },
    MUIListHeading: {
        padding: '16px 48px 16px 16px',
    },
    pl10px: {
        paddingLeft: '10px',
    },
    plr8px: {
        padding: '0px 8px',
    },
    pb30px: {
        paddingBottom: '30px',
    },
}))
