import React from 'react'
import {Container, Grid, Paper} from '@material-ui/core'
import {useDispatch} from 'react-redux'
import {sendNotification} from '../../actions/notification'
import {Field, reduxForm} from 'redux-form'
import {renderTextField} from '../UserFormDialog/TextField'
import PageWrapper from '../PageWrapper'
import {PAGES} from '../../utils/routes'
import {usePadding} from '../../css/usePadding'
import Buttons from '../Buttons/Buttons'

const Notification = ({handleSubmit}) => {
    const {p1rem} = usePadding()

    const dispatch = useDispatch()

    const submit = ({deviceId, title, body}) => {
        dispatch(
            sendNotification({
                deviceId,
                title,
                body,
            })
        )
    }

    return (
        <PageWrapper headingFor={PAGES.NOTIFICATIONS}>
            <Container maxWidth="sm">
                <Paper className={p1rem}>
                    <form onSubmit={handleSubmit(submit)}>
                        <Grid container direction="column" justify="center" spacing={2}>
                            <Grid item sm={12}>
                                <Field
                                    component={renderTextField}
                                    variant="outlined"
                                    name="deviceId"
                                    label="ID zařízení"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Field
                                    component={renderTextField}
                                    variant="outlined"
                                    name="title"
                                    label="Titulek"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Field
                                    component={renderTextField}
                                    variant="outlined"
                                    label="Popisek"
                                    name="body"
                                    multiline
                                    rows={6}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container sm={12} justify="flex-end">
                                <Buttons type={'submit'}>Odeslat</Buttons>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </PageWrapper>
    )
}

export default reduxForm({
    form: 'notification-form',
})(Notification)
