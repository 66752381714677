import {createMuiTheme} from '@material-ui/core'

export const THEME = createMuiTheme({
    typography: {
        //"fontFamily": `'Montserrat', sans-serif`,
    },
    palette: {
        success: {
            main: '#2aab00',
        },
    },
})
