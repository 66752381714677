export const cFieldNames = {
    name: 'name',
    filter: 'filter',
    active: 'active',
}

export const initialValues = {
    [cFieldNames.name]: '',
    [cFieldNames.filter]: '{ }',
    [cFieldNames.active]: false,
}

export const validate = (values) => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Povinné pole'
    }
    if (!values.filter) {
        errors.filter = 'Povinné pole'
    }
    try {
        JSON.parse(values.filter)
    } catch (e) {
        errors.filter = 'Špatný formát JSON'
    }
    return errors
}
