import React, {useEffect, useState} from 'react'
import PageWrapper from '../PageWrapper'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import {useDispatch} from 'react-redux'
import {fetchForPaymentLogs} from '../../actions/fioPayments'
import {isNilOrEmpty, isNotNil} from 'ramda-extension'
import {not} from 'ramda'
import toFormatDate from '../../utils/toFormatDate'
import {Typography} from '@material-ui/core'
import {useHistory} from 'react-router'
import TablePagination from '@material-ui/core/TablePagination'
import {useStyles} from '../../css/useStyles'

const FioPaymentLogs = () => {
    const [logs, setLogs] = useState({})

    const [rowsPerPage, setRowsPerPage] = useState(5)

    const {pointer, tableHeadGray} = useStyles()

    const history = useHistory()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchForPaymentLogs(0, rowsPerPage, (data) => setLogs(data)))
        //eslint-disable-next-line
    }, [dispatch])

    const handleChangePage = (event, newPage) => {
        dispatch(fetchForPaymentLogs(newPage, rowsPerPage, (data) => setLogs(data)))
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        dispatch(fetchForPaymentLogs(0, parseInt(event.target.value, 10), (data) => setLogs(data)))
    }
    return (
        <PageWrapper heading={'Platební logy'}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead className={tableHeadGray}>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="left">Popisek</TableCell>
                            <TableCell align="left">Uživatel</TableCell>
                            <TableCell align="left">Datum</TableCell>
                            <TableCell align="left">Platba</TableCell>
                            <TableCell align="left">Objednávka</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {not(isNilOrEmpty(logs.content)) &&
                            logs.content.map(({id, order, dateCreated, description, user, payment}) => (
                                <TableRow key={id}>
                                    <TableCell scope="row">{id}</TableCell>
                                    <TableCell align="left">{description}</TableCell>
                                    <TableCell align="left">
                                        {isNotNil(user) && `${user.firstName} ${user.lastName}`}
                                    </TableCell>
                                    <TableCell align="left">{toFormatDate(dateCreated)}</TableCell>
                                    <TableCell align="left">{payment.id}</TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            className={pointer}
                                            color={'primary'}
                                            onClick={() => history.push(`/order/${order.id}`)}>
                                            {order.id}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={logs.totalElements}
                    rowsPerPage={rowsPerPage}
                    page={logs.number}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer>
        </PageWrapper>
    )
}
export default FioPaymentLogs
