import Axios from 'axios'
import {createAction} from 'redux-actions'
import userConstants from '../../constants/userConstants'
import {APIroutes} from '../../constants/routes'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import {setLoading, setNull} from '../fetch'
import fetchConstants from '../../constants/fetchConstants'
import {toastSuccess} from '../../components/Toast/ToastSuccess'
import {loadAllUsrs} from './loadAllUsers'

const setDeletingUserID = createAction(userConstants.SET_DELETING_USER)
const nullDeletingUserID = createAction(userConstants.NULL_DELETING_USER)
export const deleteUser = (id) => (dispatch, getState) => {
    const {
        users: {size, number},
    } = getState()
    const {baseUrl, version, userDelete} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setDeletingUserID(id))
    dispatch(setLoading(fetchConstants.USER_DELETE))
    Axios.delete(`${baseUrl}${version}${userDelete}${id}`, config)
        .then(({data}) => {
            dispatch(loadAllUsrs(size, number))
            dispatch(nullDeletingUserID())
            dispatch(setNull({fetch: fetchConstants.USER_DELETE}))
            toastSuccess('Uživatel odstraněn')
        })
        .catch((e) => {
            dispatch(nullDeletingUserID())
        })
}
