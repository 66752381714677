import userConstants from '../constants/userConstants'
import {handleActions} from 'redux-actions'
import flightConstants from '../constants/flightConstants'
import destinationTemperaturesConstants from '../constants/destinationTemperaturesConstants'
import propagateConstants from '../constants/propagateConstants'
import errorModalConstants from '../constants/errorModalConstants'
import inspirationsConstants from '../constants/inspirationsConstants'
import launchScreenConstants from '../constants/launchScreenConstants'
import offersConstants from '../constants/offersConstants'
import fioPaymentsConstants from '../constants/fioPaymentsConstants'
import modalNames, {CLOSE_MODAL, OPEN_MODAL} from '../constants/modal/modalNames'

let modals = {}

Object.keys(modalNames).forEach((v) => {
    modals[v] = false
})

const initialState = {
    createUser: {
        modalOpen: false,
    },
    modals,
    editUser: {
        modalOpen: false,
    },
    propagateOffersModal: {
        modalOpen: false,
    },
    fioAccountModal: {
        modalOpen: false,
    },
    propagateLocationsWasChanged: false,
    deleteUser: {
        id: null,
    },
    flightTable: {
        addingNewFlightCompany: false,
    },
    propagateOffers: {
        editting: false,
    },
    inspirationModal: {
        modalOpen: false,
    },
    inspirationsChanges: false,
    specialOffersChanges: false,
    destinationTemperatureModalOpen: false,
    errorModal: {
        modalOpen: false,
        fetch: '',
        message: '',
    },
    launchScreen: {
        form: false,
    },
    specialOffers: {
        openForm: false,
    },
}
export default handleActions(
    {
        [userConstants.CREATE_USER_MODAL_OPEN]: (state, {payload}) => ({
            ...state,
            createUser: {
                modalOpen: true,
            },
        }),
        [userConstants.CREATE_USER_MODAL_CLOSE]: (state, {payload}) => ({
            ...state,
            createUser: {
                modalOpen: false,
            },
        }),
        [userConstants.EDIT_USER_MODAL_OPEN]: (state, {payload}) => ({
            ...state,
            editUser: {
                modalOpen: true,
            },
        }),
        [userConstants.EDIT_USER_MODAL_CLOSE]: (state, {payload}) => ({
            ...state,
            editUser: {
                modalOpen: false,
            },
        }),
        [userConstants.SET_DELETING_USER]: (state, {payload}) => ({
            ...state,
            deleteUser: {
                id: payload,
            },
        }),
        [userConstants.NULL_DELETING_USER]: (state, {payload}) => ({
            ...state,
            deleteUser: {
                id: null,
            },
        }),
        [flightConstants.UI_ADDING_NEW_COMPANY]: (state, {payload}) => ({
            ...state,
            flightTable: {
                addingNewFlightCompany: true,
            },
        }),
        [flightConstants.UI_NON_ADDING_COMPANY]: (state, {payload}) => ({
            ...state,
            flightTable: {
                addingNewFlightCompany: false,
            },
        }),
        [propagateConstants.UI_EDITTING]: (state, {payload}) => ({
            ...state,
            propagateOffers: {
                editting: true,
            },
        }),
        [propagateConstants.UI_CLOSE_EDITTING]: (state, {payload}) => ({
            ...state,
            propagateOffers: {
                editting: false,
            },
        }),
        [propagateConstants.OPEN_MODAL_PROPAGATE]: (state, {payload}) => ({
            ...state,
            propagateOffersModal: {
                modalOpen: true,
            },
        }),
        [propagateConstants.CLOSE_MODAL_PROPAGATE]: (state, {payload}) => ({
            ...state,
            propagateOffersModal: {
                modalOpen: false,
            },
        }),
        [propagateConstants.LOCATION_CHANGED]: (state, {payload}) => ({
            ...state,
            propagateLocationsWasChanged: true,
        }),
        [propagateConstants.SET_NULL_LOCATION_CHANGED]: (state, {payload}) => ({
            ...state,
            propagateLocationsWasChanged: false,
        }),
        [destinationTemperaturesConstants.OPEN_MODAL]: (state, {payload}) => ({
            ...state,
            destinationTemperatureModalOpen: true,
        }),
        [destinationTemperaturesConstants.CLOSE_MODAL]: (state, {payload}) => ({
            ...state,
            destinationTemperatureModalOpen: false,
        }),
        [errorModalConstants.OPEN_ERROR_MODAL]: (state, {payload}) => ({
            ...state,
            errorModal: {
                modalOpen: true,
                fetch: payload.fetch,
                message: payload.error.message,
            },
        }),
        [errorModalConstants.CLOSE_ERROR_MODAL]: (state, {payload}) => ({
            ...state,
            errorModal: {
                modalOpen: false,
                errorModal: {
                    modalOpen: false,
                    fetch: '',
                    message: '',
                },
            },
        }),
        [inspirationsConstants.OPEN_INPIRATION_MODAL]: (state, {payload}) => ({
            ...state,
            inspirationModal: {
                modalOpen: true,
            },
        }),
        [inspirationsConstants.CLOSE_INPIRATION_MODAL]: (state, {payload}) => ({
            ...state,
            inspirationModal: {
                modalOpen: false,
            },
        }),
        [inspirationsConstants.SET_ARRAY_LOCATIONS_CHANGE_TRUE]: (state, {payload}) => ({
            ...state,
            inspirationsChanges: true,
        }),
        [inspirationsConstants.SET_ARRAY_LOCATIONS_CHANGE_FALSE]: (state, {payload}) => ({
            ...state,
            inspirationsChanges: false,
        }),
        [launchScreenConstants.LAUNCH_SCREEN_OPEN_FORM]: (state, {payload}) => ({
            ...state,
            launchScreen: {
                form: true,
            },
        }),
        [launchScreenConstants.LAUNCH_SCREEN_CLOSE_FORM]: (state, {payload}) => ({
            ...state,
            launchScreen: {
                form: false,
            },
        }),
        [offersConstants.OPEN_FORM]: (state) => ({
            ...state,
            specialOffers: {
                openForm: true,
            },
        }),
        [offersConstants.CLOSE_FORM]: (state) => ({
            ...state,
            specialOffers: {
                openForm: false,
            },
        }),
        [offersConstants.OFFERS_LOCATION_CHANGED]: (state) => ({
            ...state,
            specialOffersChanges: true,
        }),
        [offersConstants.OFFERS_LOCATION_SAVED]: (state) => ({
            ...state,
            specialOffersChanges: false,
        }),
        [fioPaymentsConstants.OPEN_FIO_ACCOUNT_MODAL]: (state) => ({
            ...state,
            fioAccountModal: {
                modalOpen: true,
            },
        }),
        [fioPaymentsConstants.CLOSE_FIO_ACCOUNT_MODAL]: (state) => ({
            ...state,
            fioAccountModal: {
                modalOpen: false,
            },
        }),
        [OPEN_MODAL]: (state, {payload}) => ({
            ...state,
            modals: {
                ...modals,
                [payload]: true,
            },
        }),
        [CLOSE_MODAL]: (state, {payload}) => ({
            ...state,
            modals: {
                ...modals,
                [payload]: false,
            },
        }),
    },
    initialState
)
