import OfferCard from '../OfferCard'
import React from 'react'
import {SortableElement} from 'react-sortable-hoc'

const SortableItem = SortableElement(({value, deleteOffer, fillToUpdate}) => (
    <div tabIndex={0}>
        <OfferCard offer={value} deleteOffer={deleteOffer} fillToUpdate={fillToUpdate} />
    </div>
))

export default SortableItem
