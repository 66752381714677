import {APIroutes} from '../../constants/routes'
import {setLoading, setNull, setError} from '../fetch'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import {createAction} from 'redux-actions'
import notificationConstants from '../../constants/notification'
import {reset} from 'redux-form'
import {toastError} from '../../components/Toast/ToastError'
import {toastSuccess} from '../../components/Toast/ToastSuccess'

const fetchSuccess = createAction(notificationConstants.SEND_NOTIFICATION_SUCCESS)

export const sendNotification = (data) => (dispatch, getState) => {
    const {baseUrl, version, notification} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.SEND_NOTIFICATION))

    Axios.post(`${baseUrl}${version}${notification}`, data, config)
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.SEND_NOTIFICATION}))
            dispatch(reset('notification-form'))
            toastSuccess('Notifikace byla poslána')
        })
        .catch((e) => {
            dispatch(
                setError({
                    fetch: fetchConstants.SEND_NOTIFICATION,
                    msg: e.message,
                })
            )
            toastError(e.response.data.error)
        })
}
