import {createSelector} from 'reselect'
import fetchConstants from '../../../constants/fetchConstants'

export const fetchRequestsSelector = (state) => state.fetch.requests

export const loginFetchSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.USER_LOGIN]
)

export const loadingLoginSelector = createSelector(loginFetchSelector, (state) => state.loading)

export const errorLoginSelector = createSelector(loginFetchSelector, (state) => state.error)

export const autoLoginFetchSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.USER_AUTO_LOGIN]
)
export const loadingAutoLoginSelector = createSelector(autoLoginFetchSelector, (state) => state.loading)
