import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import {createAction} from 'redux-actions'
import fioPaymentsConstants from '../../constants/fioPaymentsConstants'
import fetchErrorHandler from '../../utils/fetchErrorHandler'

const fetchSuccess = createAction(fioPaymentsConstants.ALL_FIO_PAYMENTS_SUCCESS)

export const openFioAccountModal = createAction(fioPaymentsConstants.OPEN_FIO_ACCOUNT_MODAL)
export const closeFioAccountModal = createAction(fioPaymentsConstants.CLOSE_FIO_ACCOUNT_MODAL)

export const fetchForAllFioPayments = () => (dispatch, getState) => {
    const {baseUrl, version, fioPayments, lastFioPayments} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.ALL_FIO_PAYMENTS))
    Axios.get(`${baseUrl}${version}${fioPayments}${lastFioPayments}`, config)
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.ALL_FIO_PAYMENTS}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.ALL_FIO_PAYMENTS))
}

const fetchSuccessAllWithoutOrder = createAction(fioPaymentsConstants.ALL_PAYMENTS_WITHOUT_ORDER)

export const fetchAllPaymentsWithoutOrder = (pageNumber, pageSize) => (dispatch, getState) => {
    const {baseUrl, version, fioPayments, fioPaymentsWithoutOrder} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.ALL_PAYMENTS_WITHOUT_ORDER))
    Axios.get(`${baseUrl}${version}${fioPayments}${fioPaymentsWithoutOrder}`, {
        ...config,
        params: {
            page: pageNumber,
            size: pageSize,
        },
    })
        .then(({data}) => {
            dispatch(fetchSuccessAllWithoutOrder(data))
            dispatch(setNull({fetch: fetchConstants.ALL_PAYMENTS_WITHOUT_ORDER}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.ALL_PAYMENTS_WITHOUT_ORDER))
}

const fetchSuccessAllWithOrder = createAction(fioPaymentsConstants.ALL_PAYMENTS_WITH_ORDER)

export const fetchAllPaymentsWithOrder = (pageNumber, pageSize) => (dispatch, getState) => {
    const {baseUrl, version, fioPayments, fioPaymentsWithOrder} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.ALL_PAYMENTS_WITH_ORDER_FETCH))
    Axios.get(`${baseUrl}${version}${fioPayments}${fioPaymentsWithOrder}`, {
        ...config,
        params: {
            page: pageNumber,
            size: pageSize,
        },
    })
        .then(({data}) => {
            dispatch(fetchSuccessAllWithOrder(data))
            dispatch(setNull({fetch: fetchConstants.ALL_PAYMENTS_WITH_ORDER_FETCH}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.ALL_PAYMENTS_WITH_ORDER_FETCH))
}

const fetchSuccessBankAccount = createAction(fioPaymentsConstants.ACCOUNT_SUCCESS)

export const fetchForBankAccount = () => (dispatch, getState) => {
    const {baseUrl, version, fioPayments, account} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.ACCOUNT_FETCH))
    Axios.get(`${baseUrl}${version}${fioPayments}${account}`, config)
        .then(({data}) => {
            dispatch(fetchSuccessBankAccount(data))
            dispatch(setNull({fetch: fetchConstants.ACCOUNT_FETCH}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.ACCOUNT_FETCH))
}

export const setPaymentToOrder = (orderID, paymentID, cb) => (dispatch, getState) => {
    const {baseUrl, version, fioPayments} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.SET_PAYMENT_TO_ORDER))
    Axios.post(
        `${baseUrl}${version}${fioPayments}/set-to-order`,
        {},
        {
            ...config,
            params: {
                orderID,
                paymentID,
            },
        }
    )
        .then(({data}) => {
            dispatch(fetchAllPaymentsWithOrder())
            dispatch(fetchAllPaymentsWithoutOrder())
            dispatch(setNull({fetch: fetchConstants.SET_PAYMENT_TO_ORDER}))
            if (typeof cb === 'function') {
                cb()
            }
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.SET_PAYMENT_TO_ORDER))
}

export const fetchForPaymentLogs = (page, size, cb) => (dispatch, getState) => {
    const {baseUrl, version, payment, log} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.ACCOUNT_FETCH))
    Axios.get(`${baseUrl}${version}${payment}${log}`, {
        ...config,
        params: {
            size,
            page,
        },
    })
        .then(({data}) => {
            cb(data)
            dispatch(setNull({fetch: fetchConstants.ACCOUNT_FETCH}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.ACCOUNT_FETCH))
}
