import offersConstants from '../../constants/offersConstants'
import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import {createAction} from 'redux-actions'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import {toastSuccess} from '../../components/Toast/ToastSuccess'
import {closeReduxModal} from '../modal'
import modalNames from '../../constants/modal/modalNames'
import {offersSelector} from '../../selectors/offers'

export const setChangesInArray = createAction(offersConstants.OFFERS_LOCATION_CHANGED)
export const setChangesInArraySaved = createAction(offersConstants.OFFERS_LOCATION_SAVED)

const fetchSuccess = createAction(offersConstants.FETCH_OFFERS_SUCCESS)

export const fetchAllOffers = () => (dispatch, getState) => {
    const {baseUrl, version, offers} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.OFFERS_ALL))
    Axios.get(`${baseUrl}${version}${offers}`, config)
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.OFFERS_ALL}))
            dispatch(closeReduxModal(modalNames.SPECIAL_OFFERS_MODAL))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.OFFERS_ALL))
}

const fetchDeleteSuccess = createAction(offersConstants.FETCH_OFFER_DELETE_SUCCESS)
export const deleteOffer = (id) => (dispatch, getState) => {
    const {baseUrl, version, offers} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.OFFER_DELETE))
    Axios.delete(`${baseUrl}${version}${offers}/${id}`, config)
        .then(({data}) => {
            dispatch(fetchDeleteSuccess(data))
            dispatch(setNull({fetch: fetchConstants.OFFER_DELETE}))
            toastSuccess('Úspěšně odstraněno')
            dispatch(closeReduxModal(modalNames.SPECIAL_OFFERS_MODAL))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.OFFER_DELETE))
}

const fetchChangesSuccess = createAction(offersConstants.CHANGE_OFFERS_POSIITONS_SUCCESS)
export const changeOrderInDB = () => (dispatch, getState) => {
    const {baseUrl, version, offers, changePositions} = APIroutes
    const offersArray = offersSelector(getState())
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.OFFERS_CHANGE_POSITIONS))
    Axios.post(`${baseUrl}${version}${offers}${changePositions}`, {changes: offersArray}, config)
        .then(({data}) => {
            dispatch(fetchChangesSuccess(data))
            dispatch(setNull({fetch: fetchConstants.OFFERS_CHANGE_POSITIONS}))
            dispatch(setChangesInArraySaved())
            toastSuccess('Úspěšně seřazeno')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.OFFERS_CHANGE_POSITIONS))
}

export const afterCreateOffer = createAction(offersConstants.AFTER_CREATE_OFFER)

export const uploadNewOffer = (formData) => (dispatch, getState) => {
    const {baseUrl, version, offers, offerUpload} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.UPLOAD_OFFERS))
    Axios.post(`${baseUrl}${version}${offers}${offerUpload}`, formData, config)
        .then(({data}) => {
            dispatch(afterCreateOffer(data))
            dispatch(setNull({fetch: fetchConstants.UPLOAD_OFFERS}))
            toastSuccess('Úspěšně vytvořeno')
            dispatch(closeReduxModal(modalNames.SPECIAL_OFFERS_MODAL))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.UPLOAD_OFFERS))
}
export const reOrderOffers = createAction(offersConstants.RE_ORDER_OFFERS)

const fetchUpdateNoImagesSuccess = createAction(offersConstants.UPDATE_WITHOUT_PICTURE_SUCCESS)
export const updateNoImageChange = (offer) => (dispatch, getState) => {
    const {baseUrl, version, offers, updateWithoutImage} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.UPDATE_WITHOUT_PICTURE))
    Axios.put(`${baseUrl}${version}${offers}${updateWithoutImage}/${offer.id}`, offer, config)
        .then(({data}) => {
            dispatch(fetchUpdateNoImagesSuccess(data))
            dispatch(setNull({fetch: fetchConstants.UPDATE_WITHOUT_PICTURE}))
            toastSuccess('Úspěšně aktualizováno')
            dispatch(closeReduxModal(modalNames.SPECIAL_OFFERS_MODAL))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.UPDATE_WITHOUT_PICTURE))
}

const fetchUpdateithImageSuccess = createAction(offersConstants.UPDATE_WITH_PICTURE_SUCCESS)
export const updateWithNewImage = (formData, id) => (dispatch, getState) => {
    const {baseUrl, version, offers, offerUpdate} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.UPDATE_WITH_PICTURE))
    Axios.put(`${baseUrl}${version}${offers}${offerUpdate}/${id}`, formData, config)
        .then(({data}) => {
            dispatch(fetchUpdateithImageSuccess(data))
            dispatch(setNull({fetch: fetchConstants.UPDATE_WITH_PICTURE}))
            toastSuccess('Úspěšně aktualizováno')
            dispatch(closeReduxModal(modalNames.SPECIAL_OFFERS_MODAL))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.UPDATE_WITH_PICTURE))
}
