import {keyMirror} from 'ramda-extension'

export default keyMirror({
    FLIGHT_COMPANIES_ALL_SUCCESS: null,
    UI_ADDING_NEW_COMPANY: null,
    UI_NON_ADDING_COMPANY: null,
    FLIGHT_COMPANIES_CREATE_SUCCESS: null,
    FLIGHT_COMPANIES_DELETE_SUCCESS: null,
    FLIGHT_COMPANIES_UPDATE_SUCCESS: null,
})
