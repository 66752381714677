import {isEmpty, isNil, keys} from 'ramda'

export const cFieldNames = {
    title: 'title',
    description: 'description',
    filter: 'filter',
    active: 'active',
    files: 'files',
}

const {files, ...restNames} = cFieldNames

export const initialValues = {
    [cFieldNames.title]: '',
    [cFieldNames.filter]: '{ }',
    [cFieldNames.active]: false,
    [cFieldNames.description]: '',
    [cFieldNames.files]: {},
}

export const validate = (values) => {
    const errors = {}
    keys(restNames).forEach((k) => (isEmpty(values[k]) || isNil(values[k]) ? (errors[k] = 'Povinné pole') : ''))
    try {
        JSON.parse(values[cFieldNames.filter])
    } catch (e) {
        errors[cFieldNames.filter] = 'Špatný formát JSON'
    }
    return errors
}
