export const months = {
    1: 'Leden',
    2: 'Unor',
    3: 'Brezen',
    4: 'Duben',
    5: 'Kveten',
    6: 'Cerven',
    7: 'Cervenec',
    8: 'Srpen',
    9: 'Zari',
    10: 'Rijen',
    11: 'Listopad',
    12: 'Prosinec',
}
