import {SortableContainer} from 'react-sortable-hoc'
import React from 'react'
import SortableItem from './SortableItem'
import {Card, Grid, ListItem, ListItemSecondaryAction} from '@material-ui/core'
import DragHandle from '../PropagateOffers/DragHandle'
import {usePadding} from '../../css/usePadding'

const SortableList = SortableContainer(({items, deleteOffer, fillToUpdate}) => {
    const {MUIListHeading} = usePadding()
    return (
        <Grid container justify="center" direction="column">
            <Card square>
                <ListItem ContainerComponent="div" className={MUIListHeading}>
                    <DragHandle unvisible={true} />
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item md={1}>
                            ID
                        </Grid>
                        <Grid item md={1}>
                            Náhled
                        </Grid>
                        <Grid item md={3}>
                            Titulek
                        </Grid>
                        <Grid item md={5}>
                            Popisek
                        </Grid>
                        <Grid item md={1}>
                            Aktivní
                        </Grid>
                    </Grid>
                    <ListItemSecondaryAction>{''}</ListItemSecondaryAction>
                </ListItem>
            </Card>
            {items.map((value, index) => (
                <SortableItem
                    key={`item-${value.id}`}
                    index={index}
                    value={value}
                    deleteOffer={deleteOffer}
                    fillToUpdate={fillToUpdate}
                    distance={100}
                />
            ))}
        </Grid>
    )
})

export default SortableList
