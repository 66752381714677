import {createSelector} from 'reselect'
import {pathOr, propOr} from 'ramda'

export const uiSelector = (state) => state.ui

export const openCreateUserModalSelector = createSelector(uiSelector, (ui) => ui.createUser.modalOpen)

export const openEditUserModalSelector = createSelector(uiSelector, (ui) => ui.editUser.modalOpen)

export const destinationTemperatureModalOpen = createSelector(uiSelector, (ui) => ui.destinationTemperatureModalOpen)

export const openPorpagateModalSelector = createSelector(uiSelector, (ui) => ui.propagateOffersModal.modalOpen)

export const errorModalSelector = createSelector(uiSelector, (ui) => ui.errorModal)

export const errorModalOpen = createSelector(uiSelector, (ui) => ui.errorModal.modalOpen)

export const inspirationModalOpen = createSelector(uiSelector, (ui) => ui.inspirationModal.modalOpen)

export const modalOpenSelector = (id) => (state) => propOr(false, id, pathOr([], ['ui', 'modals'], state))
