import {createSelector} from 'reselect'
import fetchConstants from '../../../constants/fetchConstants'

export const fetchRequestsSelector = (state) => state.fetch.requests

export const editUserFetchSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.USER_EDIT]
)

export const loadingUserEditSelector = createSelector(editUserFetchSelector, (state) => state.loading)
