import {combineReducers} from 'redux'
import userReducer from './userReducer'
import {connectRouter} from 'connected-react-router'
import uiReducer from './uiReducer'
import allUsersReducer from './allUsersReducer'
import {reducer as formReducer} from 'redux-form'
import fetchReducer from './fetchReducer'
import ordersReducer from './ordersReducer'
import orderReducer from './orderReducer'
import offersReducer from './offersReducer'
import codeListReducer from './codeListReducer'
import flightCompaniesReducer from './flightCompaniesReducer'
import propagateOffersReducer from './propagateOffersReducer'
import destinationsTemperaturesReducer from './destinationTemperaturesReducer'
import inspirationReducer from './inspirationReducer'
import launchScreenReducer from './launchScreenReducer'
import fioPaymentsReducer from './fioPaymentsReducer'

export const reducers = (history) =>
    combineReducers({
        user: userReducer,
        router: connectRouter(history),
        ui: uiReducer,
        form: formReducer,
        users: allUsersReducer,
        fetch: fetchReducer,
        orders: ordersReducer,
        order: orderReducer,
        offers: offersReducer,
        codeList: codeListReducer,
        flightCompanies: flightCompaniesReducer,
        destinationsTemperatures: destinationsTemperaturesReducer,
        propagateOffers: propagateOffersReducer,
        inspirations: inspirationReducer,
        launchScreen: launchScreenReducer,
        fioPayments: fioPaymentsReducer,
    })
