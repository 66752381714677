import {Paper} from '@material-ui/core'
import React from 'react'
import {usePadding} from '../../css/usePadding'

const SwissNotes = ({order: {orderNote}}) => {
    const {p16px} = usePadding()
    return <Paper className={p16px}>{orderNote}</Paper>
}

export default SwissNotes
