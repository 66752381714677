import React from 'react'
import {SortableHandle} from 'react-sortable-hoc'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import {ListItemIcon} from '@material-ui/core'

const DragHandle = SortableHandle(({unvisible}) => (
    <ListItemIcon style={{visible: unvisible ? 'hidden' : 'visible'}} className={'grabbing'}>
        {unvisible}
        <DragHandleIcon style={{visibility: unvisible ? 'hidden' : 'visible'}} />
    </ListItemIcon>
))
export default DragHandle
