import React from 'react'
import clsx from 'clsx'
import {lighten, makeStyles} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import SearchIcon from '@material-ui/icons/Search'
import {Grid, TextField} from '@material-ui/core'
import PropTypes from 'prop-types'

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: '1 1 100%',
    },
}))

const TableOrdersToolbar = ({numSelected, search, setSearch}) => {
    const classes = useToolbarStyles()
    return (
        <div>
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}>
                <Grid container spacing={1} alignItems="flex-end" justify="flex-start">
                    <Grid item>
                        <SearchIcon />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="input-with-icon-grid"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            label="Hledej..."
                        />
                    </Grid>
                </Grid>
            </Toolbar>
        </div>
    )
}
TableOrdersToolbar.propTypes = {
    search: PropTypes.string,
    setSearch: PropTypes.func,
}
export default TableOrdersToolbar
