import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {findOrderByString} from '../../actions/orders'
import {useDispatch} from 'react-redux'
import {propOr} from 'ramda'
import {Grid} from '@material-ui/core'

const OrderAutocomplete = ({changeSelectedOrder}) => {
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (!open) {
            setOptions([])
        }
    }, [open])

    const findByString = (findString) => {
        dispatch(
            findOrderByString(findString, (data) => {
                setOptions(data)
            })
        )
    }

    return (
        <Autocomplete
            id="asynchronous-demo"
            style={{width: 300}}
            open={open}
            onOpen={() => {
                setOpen(true)
            }}
            onClose={() => {
                setOpen(false)
            }}
            filterOptions={(x) => x}
            autoComplete
            includeInputInList
            filterSelectedOptions
            onChange={(value, newValue) => {
                changeSelectedOrder(newValue)
            }}
            getOptionLabel={(option) =>
                typeof option === 'string'
                    ? option
                    : `${propOr('', 'externalId', option)} ${propOr('', 'firstName', option)} ${propOr(
                          '',
                          'lastName',
                          option
                      )}`
            }
            renderOption={(option) => (
                <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                    <Grid item alignItems={'center'} xs={4}>
                        {`ID: ${propOr('', 'externalId', option)}`}
                    </Grid>
                    <Grid item alignItems={'center'} xs={7}>
                        {`${propOr('', 'firstName', option)} ${propOr('', 'lastName', option)}`}
                    </Grid>
                </Grid>
            )}
            options={options}
            onInputChange={(e, newValue) => findByString(newValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Vyhledejte objednávku..."
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                    }}
                />
            )}
        />
    )
}
export default OrderAutocomplete
