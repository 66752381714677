import React, {useState} from 'react'
import {Dialog} from '@material-ui/core'
import {connect} from 'react-redux'
import {closeEditUserModal} from '../../actions/modal'
import {reduxForm, reset} from 'redux-form'
import {validate} from './TextField'
import Form from './Form'
import {updateUser} from '../../actions/users/updateUser'
import {loadingUserEditSelector} from '../../selectors/fetches/editUserFetch'
import {loadingInitUsersSelector} from '../../selectors/fetches/initUserFetch'
import {openEditUserModalSelector} from '../../selectors/modals'
import PropTypes from 'prop-types'

const titleSize = 5
const fieldSize = 7

const UserEditFormDialog = ({
    edit,
    open,
    dispatch,
    reset,
    loading,
    handleSubmit,
    updateUser,
    closeEditUserModal,
    initLoading,
}) => {
    const [password, setPassword] = useState(false)

    const submit = (values) => {
        const {id, ...other} = values
        updateUser(other, id)
    }

    return (
        <Dialog
            open={open}
            onBackdropClick={() => {
                reset('editUser')
                closeEditUserModal()
            }}>
            <Form
                formName="editUser"
                edit={true}
                titleSize={titleSize}
                fieldSize={fieldSize}
                loading={loading}
                dispatch={dispatch}
                submit={submit}
                password={password}
                setPassword={setPassword}
                close={closeEditUserModal}
                handleSubmit={handleSubmit}
                initLoading={initLoading}
            />
        </Dialog>
    )
}
UserEditFormDialog.propTypes = {
    loading: PropTypes.bool,
    open: PropTypes.bool,
    initLoading: PropTypes.bool,
    updateUser: PropTypes.func,
    reset: PropTypes.func,
}

export default connect(
    (state) => ({
        loading: loadingUserEditSelector(state),
        open: openEditUserModalSelector(state),
        initLoading: loadingInitUsersSelector(state),
    }),
    {
        updateUser,
        reset,
        closeEditUserModal,
    }
)(
    reduxForm({
        form: 'editUser',
        validate,
    })(UserEditFormDialog)
)
