import {handleActions} from 'redux-actions'
import ordersConstants from '../constants/ordersConstants'

const initialState = {
    content: [],
}

export default handleActions(
    {
        [ordersConstants.FETCH_ORDERS_ALL_SUCCESS]: (state, {payload}) => payload,
    },
    initialState
)
