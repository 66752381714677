import React from 'react'
import {Grid} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import {connect} from 'react-redux'
import {inspirationArray, inspirationsSelector} from '../../selectors/inspirations'
import InspirationSortableContainer from './InspirationSortableList'
import InspirationModal from './Dialog/InspirationModal'
import {openInspirationModal} from '../../actions/modal'
import {
    changeInspirationOrderInDB,
    onSortEndInspirations,
    setArrayInspirationsChanges,
    setArrayInspirationsChangesFalse,
} from '../../actions/inspirations'
import arrayMove from 'array-move'
import PageWrapper from '../PageWrapper'
import Buttons from '../Buttons/Buttons'

const InspirationList = ({
    inspirations,
    openInspirationModal,
    onSortEndInspirations,
    changeInspirationOrderInDB,
    inspirationChanges,
    setArrayInspirationsChanges,
}) => {
    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex === newIndex) {
            return
        }
        onSortEndInspirations(arrayMove(inspirations, oldIndex, newIndex))
        setArrayInspirationsChanges()
    }
    const rightBar = (
        <Grid container justify="space-between" alignItems="center">
            <Grid item>
                {inspirationChanges && (
                    <Buttons marginRight startIcon={<SaveIcon />} onClick={changeInspirationOrderInDB}>
                        Uložit změny pořadí
                    </Buttons>
                )}
                <Buttons onClick={openInspirationModal} startIcon={<AddIcon />}>
                    Přidej inspiraci
                </Buttons>
            </Grid>
        </Grid>
    )
    return (
        <PageWrapper heading={'Inspirace na cesty'} rightBar={rightBar}>
            <InspirationModal />
            <Grid container spacing={2} direction="row">
                <InspirationSortableContainer
                    useDragHandle={true}
                    lockAxis="y"
                    items={inspirations}
                    onSortEnd={onSortEnd}
                />
            </Grid>
        </PageWrapper>
    )
}

export default connect(
    (state) => ({inspirations: inspirationsSelector(state), inspirationChanges: inspirationArray(state)}),
    {
        openInspirationModal,
        onSortEndInspirations,
        changeInspirationOrderInDB,
        setArrayInspirationsChanges,
        setArrayInspirationsChangesFalse,
    }
)(InspirationList)
