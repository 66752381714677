import Axios from 'axios'
import {initialize} from 'redux-form'
import {openEditUserModal} from '../modal'
import {APIroutes} from '../../constants/routes'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import {setLoading, setNull} from '../fetch'
import fetchConstants from '../../constants/fetchConstants'
import fetchErrorHandler from '../../utils/fetchErrorHandler'

export const initUser = (id) => (dispatch, getState) => {
    const {baseUrl, user, version} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(openEditUserModal())
    dispatch(setLoading(fetchConstants.USER_INIT))
    Axios.get(`${baseUrl}${version}${user}${id}`, config)
        .then(({data}) => {
            dispatch(initialize('editUser', data))
            dispatch(setNull({fetch: fetchConstants.USER_INIT}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.USER_INIT))
}
