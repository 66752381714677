import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import {useTheme} from '@material-ui/core/styles'
import NavItem from '../Navigation/NavItem'
import TringaIcon from '../../assets/tringa-bird-svg.svg'
import {Typography} from '@material-ui/core'
import DividerSvg from '../../assets/divider.svg'
import LowPricePNG from '../../assets/icons8-price-96.png'
import PersonsPNG from '../../assets/icons8-conference-96.png'
import TemperaturePNG from '../../assets/icons8-dew-point-96.png'
import PlanePNG from '../../assets/icons8-around-the-globe-96.png'
import Bell from '../../assets/icons8-alarm-96.png'
import ApplePay from '../../assets/icons8-apple-pay-96.png'
import LowerCase from '../../assets/icons8-lowercase-96.png'
import Nature from '../../assets/icons8-nature-96.png'
import Beach from '../../assets/icons8-beach-96.png'
import Special from '../../assets/icons8-paste-special-96.png'
import FioBank from '../../assets/fio_logo.jpg'
import {PAGES} from '../../utils/routes'
import {useDispatch} from 'react-redux'
import {logout} from '../../actions/users/logout'
import ShutDownICon from '../../assets/icons8-shutdown-64.png'
const DrawerNav = ({classes, handleDrawerToggle, mobileOpen, open}) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const drawer = (
        <div>
            <NavItem itemFor={PAGES.RESERVATIONS} icon={LowPricePNG} />
            <NavItem itemFor={PAGES.FIO_BANK} icon={FioBank} />
            <NavItem itemFor={PAGES.SPECIAL_OFFERS} icon={Special} />
            <NavItem itemFor={PAGES.PROPAGATE_OFFERS} icon={Beach} />
            <NavItem itemFor={PAGES.INSPIRATIONS} icon={Nature} />
            <NavItem itemFor={PAGES.LANDING_PAGE} icon={LowerCase} />
            <NavItem itemFor={PAGES.DEST_TEMPERATURES} icon={TemperaturePNG} />
            <NavItem itemFor={PAGES.FLIGHT_COMPANIES} icon={PlanePNG} />
            <NavItem itemFor={PAGES.NOTIFICATIONS} icon={Bell} />
            <NavItem itemFor={PAGES.APPLE_CERT} icon={ApplePay} />
            <NavItem itemFor={PAGES.USERS} icon={PersonsPNG} />
            <NavItem icon={ShutDownICon} clickable onClick={() => dispatch(logout())}>
                Odlhásit se
            </NavItem>
        </div>
    )

    return (
        <div>
            <Hidden smUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true,
                    }}>
                    <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                        <CloseIcon />
                    </IconButton>
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}>
                    <div className={classes.tringaIcon}>
                        <img src={TringaIcon} alt="logo" width={80} />
                    </div>
                    <div className={classes.tringaText}>
                        <Typography variant={'body1'}>Tringa Travel s.r.o.</Typography>
                    </div>
                    <div>
                        <img src={DividerSvg} alt="logo" />
                    </div>

                    {drawer}
                </Drawer>
            </Hidden>
        </div>
    )
}

export default DrawerNav
