import {createSelector} from 'reselect'
import fetchConstants from '../../../constants/fetchConstants'

export const fetchRequestsSelector = (state) => state.fetch.requests

export const createPropagateSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.CREATE_PROPAGATE]
)

export const updatePropagateSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.UPDATE_PROPAGATE_OFFER]
)

export const createPropagateOfferLoading = createSelector(createPropagateSelector, (state) => state.loading)

export const updatePropagateOfferLoading = createSelector(updatePropagateSelector, (state) => state.loading)
