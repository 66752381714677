import {handleActions} from 'redux-actions'
import propagateConstants from '../constants/propagateConstants'

const initialState = []

export default handleActions(
    {
        [propagateConstants.PROPAGATE_OFFERS_ALL_SUCCESS]: (state, {payload}) => payload,
        [propagateConstants.PROPAGATE_UPDATE_SUCCESS]: (state, {payload}) =>
            state.map((offer) => (offer.id === payload.id ? payload : offer)),
        [propagateConstants.DELETE_PROPAGATE_OFFER]: (state, {payload}) =>
            state.filter((offer) => offer.id !== payload),
        [propagateConstants.CREATE_PROPAGATE_OFFER]: (state, {payload}) => [...state, payload],
        [propagateConstants.PRE_ORDER_ARRAY]: (state, {payload}) => payload,
    },
    initialState
)
