import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import toFormatDate from '../../utils/toFormatDate'
import PropTypes from 'prop-types'
import {isNotNil} from 'ramda-extension'
import ClearIcon from '@material-ui/icons/Clear'
import {Tooltip} from '@material-ui/core'
import {useStyles} from '../../css/useStyles'

const OrderDetailTable = ({
    order: {id, externalId, createdAt, emailContractSentAt, expireAt, status, expireNotificationSentAt},
}) => {
    const {tableHeadGray} = useStyles()

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead className={tableHeadGray}>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">Číslo rezervace</TableCell>
                        <TableCell align="right">Datum vytvoření</TableCell>
                        <TableCell align="right">Datum rezervace</TableCell>
                        <TableCell align="right">Datum expirace</TableCell>
                        <Tooltip placement={'top'} title={'Datum odeslání smlouvy'}>
                            <TableCell align="right">Odeslání smlouvy</TableCell>
                        </Tooltip>
                        <Tooltip placement={'top'} title={'Datum odeslání expirační notifikace'}>
                            <TableCell align="right">Odeslání exp. not.</TableCell>
                        </Tooltip>
                        <TableCell align="right">Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            {id}
                        </TableCell>
                        <TableCell align="right">{externalId}</TableCell>
                        <TableCell align="right">{toFormatDate(createdAt)}</TableCell>
                        <TableCell align="right">{toFormatDate(createdAt)}</TableCell>
                        <TableCell align="right">{toFormatDate(expireAt)}</TableCell>
                        <TableCell align="right">
                            {isNotNil(emailContractSentAt) ? (
                                toFormatDate(emailContractSentAt)
                            ) : (
                                <ClearIcon fontSize="small" color="error" />
                            )}
                        </TableCell>
                        <TableCell align="right">
                            {isNotNil(expireNotificationSentAt) ? (
                                toFormatDate(expireNotificationSentAt)
                            ) : (
                                <ClearIcon fontSize="small" color="error" />
                            )}
                        </TableCell>
                        <TableCell align="right">{status}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
OrderDetailTable.propTypes = {
    order: PropTypes.object,
}
export default OrderDetailTable
