import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import fetchConstants from '../../constants/fetchConstants'
import Axios from 'axios'
import {createAction} from 'redux-actions'
import inspirationsConstants from '../../constants/inspirationsConstants'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import {inspirationsSelector} from '../../selectors/inspirations'
import {closeInspirationModal} from '../modal'
import {toastSuccess} from '../../components/Toast/ToastSuccess'

const fetchSuccess = createAction(inspirationsConstants.INSPIRATIONS_SUCCESS)

export const fetchAllInspirations = () => (dispatch, getState) => {
    const {baseUrl, version, inspirations} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.FETCH_ALL_INSPIRATIONS))
    Axios.get(`${baseUrl}${version}${inspirations}`, config)
        .then(({data}) => {
            dispatch(fetchSuccess(data))
            dispatch(setNull({fetch: fetchConstants.FETCH_ALL_INSPIRATIONS}))
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.FETCH_ALL_INSPIRATIONS))
}

const createSuccess = createAction(inspirationsConstants.INSPIRATIONS_CREATE_SUCCESS)
export const createNewInspirations = (data) => (dispatch, getState) => {
    const {baseUrl, version, inspirations} = APIroutes
    const inspirationsArray = inspirationsSelector(getState())

    const formData = new FormData()
    formData.append('image', data.image)
    formData.append('active', data.active)
    formData.append('title', data.title)
    formData.append('location', inspirationsArray.length)
    formData.append('filter', data.filter)

    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.CREATE_INSPIRATION))
    Axios.post(`${baseUrl}${version}${inspirations}`, formData, config)
        .then(({data}) => {
            dispatch(createSuccess(data))
            dispatch(setNull({fetch: fetchConstants.CREATE_INSPIRATION}))
            dispatch(closeInspirationModal())
            toastSuccess('Inspirace byla vytvořena')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.CREATE_INSPIRATION))
}

const afterUpdateWithImage = createAction(inspirationsConstants.INSPIRATIONS_UPDATE_SUCCESS)
export const updateInspirationWithImage = (data) => (dispatch, getState) => {
    const {baseUrl, version, inspirations} = APIroutes

    const formData = new FormData()
    formData.append('image', data.image)
    formData.append('active', data.active)
    formData.append('title', data.title)
    formData.append('location', data.location)
    formData.append('filter', data.filter)

    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.UDPATE_INSPIRATIONS))
    Axios.put(`${baseUrl}${version}${inspirations}/${data.id}`, formData, config)
        .then(({data}) => {
            dispatch(afterUpdateWithImage(data))
            dispatch(setNull({fetch: fetchConstants.UDPATE_INSPIRATIONS}))
            dispatch(closeInspirationModal())
            toastSuccess('Inspirace byla aktualizována')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.UDPATE_INSPIRATIONS))
}

const afterUpdateNoImage = createAction(inspirationsConstants.INSPIRATIONS_UPDATE_SUCCESS)

export const updateWithOldIamge = (data) => (dispatch, getState) => {
    const {baseUrl, version, inspirations, updateNoImage} = APIroutes

    const formData = new FormData()
    formData.append('active', data.active)
    formData.append('title', data.title)
    formData.append('location', data.location)
    formData.append('filter', data.filter)

    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.UPDATE_INSPIRATIONS_WITHOUT_IMAGE))
    Axios.put(`${baseUrl}${version}${inspirations}${updateNoImage}/${data.id}`, formData, config)
        .then(({data}) => {
            dispatch(afterUpdateNoImage(data))
            dispatch(setNull({fetch: fetchConstants.UPDATE_INSPIRATIONS_WITHOUT_IMAGE}))
            dispatch(closeInspirationModal())
            toastSuccess('Inspirace byla aktualizována')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.UPDATE_INSPIRATIONS_WITHOUT_IMAGE))
}

const deleteSuccess = createAction(inspirationsConstants.INSPIRATIONS_DELETE_SUCCESS)
export const deleteInspiration = (id) => (dispatch, getState) => {
    const {baseUrl, version, inspirations} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.DELETE_INSPIRATION))
    Axios.delete(`${baseUrl}${version}${inspirations}/${id}`, config)
        .then(({data}) => {
            dispatch(deleteSuccess(data))
            dispatch(setNull({fetch: fetchConstants.DELETE_INSPIRATION}))
            dispatch(closeInspirationModal())
            toastSuccess('Inspirace byla ostraněna')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.DELETE_INSPIRATION))
}

export const changeInspirationOrderInDB = () => (dispatch, getState) => {
    const arrayOfChanges = inspirationsSelector(getState())
    const arrayToRequest = arrayOfChanges.map((insp, index) => ({...insp, location: index + 1}))
    const {baseUrl, version, inspirations, inspirationsChanges} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.CHANGE_ORDER_INSPIRATIONS))
    Axios.post(`${baseUrl}${version}${inspirations}${inspirationsChanges}`, arrayToRequest, config)
        .then(({data}) => {
            dispatch(setArrayInspirationsChangesFalse())
            dispatch(setNull({fetch: fetchConstants.CHANGE_ORDER_INSPIRATIONS}))
            toastSuccess('Pořadí bylo změněno')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.CHANGE_ORDER_INSPIRATIONS))
}

export const onSortEndInspirations = createAction(inspirationsConstants.ON_SORT_END_INSPIRATIONS)

export const setArrayInspirationsChanges = createAction(inspirationsConstants.SET_ARRAY_LOCATIONS_CHANGE_TRUE)

export const setArrayInspirationsChangesFalse = createAction(inspirationsConstants.SET_ARRAY_LOCATIONS_CHANGE_FALSE)
