import React from 'react'
import {Grid} from '@material-ui/core'
import {Field, reduxForm} from 'redux-form'
import {cFieldNames, validate, initialValues} from '../../../constants/form/flightCompaniesFormSchema'
import {renderTextField} from '../../UserFormDialog/TextField'
import formNames from '../../../constants/form/formNames'
import {useStyles} from '../../../css/useStyles'

const lessThan = (value, previousValue) => (value.length <= 2 ? value : previousValue)

const FlightCompaniesForm = ({handleSubmit}) => {
    const {airCompanyCodeInput} = useStyles()
    return (
        <form onSubmit={handleSubmit} onKeyDown={(e) => e.keyCode === 13 && handleSubmit()}>
            <Grid container spacing={1} justify="center">
                <Grid item md={3} xs={12} container justify="center" alignItems="center">
                    Kód
                </Grid>
                <Grid item xs={12} md={9}>
                    <Field
                        disableErrorCode
                        name={cFieldNames.code}
                        className={airCompanyCodeInput}
                        normalize={lessThan}
                        component={renderTextField}
                    />
                </Grid>
                <Grid item md={3} xs={12} container justify="center" alignItems="center">
                    Jméno
                </Grid>
                <Grid item xs={12} md={9}>
                    <Field disableErrorCode name={cFieldNames.name} component={renderTextField} />
                </Grid>
                <Grid item md={3} xs={12} container justify="center" alignItems="center">
                    URL
                </Grid>
                <Grid item xs={12} md={9}>
                    <Field disableErrorCode name={cFieldNames.url} component={renderTextField} />
                </Grid>
            </Grid>
        </form>
    )
}
export default reduxForm({
    form: formNames.FLIGHT_COMPANIES_FORM,
    initialValues,
    validate: validate,
})(FlightCompaniesForm)
