import {keyMirror} from 'ramda-extension'

export const PAGES = keyMirror({
    RESERVATIONS: null,
    USERS: null,
    SPECIAL_OFFERS: null,
    FLIGHT_COMPANIES: null,
    DEST_TEMPERATURES: null,
    PROPAGATE_OFFERS: null,
    INSPIRATIONS: null,
    NOTIFICATIONS: null,
    LANDING_PAGE: null,
    APPLE_CERT: null,
    FIO_BANK: null,
})

export const PAGE_TITLES = {
    [PAGES.RESERVATIONS]: 'Rezervace',
    [PAGES.USERS]: 'Uživatelé',
    [PAGES.SPECIAL_OFFERS]: 'Speciální nabídky',
    [PAGES.FLIGHT_COMPANIES]: 'Letové společnosti',
    [PAGES.DEST_TEMPERATURES]: 'Destinace a teploty',
    [PAGES.PROPAGATE_OFFERS]: 'Propagované nabídky',
    [PAGES.INSPIRATIONS]: 'Inspirace',
    [PAGES.NOTIFICATIONS]: 'Notifikace',
    [PAGES.LANDING_PAGE]: 'Úvodní stránka',
    [PAGES.APPLE_CERT]: 'Apple certification',
    [PAGES.FIO_BANK]: 'Fio platby',
}

export const PAGE_ROUTES = {
    [PAGES.RESERVATIONS]: '/',
    [PAGES.USERS]: '/users',
    [PAGES.SPECIAL_OFFERS]: '/offers',
    [PAGES.FLIGHT_COMPANIES]: '/flight_companies',
    [PAGES.DEST_TEMPERATURES]: '/destination_temperatures',
    [PAGES.PROPAGATE_OFFERS]: '/propagate_offers',
    [PAGES.INSPIRATIONS]: '/inspirations',
    [PAGES.NOTIFICATIONS]: '/notification',
    [PAGES.LANDING_PAGE]: '/launch_screen',
    [PAGES.APPLE_CERT]: '/apple_session_cert',
    [PAGES.FIO_BANK]: '/fio_payments',
}
