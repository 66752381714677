import {handleActions} from 'redux-actions'
import fetchConstants from '../constants/fetchConstants'

const fetchInit = {
    loading: false,
    error: {
        status: false,
        msg: '',
    },
}
const loadingLogic = (state, fetch) => ({
    ...state,
    requests: {
        ...state.requests,
        [fetch]: {
            error: {
                status: false,
                msg: '',
            },
            loading: true,
        },
    },
})
const setError = (state, fetch, msg) => ({
    ...state,
    requests: {
        ...state.requests,
        [fetch]: {
            loading: false,
            error: {
                status: true,
                msg: msg,
            },
        },
    },
})
const setNull = (state, fetch) => ({
    ...state,
    requests: {
        ...state.requests,
        [fetch]: fetchInit,
    },
})
export default handleActions(
    {
        [fetchConstants.SET_LOADING]: (state, {payload}) => loadingLogic(state, payload),
        [fetchConstants.SET_ERROR]: (state, {payload: {fetch, msg}}) => setError(state, fetch, msg),
        [fetchConstants.SET_NULL]: (state, {payload: {fetch}}) => setNull(state, fetch),
    },
    {
        requests: {
            [fetchConstants.USER_LOGIN]: fetchInit,
            [fetchConstants.USER_EDIT]: fetchInit,
            [fetchConstants.USER_DELETE]: fetchInit,
            [fetchConstants.USER_CREATE]: fetchInit,
            [fetchConstants.USER_ALL]: fetchInit,
            [fetchConstants.USER_INIT]: fetchInit,
            [fetchConstants.ORDERS_ALL]: fetchInit,
            [fetchConstants.ORDER_DETAIL]: fetchInit,
            [fetchConstants.USER_AUTO_LOGIN]: fetchInit,
            [fetchConstants.FETCH_DESTINATION_ALL]: fetchInit,
            [fetchConstants.ORDER_INVOICE]: fetchInit,
            [fetchConstants.OFFERS_ALL]: fetchInit,
            [fetchConstants.OFFER_DELETE]: fetchInit,
            [fetchConstants.OFFERS_CHANGE_POSITIONS]: fetchInit,
            [fetchConstants.UPDATE_WITHOUT_PICTURE]: fetchInit,
            [fetchConstants.UPDATE_WITH_PICTURE]: fetchInit,
            [fetchConstants.UPLOAD_OFFERS]: fetchInit,

            [fetchConstants.CODELIST_FETCH]: fetchInit,

            [fetchConstants.FLIGHT_COMPANIES_ALL]: fetchInit,
            [fetchConstants.FLIGHT_COMPANIES_CREATE]: fetchInit,
            [fetchConstants.FLIGHT_COMPANIES_DELETE]: fetchInit,
            [fetchConstants.FLIGHT_COMPANIES_UPDATE]: fetchInit,

            [fetchConstants.PROPAGATE_OFFERS_ALL]: fetchInit,
            [fetchConstants.UPDATE_PROPAGATE_OFFER]: fetchInit,
            [fetchConstants.DELETE_PROPAGATE]: fetchInit,
            [fetchConstants.CREATE_PROPAGATE]: fetchInit,
            [fetchConstants.CHANGE_LOCATIONS]: fetchInit,

            [fetchConstants.FETCH_ALL_INSPIRATIONS]: fetchInit,
            [fetchConstants.CREATE_INSPIRATION]: fetchInit,
            [fetchConstants.DELETE_INSPIRATION]: fetchInit,
            [fetchConstants.CHANGE_ORDER_INSPIRATIONS]: fetchInit,
            [fetchConstants.UDPATE_INSPIRATIONS]: fetchInit,
            [fetchConstants.UPDATE_INSPIRATIONS_WITHOUT_IMAGE]: fetchInit,
            [fetchConstants.SEND_NOTIFICATION]: fetchInit,
            [fetchConstants.LAUNCH_SCREEN_FETCH_ALL]: fetchInit,
            [fetchConstants.LAUNCH_SCREEN_UPDATE]: fetchInit,
            [fetchConstants.LAUNCH_SCREEN_DELETE]: fetchInit,
            [fetchConstants.LAUNCH_SCREEN_CREATE]: fetchInit,
        },
    }
)
