import React from 'react'
import Empty from '../../utils/empty_box.png'
import {Typography, Grid} from '@material-ui/core'

const EmptyBox = () => {
    return (
        <Grid container direction="column" alignItems="center" justify="center">
            <Grid item>
                <img src={Empty} alt="Empty" width="150px" />
            </Grid>
            <Grid item>
                <Typography variant="h5">Kde nic, tu nic.. </Typography>
            </Grid>
        </Grid>
    )
}

export default EmptyBox
