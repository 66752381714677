import React from 'react'
import {Button, Grid} from '@material-ui/core'
import {Field} from 'redux-form'
import {renderCheckbox, renderTextField} from '../UserFormDialog/TextField'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import {isNumber} from '../../utils/reduxValidator'

const RenderSections = ({fields, meta: {error, submitFailed}, classes}) => {
    return (
        <React.Fragment>
            <Grid container spacing={3} justify="center">
                {fields.map((section, index) => (
                    <div className={classes.formSection}>
                        <Grid container justify="center" spacing={1}>
                            <Grid item md={3} xs={12} container justify="center" alignItems="center">
                                Title
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Field name={`${section}.title`} component={renderTextField} multiline />
                            </Grid>
                            <Grid item md={3} xs={12} container justify="center" alignItems="flex-start">
                                Text
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Field name={`${section}.text`} component={renderTextField} multiline />
                            </Grid>
                            <Grid item md={3} xs={3} container justify="center" alignItems="center">
                                Aktivní
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Field name={`${section}.active`} color={'primary'} component={renderCheckbox} />
                            </Grid>
                            <Grid item md={1} xs={1} container justify="center" alignItems="center">
                                Location
                            </Grid>
                            <Grid item xs={2} md={2} container justify={'flex-end'}>
                                <Field
                                    name={`${section}.location`}
                                    className={classes.fieldLocation}
                                    validate={[isNumber]}
                                    component={renderTextField}
                                />
                            </Grid>
                            <Grid item xs={12} md={9} container justify={'center'} className={classes.cancelButton}>
                                <Button
                                    type={'button'}
                                    onClick={() => fields.remove(index)}
                                    variant={'outlined'}
                                    color={'secondary'}
                                    startIcon={<CancelOutlinedIcon color={'error'} />}>
                                    Odstranit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                ))}
                <Grid item md={12} xs={12} container justify="center" alignItems="center">
                    <Button type="button" variant={'outlined'} onClick={() => fields.push({active: false})}>
                        <AddCircleIcon className={classes.addSection} /> Přidat sekci
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
export default RenderSections
