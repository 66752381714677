import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import DateCell from './DateCell'
import {IconButton} from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import TableRow from '@material-ui/core/TableRow'
import {useColors} from '../../css/useColors'

const OrderTableRow = ({row, clickHandler}) => {
    const {arrowBlue} = useColors()

    return (
        <TableRow hover tabIndex={-1} key={row.id}>
            <TableCell component="th" scope="row">
                {row.id}
            </TableCell>

            <TableCell align="left">{row.externalId}</TableCell>
            <TableCell width={200} align="left">
                <DateCell
                    createdAt={row.createdAt}
                    expireAt={row.expireAt}
                    emailContractSentAt={row.emailContractSentAt}
                    dateFirstPayment={row.firstPaymentPaidAt}
                />
            </TableCell>
            <TableCell align="left">{`${row.customer.firstName} ${row.customer.lastName}`}</TableCell>
            <TableCell align="left">{row.status}</TableCell>
            <TableCell align="left">{`${row.totalPrice.toLocaleString()} Kč`}</TableCell>
            <TableCell align="left">{row.provider}</TableCell>
            <TableCell align="center">
                <IconButton onClick={(e) => clickHandler(e, row.id)}>
                    <SendIcon color={'action'} className={arrowBlue} />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}
export default OrderTableRow
