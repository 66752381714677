import {handleActions} from 'redux-actions'
import ordersConstants from '../constants/ordersConstants'

const initialState = {}

export default handleActions(
    {
        [ordersConstants.FETCH_ORDER_BY_ID]: (state, {payload}) => ({
            ...payload,
        }),
        [ordersConstants.AFTER_GENERATE]: (state, {payload}) => ({
            ...state,
            contracts: payload,
        }),
        [ordersConstants.RESET_DETAIL]: (state, {payload}) => initialState,
    },
    initialState
)
