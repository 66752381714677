import Axios from 'axios'
import {closeEditUserModal} from '../modal'
import {APIroutes} from '../../constants/routes'
import {setLoading, setNull} from '../fetch'
import fetchConstants from '../../constants/fetchConstants'
import {getAuthHeaders} from '../../utils/authFetchHeaders'
import {reset} from 'redux-form'
import fetchErrorHandler from '../../utils/fetchErrorHandler'
import {toastSuccess} from '../../components/Toast/ToastSuccess'
import {loadAllUsrs} from './loadAllUsers'

export const updateUser = (formData, id, withPassword) => (dispatch, getState) => {
    const {
        users: {size, number},
    } = getState()
    const {baseUrl, version, userUpdate, userWithPasswordUpdate} = APIroutes
    const config = getAuthHeaders(getState())
    dispatch(setLoading(fetchConstants.USER_EDIT))
    Axios.put(
        `${baseUrl}${withPassword ? `${version}${userUpdate}` : `${version}${userWithPasswordUpdate}`}${id}`,
        formData,
        config
    )
        .then(({data}) => {
            dispatch(loadAllUsrs(size, number))
            dispatch(setNull({fetch: fetchConstants.USER_EDIT}))
            dispatch(closeEditUserModal())
            dispatch(reset('editUser'))
            toastSuccess('Uživatel byl aktualizován')
        })
        .catch((e) => fetchErrorHandler(dispatch, e, fetchConstants.USER_EDIT))
}
