export const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'temperature.water',
        'temperature.air',
        'temperature.month',
        'destinationName',
        'destinationId',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Povinné'
        }
        if (values[field] === '') {
            errors[field] = 'Povinné'
        }
    })
    return errors
}
