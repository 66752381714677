import React from 'react'
import {Grid} from '@material-ui/core'
import {Field, reduxForm} from 'redux-form'
import {cFieldNames} from '../../../constants/form/StornoAdminNoteFormSchema'
import {renderTextField} from '../../UserFormDialog/TextField'
import formNames from '../../../constants/form/formNames'

const StornoAdminNoteForm = ({handleSubmit}) => {
    return (
        <form onSubmit={handleSubmit} onKeyDown={(e) => e.keyCode === 13 && handleSubmit()}>
            <Grid container spacing={1} justify="center">
                <Grid item xs={12} md={12}>
                    <Field name={cFieldNames.note} component={renderTextField} multiline rows={4} />
                </Grid>
            </Grid>
        </form>
    )
}
export default reduxForm({
    form: formNames.STORNO_ADMIN_NOTE_FORM,
})(StornoAdminNoteForm)
