import {keyMirror} from 'ramda-extension'

export const routes = {
    login: '/login',
    dashboard: '/',
}

const ordersRoutes = {
    ordersPrefix: '/order',
    find: '/find',
}

const flightRoutes = {
    flightCompanies: '/flight_company',
}

const userRoutes = {
    findAllUsers: '/user/all',
    login: '/auth/login',
    autoLogin: '/auth',
    userUpdate: '/user/update/',
    userWithPasswordUpdate: '/user/update/noPassword/',
    user: '/user/',
    userCreate: '/user/create',
    userDelete: '/user/delete/',
    offers: '/specials-offers',
    changePositions: '/change-positions',
    updateWithoutImage: '/update/noImage',
    offerUpload: '/upload',
    offerUpdate: '/update',
}

const destinationTemperatures = {
    temperatures: '/temperature',
}
const propagateOffersRoutes = {
    propagateOffers: '/propagate-offer',
}

const inspirations = {
    inspirations: '/inspirations',
    inspirationsChanges: '/changes',
    updateNoImage: '/no-image',
}
const notification = {
    notification: '/firebase',
}
const launchScreen = {
    launchScreen: '/launch-screen',
    allLaunchScreens: '/launch-screen/all',
}

const appleSession = {
    contentOfAppleTxt: '/.well-known/apple-developer-merchantid-domain-association.txt',
    appleWellknown: '/.well-known',
}

const payment = {
    payment: '/payment',
    log: '/log',
}

const fioPayments = {
    fioPayments: '/fio',
    lastFioPayments: '/last',
    fioPaymentsPeriod: '/period',
    fioPaymentsWithoutOrder: '/without-order',
    fioPaymentsWithOrder: '/with-order',
    account: '/account',
}

const reservation = {
    reservation: '/reservation',
}
const searchApi = {
    searchApiHotelId: 'https://master.search.api.tringa.cz/api/v1/hotel/',
}

export const BASE_URL = 'baseUrl'
export const API_URL = keyMirror({
    DEV_API: null,
    PROD_API: null,
    CUSTOM_API: null,
    BASE_URL: null,
    API_ACTIVE: null,
})

export const APIroutes = {
    version: '/v1',
    [BASE_URL]: localStorage.getItem(API_URL.BASE_URL),
    [API_URL.DEV_API]: 'https://dev.reservation.api.tringa.cz',
    [API_URL.PROD_API]: 'https://master.reservation.api.tringa.cz',
    prodUrl: process.env.REACT_APP_API_URI,
    codelist: 'https://master.search.api.tringa.cz/api/v1/data-counters/all',
    ...reservation,
    ...userRoutes,
    ...ordersRoutes,
    ...flightRoutes,
    ...destinationTemperatures,
    ...propagateOffersRoutes,
    ...inspirations,
    ...notification,
    ...launchScreen,
    ...appleSession,
    ...fioPayments,
    ...searchApi,
    ...payment,
}
