import userConstants from '../constants/userConstants'
import {handleActions} from 'redux-actions'

const initialState = {
    isAuthorized: false,
}
export default handleActions(
    {
        [userConstants.LOGIN_SUCCESS]: (state, {payload}) => ({
            ...state,
            ...payload.user,
            token: payload.token,
            isAuthorized: true,
        }),
        [userConstants.LOGOUT]: (state, action) => initialState,
    },
    initialState
)
