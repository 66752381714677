import React from 'react'
import {Avatar, Grid, IconButton, ListItem, ListItemSecondaryAction, Paper} from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import DragHandle from '../PropagateOffers/DragHandle'
import {useStyles} from '../../css/useStyles'
import ConfirmDeleteWithIcon from '../ConfirmDialog/ConfirmDeleteWithIcon'
import {useColors} from '../../css/useColors'

const OfferCard = ({offer, deleteOffer, fillToUpdate}) => {
    const {avatarSize5} = useStyles()
    const {svgGreen, svgRed} = useColors()

    return (
        <Paper square>
            <ListItem ContainerComponent="div">
                <DragHandle />
                <Grid container alignItems="center" spacing={2}>
                    <Grid item md={1}>
                        {offer.id}
                    </Grid>
                    <Grid item md={1}>
                        <Avatar alt="Remy Sharp" src={offer.imageUrl} className={avatarSize5} />
                    </Grid>
                    <Grid item md={3}>
                        {offer.title}
                    </Grid>
                    <Grid item md={5}>
                        {offer.description}
                    </Grid>
                    <Grid item md={1}>
                        <FiberManualRecordIcon className={offer.active ? svgGreen : svgRed} fontSize="small" />
                    </Grid>
                </Grid>
                <ListItemSecondaryAction>
                    <IconButton onClick={() => fillToUpdate(offer)}>
                        <CreateIcon color="primary" />
                    </IconButton>
                    <ConfirmDeleteWithIcon onApprove={() => deleteOffer(offer.id)} />
                </ListItemSecondaryAction>
            </ListItem>
        </Paper>
    )
}
export default OfferCard
