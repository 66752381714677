import {Grid} from '@material-ui/core'
import React from 'react'
import {propagateLocationChanged, propagateOffersSelector} from '../../selectors/propagateOffers'
import {connect, useDispatch} from 'react-redux'
import PropagateOfferDialog from './Dialog'
import AddIcon from '@material-ui/icons/Add'
import {locationWasChanged, preOrderPropagateOffers, saveNewLocations} from '../../actions/propagateOffers'
import {openReduxModal} from '../../actions/modal'
import arrayMove from 'array-move'
import SaveIcon from '@material-ui/icons/Save'
import PageWrapper from '../PageWrapper'
import {PAGES} from '../../utils/routes'
import modalNames from '../../constants/modal/modalNames'
import SortablePropagateOfferList from './SortablePropagateOfferList'
import Buttons from '../Buttons/Buttons'

const PropagateOffersList = ({
    propagateOffers,
    preOrderPropagateOffers,
    locationWasChanged,
    propagateLocationWasChanged,
    saveNewLocations,
}) => {
    const dispatch = useDispatch()

    const openModal = () => {
        dispatch(openReduxModal(modalNames.PROPAGATE_OFFER_MODAL))
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex === newIndex) return
        preOrderPropagateOffers(arrayMove(propagateOffers, oldIndex, newIndex))
        locationWasChanged()
    }
    const rightBar = (
        <Grid container justify="space-between" alignItems="center">
            <Grid item>
                {propagateLocationWasChanged && (
                    <Buttons marginRight startIcon={<SaveIcon />} onClick={saveNewLocations}>
                        Uložit změny pořadí
                    </Buttons>
                )}
                <Buttons startIcon={<AddIcon />} onClick={openModal} disabled={propagateLocationWasChanged}>
                    Přidej nabídku
                </Buttons>
            </Grid>
        </Grid>
    )
    return (
        <PageWrapper headingFor={PAGES.PROPAGATE_OFFERS} rightBar={rightBar}>
            <PropagateOfferDialog />
            <SortablePropagateOfferList
                onSortEnd={onSortEnd}
                items={propagateOffers}
                useDragHandle={true}
                lockAxis="y"
            />
        </PageWrapper>
    )
}

export default connect(
    (state) => ({
        propagateOffers: propagateOffersSelector(state),
        propagateLocationWasChanged: propagateLocationChanged(state),
    }),
    {
        preOrderPropagateOffers,
        locationWasChanged,
        saveNewLocations,
    }
)(PropagateOffersList)
