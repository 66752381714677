import React from 'react'
import {Grid} from '@material-ui/core'
import {Field, FieldArray, reduxForm} from 'redux-form'
import {renderCheckbox, renderTextField} from '../UserFormDialog/TextField'
import RenderSections from './RenderSections'
import {validate, initialValues} from '../../constants/form/launchScreenFormSchema'
import formNames from '../../constants/form/formNames'

const LaunchScreenForm = ({handleSubmit, onSubmit, classes}) => {
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} justify="center">
                <Grid item md={3} xs={12} container justify="center" alignItems="center">
                    Title
                </Grid>
                <Grid item xs={12} md={9}>
                    <Field name="headingTitle" component={renderTextField} multiline />
                </Grid>
                <Grid item md={3} xs={3} container justify="center" color={'primary'} alignItems="center">
                    Aktivni
                </Grid>
                <Grid item xs={9} md={9}>
                    <Field name="active" color={'primary'} component={renderCheckbox} />
                </Grid>
            </Grid>
            <FieldArray name="sections" component={RenderSections} classes={classes} />
        </form>
    )
}
export default reduxForm({
    initialValues,
    form: formNames.LAUNCH_SCREEN_FORM,
    validate,
})(LaunchScreenForm)
