import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {Grid} from '@material-ui/core'
import {czkNumber} from '../../utils/formatNumber'
import orderItemsPrice from '../../constants/OrderItemTypes/orderItemsPrice'
import {useStyles} from '../../css/useStyles'

const OrderPersonAndItems = ({orderPeople}) => {
    const {tableHeadGray, userPreviewTableDetail} = useStyles()

    return orderPeople.map(({id, firstName, lastName, birthdate, gender, orderItems}) => (
        <React.Fragment key={id}>
            <Grid container>
                <Grid item md={5}>
                    <TableContainer className={userPreviewTableDetail}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">
                                        {firstName} {lastName}
                                    </TableCell>
                                    <TableCell align="left">{birthdate}</TableCell>
                                    <TableCell align="left">{gender}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead className={tableHeadGray}>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="left">Název</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">Cena</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderItems.map(({id, name, type, amount}) => (
                            <TableRow key={id}>
                                <TableCell scope="row">{id}</TableCell>
                                <TableCell align="left">{name}</TableCell>
                                <TableCell align="left" width={150}>
                                    {orderItemsPrice[type]}
                                </TableCell>
                                <TableCell align="left" width={150}>
                                    {czkNumber(amount)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    ))
}

export default OrderPersonAndItems
