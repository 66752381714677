import {createSelector} from 'reselect'
import fetchConstants from '../../../constants/fetchConstants'

export const fetchRequestsSelector = (state) => state.fetch.requests

export const initUserFetchSelector = createSelector(
    fetchRequestsSelector,
    (requests) => requests[fetchConstants.USER_INIT]
)

export const loadingInitUsersSelector = createSelector(initUserFetchSelector, (state) => state.loading)
