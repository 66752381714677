import React from 'react'
import {Grid, IconButton, ListItem, ListItemSecondaryAction, Paper} from '@material-ui/core'
import {SortableElement} from 'react-sortable-hoc'
import EditIcon from '@material-ui/icons/Edit'
import DragHandle from './DragHandle'
import {useDispatch, useSelector} from 'react-redux'
import {deletePropagateOffers, initPropagateModalForm} from '../../actions/propagateOffers'
import {openReduxModal} from '../../actions/modal'
import {propagateLocationChanged} from '../../selectors/propagateOffers'
import ConfirmDeleteWithIcon from '../ConfirmDialog/ConfirmDeleteWithIcon'
import modalNames from '../../constants/modal/modalNames'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import {useColors} from '../../css/useColors'

const SortableItem = SortableElement(({value}) => {
    const unOrder = useSelector(propagateLocationChanged)
    const dispatch = useDispatch()
    const {svgGreen, svgRed} = useColors()

    return (
        <Paper square>
            <ListItem ContainerComponent="div">
                <DragHandle />
                <Grid container alignItems="center" spacing={2}>
                    <Grid item md={1}>
                        {value.id}
                    </Grid>
                    <Grid item md={3}>
                        {value.name}
                    </Grid>
                    <Grid item md={6}>
                        {value.filter}
                    </Grid>
                    <Grid item md={1}>
                        <FiberManualRecordIcon className={value.active ? svgGreen : svgRed} fontSize="small" />
                    </Grid>
                </Grid>
                <ListItemSecondaryAction>
                    <IconButton
                        onClick={() => {
                            dispatch(initPropagateModalForm(value))
                            dispatch(openReduxModal(modalNames.PROPAGATE_OFFER_MODAL))
                        }}
                        disabled={unOrder}>
                        <EditIcon color="primary" disabled={unOrder} />
                    </IconButton>
                    <ConfirmDeleteWithIcon onApprove={() => dispatch(deletePropagateOffers(value.id))} />
                </ListItemSecondaryAction>
            </ListItem>
        </Paper>
    )
})
export default SortableItem
