import React, {useEffect} from 'react'
import {Container, Grid, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {connect, useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import {fetchOrderDetail} from '../../actions/orders/orderDetail'
import OrderDetailTable from '../../components/OrderDetail/OrderDetailTable'
import {orderDetailSelector} from '../../selectors/orders'
import OrderCustomerTable from '../../components/OrderDetail/OrderCustomerTable'
import OrderPaymentsTable from '../../components/OrderDetail/OrderPaymentsTable'
import OrderDetailInfoTable from '../../components/OrderDetail/OrderDetailInfoTable'
import OrderPersonAndItems from '../../components/OrderDetail/OrderPersonAndItems'
import SwissNotes from '../../components/OrderDetail/SwissNotes'
import FlightNotes from '../../components/OrderDetail/FlightNotes'
import {useHistory, useParams} from 'react-router'
import OrderRefreshButton from '../../components/Buttons/OrderRefreshButton'
import Contracts from '../../components/OrderDetail/Contracts'
import OrderEmails from '../../components/OrderDetail/OrderEmails'
import EximLogo from '../../assets/logo_exim_negativ.png'
import OrderTotalPrice from '../../components/OrderDetail/OrderTotalPrice'
import Icon8 from '../../components/IconFromPNG/Icon8'
import CancelIcon from '../../assets/icons8-event-declined-48.png'
import {openReduxModal} from '../../actions/modal'
import formNames from '../../constants/form/formNames'
import {initialize} from 'redux-form'
import StornoAdminNoteDialog from '../../components/OrderDetail/Dialog/StornoAdminNoteDialog'
import CheckIcon from '../../assets/icons8-checkmark-48.png'
import {setReservationDone} from '../../actions/orders'
import Notifications from '../../components/OrderDetail/Notifications'
import {useMargin} from '../../css/useMargin'
import Buttons from '../../components/Buttons/Buttons'
import {useColors} from '../../css/useColors'
import HeadingTypography from '../../components/OrderDetail/HeadingTypography'
import {useStyles} from '../../css/useStyles'
import StornoNote from "../../components/OrderDetail/StornoNote";

const OrderDetail = ({fetchOrderDetail, order}) => {
    const {id} = useParams()
    const {w100} = useStyles()
    const {orderTextColor} = useColors()

    const {mr10} = useMargin()

    const dispatch = useDispatch()

    const history = useHistory()

    useEffect(() => {
        fetchOrderDetail(id)
    }, [fetchOrderDetail, id])

    const stornoOrderWithAdminNote = () => {
        dispatch(openReduxModal(formNames.STORNO_ADMIN_NOTE_FORM))
        dispatch(
            initialize(formNames.STORNO_ADMIN_NOTE_FORM, {
                orderId: order.id,
                fireBaseId: order.fireBaseId,
                note: '',
            })
        )
    }

    const setOrderDone = () => {
        dispatch(
            setReservationDone({
                orderId: order.id,
                externalId: order.externalId,
                fireBaseId: order.fireBaseId,
            })
        )
    }

    return (
        <div className={w100}>
            <StornoAdminNoteDialog />
            <Container maxWidth="lg">
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item className={'flex'}>
                        <Grid item className={mr10}>
                            <Buttons onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
                                Zpět
                            </Buttons>
                        </Grid>
                        <Grid item className={mr10}>
                            <OrderRefreshButton id={order.id} />
                        </Grid>
                        <Grid item className={mr10}>
                            <Buttons onClick={stornoOrderWithAdminNote} startIcon={<Icon8 src={CancelIcon} />}>
                                Stornovat
                            </Buttons>
                        </Grid>
                        <Grid item className={mr10}>
                            <Buttons onClick={setOrderDone} startIcon={<Icon8 src={CheckIcon} />}>
                                Dokončit rez.
                            </Buttons>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        md={5}
                        container
                        direction={'row'}
                        justify={'flex-end'}
                        spacing={2}
                        alignItems={'center'}>
                        <Grid item>
                            <Typography className={orderTextColor} variant="h5">{`Objednávka č.${id}`}</Typography>
                        </Grid>
                        <Grid item className={'flex'}>
                            <img src={EximLogo} alt={'Provider'} width={100} />
                        </Grid>
                    </Grid>
                </Grid>
                {order && order.orderCustomer && order.orderPayments && (
                    <div>
                        <HeadingTypography>
                            <OrderDetailTable order={order} />
                        </HeadingTypography>
                        <HeadingTypography title={'Osoba, která sjednala pobyt'}>
                            <OrderCustomerTable order={order} />
                        </HeadingTypography>
                        <HeadingTypography title={'Platby'}>
                            <OrderPaymentsTable orderPayments={order.orderPayments} />
                        </HeadingTypography>
                        <HeadingTypography title={'Položky'}>
                            <OrderDetailInfoTable orderDetail={order.orderDetail} />
                        </HeadingTypography>
                        <HeadingTypography title={'Osoby a položky'}>
                            <OrderPersonAndItems orderPeople={order.orderPeople} order={order} />
                        </HeadingTypography>
                        <HeadingTypography title={'Cena celkem'}>
                            <OrderTotalPrice order={order} />
                        </HeadingTypography>
                        <HeadingTypography>
                            <Notifications notifications={order.notifications} />
                        </HeadingTypography>
                        <HeadingTypography>
                            <Contracts contracts={order.contracts} orderId={order.id} />
                        </HeadingTypography>
                        <HeadingTypography title={'Emaily'}>
                            <OrderEmails order={order} />
                        </HeadingTypography>
                        <HeadingTypography title={'Swiss poznámky'}>
                            <SwissNotes order={order} />
                        </HeadingTypography>
                        <HeadingTypography title={'Poznámky k letu'}>
                            <FlightNotes order={order} />
                        </HeadingTypography>
                        <HeadingTypography title={'Storno poznámka'}>
                            <StornoNote order={order} />
                        </HeadingTypography>
                    </div>
                )}
            </Container>
        </div>
    )
}
export default connect(
    (state) => ({
        order: orderDetailSelector(state),
    }),
    {
        fetchOrderDetail,
        push,
    }
)(OrderDetail)
