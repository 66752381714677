import bankCodes from '../../../utils/bankCodes'
import {equals, isNil, prop} from 'ramda'
import currencyISO4217 from '../../../utils/currencyISO4217'

export const cFieldNames = {
    accountFrom: 'accountFrom',
    bankCode: 'bankCode',
    bankCodeFrom: 'bankCodeFrom',
    currency: 'currency',
    accountTo: 'accountTo',
    amount: 'amount',
    ss: 'ss',
    ks: 'ks',
    vs: 'vs',
    date: 'date',
    messageForRecipient: 'messageForRecipient',
    comment: 'comment',
    paymentReason: 'paymentReason',
    paymentType: 'paymentType',
}

const checkBankCode = (bankCode) => {
    return bankCodes.find((bank) => equals(prop('Kód banky', bank), bankCode))
}
const checkCurrencyCode = (inputCode) => {
    return currencyISO4217.find((code) => equals(prop('Alphabetic Code', code), inputCode))
}
export const validateFioPayment = (values) => {
    const errors = {}
    const requiredFields = [
        cFieldNames.accountFrom,
        cFieldNames.accountTo,
        cFieldNames.amount,
        cFieldNames.currency,
        cFieldNames.bankCode,
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Povinné pole'
        }
    })
    if (isNil(checkBankCode(values[cFieldNames.bankCode]))) {
        errors[cFieldNames.bankCode] = 'Neplatný kód banky'
    }
    if (isNil(checkCurrencyCode(values[cFieldNames.currency]))) {
        errors[cFieldNames.currency] = 'Neplatný kód měny'
    }
    return errors
}
